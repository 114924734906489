import React, { useEffect, useState } from 'react';

import { Text } from '@xyz-school/xyz-react-frontend';

const addLeadingZero = (number: number) => {
    if (number < 10) {
        return `0${number}`;
    }
    return number;
};

type Props = {
    timestamp: number;
    onFinish: () => void;
};

const AuthCountdown = ({ timestamp, onFinish }: Props) => {
    const [countdown, setCountdown] = useState<string>('');
    const [isCountdownActive, setIsCountdownActive] = useState<boolean>(false);

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            const currentTime = Date.now();
            const timeRemaining = timestamp * 1000 - currentTime;

            if (timeRemaining <= 0) {
                setIsCountdownActive(false);
                clearInterval(countdownInterval);
                onFinish();
            } else {
                setIsCountdownActive(true);
                const seconds = Math.floor((timeRemaining / 1000) % 60);
                const minutes = Math.floor((timeRemaining / (1000 * 60)) % 60);
                // const hours = Math.floor((timeRemaining / (1000 * 60 * 60)) % 24);
                // const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

                setCountdown(`${minutes}:${addLeadingZero(seconds)}`);
            }
        }, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [onFinish, timestamp]);

    if (!isCountdownActive) {
        return null;
    }

    return <Text color="label">{countdown}</Text>;
};

export default AuthCountdown;
