import { useEffect, useState } from 'react';

import debounce from 'lodash/debounce';

type WindowSizeType = {
    width: number | undefined;
    height: number | undefined;
};

const DEBOUNCE_DELAY: number = 1000;

const useWindowSize = (): WindowSizeType => {
    const [windowSize, setWindowSize] = useState<WindowSizeType>({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = (): void => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        const debouncedHandleResize = debounce(handleResize, DEBOUNCE_DELAY);
        window.addEventListener('resize', debouncedHandleResize);
        handleResize();
        return () => window.removeEventListener('resize', debouncedHandleResize);
    }, []);

    return windowSize;
};

export default useWindowSize;
