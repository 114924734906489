import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@xyz-school/xyz-react-frontend';

import BreadcrumbsWrapper from '@/features/BreadcrumbsWrapper';

const PageProfileBreadCrumbs = () => {
    const { t } = useTranslation(['profile']);

    return (
        <BreadcrumbsWrapper>
            <Text>{t('EDIT_TITLE')}</Text>
        </BreadcrumbsWrapper>
    );
};

export default PageProfileBreadCrumbs;
