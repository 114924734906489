import { useState } from 'react';

const useAccordion = <T>(defaultValue: T | null = null): [T | null, (value: T) => void] => {
    const [currentValue, setCurrentValue] = useState<T | null>(defaultValue);

    const onToogle = (value: T) => {
        if (value === currentValue) {
            setCurrentValue(null);
        } else {
            setCurrentValue(value);
        }
    };
    return [currentValue, onToogle];
};

export default useAccordion;
