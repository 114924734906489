import React, { ReactNode } from 'react';

import styles from './CardListGridLayout.scss';

type Props = {
    children?: Array<ReactNode>;
};

const CardListGridLayout = ({ children }: Props) => {
    if (!children) {
        return null;
    }
    return <div className={styles.cardListGridLayout}>{children}</div>;
};

export default CardListGridLayout;
