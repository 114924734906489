import React from 'react';
import Icon from '@/ui/Icon';

type Props = {
    status: 'todo' | 'finished' | 'inProgress' | 'disabled';
    className?: string;
};

const LectureStatus = ({ status, className }: Props) => {
    switch (status) {
        case 'todo':
            return <Icon name="course-complete" color="icon" className={className} />;
        case 'inProgress':
            return <Icon name="course-pause" color="warning" className={className} />;
        case 'finished':
            return <Icon name="course-complete" color="success" className={className} />;
        case 'disabled':
            return <Icon name="lock" color="icon" className={className} />;
        default:
            return null;
    }
};

export default LectureStatus;
