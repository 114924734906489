import React from 'react';

import { useTranslation } from 'react-i18next';
import { Text } from '@xyz-school/xyz-react-frontend';

const CourseFreeBadge = () => {
    const { t } = useTranslation(['course']);
    return (
        <Text size="small" color="success">
            {t('IS_FREE')}
        </Text>
    );
};

export default CourseFreeBadge;
