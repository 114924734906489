import React from 'react';
import { gql } from '@apollo/client';
import { PageSubscriptionCurrentSubscriptionFragment } from '@/generated/graphql';

import CurrentSubscriptionUi from './ui/CurrentSubscriptionUi';

gql`
    fragment PageSubscriptionCurrentSubscription on XyzPlusSubscriptionType {
        tariffTitle
        priceWithDiscount
        monthlyPrice
        priceWithDiscount
        nextTransactionDate
        prepaidDuration
    }
`;

type Props = {
    subscription: PageSubscriptionCurrentSubscriptionFragment;
};

const PageSubscriptionCurrentSubscription = ({ subscription }: Props) => {
    return (
        <>
            <CurrentSubscriptionUi
                tariffTitle={subscription.tariffTitle}
                priceWithDiscount={subscription.priceWithDiscount}
                monthlyPrice={subscription.monthlyPrice}
                nextTransactionDate={subscription.nextTransactionDate}
                prepaidDuration={subscription.prepaidDuration}
                onClickCancelSubscription={() => {
                    window.open('https://my.cloudpayments.ru/ru/unsubscribe', '_blank');
                }}
            />
        </>
    );
};

export default PageSubscriptionCurrentSubscription;
