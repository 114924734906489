import React from 'react';
import { gql } from '@apollo/client';
import { Join, Separator } from '@xyz-school/xyz-react-frontend';

import {
    CourseSectionType,
    PageCourseLectureListFragmentDoc,
    PageCourseSectionListFragment,
} from '@/generated/graphql';
import CollapseItemLayout from '@/ui/CollapseItemLayout';
import useAccordion from '@/ui/useAccordion/useAccordion';
import Progress from '@/ui/Progress';

import PageCourseLectureList from './PageCourseLectureList';
import styles from './PageCourseSectionList.scss';

gql`
    fragment PageCourseSectionList on CourseType {
        sections {
            id
            title
            description
            ...PageCourseLectureList
        }
    }
    ${PageCourseLectureListFragmentDoc}
`;

type Props = {
    courseSlug: string;
    course: PageCourseSectionListFragment;
};

const PageCourseSectionList = ({ course, courseSlug }: Props) => {
    const [activeItem, onToogle] = useAccordion<CourseSectionType['id']>(course.sections[0].id);

    return (
        <div>
            <Join separator={<Separator marginTop={0} marginBottom={0} />}>
                {course.sections.map((section) => {
                    return (
                        <div className={styles.sectionItem} key={section.id}>
                            <Progress progress={1} className={styles.progress} />
                            <CollapseItemLayout
                                title={section.title}
                                description={section.description}
                                isOpen={section.id === activeItem}
                                onToogle={() => onToogle(section.id)}
                            >
                                <PageCourseLectureList courseSlug={courseSlug} section={section} />
                            </CollapseItemLayout>
                        </div>
                    );
                })}
            </Join>
        </div>
    );
};
export default PageCourseSectionList;
