import React from 'react';
import { gql } from '@apollo/client';

import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';
import { PageXyzPlusContentBodyFragment } from '@/generated/graphql';

gql`
    fragment PageXyzPlusContentBody on XyzPlusContentType {
        body
    }
`;

type Props = {
    data?: PageXyzPlusContentBodyFragment;
};

const PageXyzPlusContentBody = ({ data }: Props) => {
    if (!data?.body) {
        return null;
    }

    return <EditorJsDataView content={data.body} />;
};

export default PageXyzPlusContentBody;
