export const normalizeProgress = (progress: number): number => {
    // с сервера прогресс приходит в формате float от 0 до 1
    const p = Math.trunc(progress * 100);
    if (p < 0) {
        return 0;
    }
    if (p > 100) {
        return 100;
    }
    return p;
};
