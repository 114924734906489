import React, { ReactNode } from 'react';

import { Text } from '@xyz-school/xyz-react-frontend';

import styles from './DefinitionList.scss';

type Props = {
    children: ReactNode;
};

const DefinitionListValue = ({ children }: Props) => {
    return (
        <Text color="text" size="small" className={styles.value}>
            {children}
        </Text>
    );
};

export default DefinitionListValue;
