import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import useFormatedDate from '@/core/useFormatedDate';
import { usePageAgreementQuery, useUpdateUserAgreementMutation } from '@/generated/graphql';
import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';
import { Heading, Button, Text } from '@xyz-school/xyz-react-frontend';

import styles from './PageAgreement.scss';

gql`
    query PageAgreement {
        currentUser {
            agreementUpdates {
                id
                body
                updateDate
            }
        }
    }
    mutation updateUserAgreement($input: AcceptAgreementDTO!) {
        updateUserAgreement(input: $input) {
            isAgreementAccepted
        }
    }
`;

const PageAgreement = () => {
    const { t } = useTranslation(['agreement']);
    const { data, loading } = usePageAgreementQuery();
    const [updateUserAgreement] = useUpdateUserAgreementMutation();
    const { formatDate } = useFormatedDate();

    const acceptAgreement = () => {
        updateUserAgreement({
            variables: { input: { isAccepted: true } },
        }).then((res) => {
            if (res?.data?.updateUserAgreement?.isAgreementAccepted) {
                const urlParams = new URLSearchParams(window.location.search);
                const redirectPath = urlParams.get('origin');
                if (redirectPath) {
                    window.location.assign(redirectPath);
                } else {
                    window.location.reload();
                }
            }
        });
    };

    if (!data && !loading) {
        return null;
    }

    return (
        <div className={styles.pageAgreementWrap}>
            <Heading level={1}>{t('TITLE')}</Heading>
            <div className={styles.editorContentWrap}>
                {data?.currentUser?.agreementUpdates.map((agreementUpdateItem) => (
                    <div className={styles.editorContentItem} key={agreementUpdateItem.id}>
                        <Text size="small" color="label" className={styles.editorContentItemDate}>
                            {formatDate(agreementUpdateItem.updateDate, 'DD MMMM YYYY')}
                        </Text>
                        {agreementUpdateItem.body && <EditorJsDataView content={agreementUpdateItem.body} />}
                    </div>
                ))}
            </div>
            <Button styleType="primary" onClick={acceptAgreement}>
                {t('ACCEPT_BTN_TEXT')}
            </Button>
        </div>
    );
};

export default PageAgreement;
