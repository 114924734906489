import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Text } from '@xyz-school/xyz-react-frontend';

// import bg from '@/models/XyzPlus/images/plus_bg.png';

import styles from './XyzPlusDashboardHead.scss';

const XyzPlusDashboardHead = () => {
    const { t } = useTranslation(['dashboard']);

    return (
        <div className={styles.bg}>
            <Heading level={1}>{t('XYZPLUS')}</Heading>;
            <Text>
                Ты находишься в библиотеке курсов и мастер-классов по диджитал-арту.
                <br />
                Внутри только актуальный контент, который мы дополняем раз в месяц.
            </Text>
        </div>
    );
};

export default XyzPlusDashboardHead;
