import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
    NotificationProvider,
    I18nProvider,
    LocalizedRouter,
    MediaContextProvider,
    AppMetaTags,
    PageError500,
    LinkProvider,
} from '@xyz-school/xyz-react-frontend';
import { ThemeProvider } from '@xyz-school/xyz-react-frontend/lib/theme';

import PageLayout from '@/features/PageLayout';
import Icon from '@/ui/Icon';

import './App.scss';

type Props = {
    children: ReactNode;
};

const version = process.env.NODE_ENV === 'production' ? __BUILD__ : Date.now();
const baseRecaptchaKey = '6LfZXSonAAAAABJwTFTJDLbttb2wQ4fSUYoxwKZe';

const App = ({ children }: Props) => {
    return (
        <I18nProvider
            supportedLngs={['ru']}
            fallbackLng="ru"
            loadPath={`/locales/{{lng}}/{{ns}}.json?v=${version}`}
            useSuspense={false}
            version={`${version}`}
        >
            <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY || baseRecaptchaKey}>
                <NotificationProvider>
                    <ThemeProvider theme="dark" isChangeDisabled>
                        <Icon.Loader />
                        <LocalizedRouter>
                            <LinkProvider history={false}>
                                <AppMetaTags appName="School" description="" />
                                <MediaContextProvider>
                                    <Sentry.ErrorBoundary fallback={<PageError500 />}>
                                        <PageLayout>{children}</PageLayout>
                                    </Sentry.ErrorBoundary>
                                </MediaContextProvider>
                            </LinkProvider>
                        </LocalizedRouter>
                    </ThemeProvider>
                </NotificationProvider>
            </GoogleReCaptchaProvider>
        </I18nProvider>
    );
};

export default App;
