import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { PageSubscriptionTariffItemFragment, XyzPlusSubscriptionTariff } from '@/generated/graphql';
import { formatPrice } from '@/helpers';
import TariffItem from './ui/TariffItem';

gql`
    fragment PageSubscriptionTariffItem on XyzPlusTariffType {
        kind
        title
        duration
        discount
        price
        priceWithDiscount
        monthlyPrice
        trial
    }
`;

type Props = {
    tariff: PageSubscriptionTariffItemFragment;
    onTariffSelected: (kind: XyzPlusSubscriptionTariff) => void;
};

const PageSubscriptionsTariffItem = ({ tariff, onTariffSelected }: Props) => {
    const { t } = useTranslation(['subscription']);

    const getLabelText = (): string | undefined => {
        const benefit = tariff.price - tariff.priceWithDiscount;
        if (tariff.trial) {
            return `${t('TRIAL_LABEL')}`;
        }
        if (benefit > 0) {
            return `${t('BENEFIT')} ${formatPrice(benefit)}`;
        }
    };

    const getPrice = (): string | undefined => {
        if (tariff.price > tariff.priceWithDiscount) {
            return formatPrice(tariff.price);
        }
    };

    const getDiscount = (): number | undefined => {
        if (tariff.discount > 0) {
            return tariff.discount;
        }
    };

    return (
        <TariffItem
            title={tariff.title}
            duration={tariff.duration}
            label={getLabelText()}
            isHighlighted={tariff.trial}
            description={t(`TARIFF_DESCRIPTION_${tariff.kind}`)}
            priceWithDiscount={formatPrice(tariff.priceWithDiscount)}
            price={getPrice()}
            discount={getDiscount()}
            btnText="Оформить"
            info={t('TARIFF_MONTHLY_PRICE_INFO', { price: formatPrice(tariff.monthlyPrice) })}
            onBtnClick={() => onTariffSelected(tariff.kind)}
        />
    );
};

export default PageSubscriptionsTariffItem;
