import React from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer, Link, Text, useLanguage } from '@xyz-school/xyz-react-frontend';

import styles from './footerUnauthorized.scss';

const FooterUnauthorized = () => {
    const { t } = useTranslation();
    const { currentLang } = useLanguage();

    const navigation = [
        {
            title: 'Курсы',
            href: 'https://www.school-xyz.com/courses',
            target: '_blank',
        },
        {
            title: t('PRIVACY_POLICY'),
            href: `https://network-xyz.com/${currentLang}/info/privacy-policy`,
            target: '_blank',
        },
        {
            title: t('AGREEMENT'),
            href: `https://network-xyz.com/${currentLang}/info/agreement`,
            target: '_blank',
        },
    ];

    return (
        <GridContainer className={styles.footer}>
            {navigation.map((link, index) => (
                <Link to={link.href} target={link.target} key={index}>
                    <Text size="small">{link.title}</Text>
                </Link>
            ))}
        </GridContainer>
    );
};

export default FooterUnauthorized;
