import React from 'react';
import { Skeleton } from '@xyz-school/xyz-react-frontend';

import ImageSkeleton from '@/ui/ImageSkeleton';

import CardShort from '@/ui/CardShort';
import styles from './CardShortSkeleton.scss';

const CardShortSkeleton = () => {
    return (
        <CardShort
            title={<Skeleton className={styles.title} />}
            image={<ImageSkeleton />}
            price={<Skeleton className={styles.price} />}
        />
    );
};

export default CardShortSkeleton;
