import React, { ReactNode } from 'react';
import classnames from 'classnames';
import styles from './BadgePlacer.scss';

type Props = {
    topLeft?: ReactNode;
    topRight?: ReactNode;
    bottomRight?: ReactNode;
    bottomLeft?: ReactNode;
    children: ReactNode;
};

const BadgePlacer = ({ topLeft, topRight, bottomRight, bottomLeft, children }: Props) => {
    return (
        <div className={styles.BadgePlacer}>
            {children}
            {!!topLeft && <div className={classnames(styles.badgePlacerItem, styles.topLeft)}>{topLeft}</div>}
            {!!topRight && <div className={classnames(styles.badgePlacerItem, styles.topRight)}>{topRight}</div>}
            {!!bottomLeft && <div className={classnames(styles.badgePlacerItem, styles.bottomLeft)}>{bottomLeft}</div>}
            {!!bottomRight && (
                <div className={classnames(styles.badgePlacerItem, styles.bottomRight)}>{bottomRight}</div>
            )}
        </div>
    );
};

export default BadgePlacer;
