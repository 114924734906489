import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockLabel, Text } from '@xyz-school/xyz-react-frontend';

import CardLayout from '@/ui/CardLayout';

import styles from './XyzPlusContentPreviewUi.scss';

type Props = {
    thumbnail?: string;
    courseTitle?: string;
    categoryTitle?: string;
    description?: string;
};

const XyzPlusContentPreviewUi = ({ thumbnail, courseTitle, categoryTitle, description }: Props) => {
    const { t } = useTranslation(['subscription']);

    return (
        <CardLayout
            labels={
                <>
                    {!!categoryTitle && <BlockLabel>{categoryTitle}</BlockLabel>}
                    {!!courseTitle && <BlockLabel>{t('CONTENT_FROM_COURSE', { courseTitle })}</BlockLabel>}
                </>
            }
            thumbnail={!!thumbnail && <img src={thumbnail} alt="" />}
        >
            {!!description && (
                <>
                    <Text size="small" color="label" className={styles.descriptionTitle}>
                        {t('CONTENT_DESCRIPTION_TITLE')}
                    </Text>
                    <Text>{description}</Text>
                </>
            )}
        </CardLayout>
    );
};

export default XyzPlusContentPreviewUi;
