import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';

import { Input } from '@xyz-school/xyz-react-frontend';
import RadioButton from '@/ui/RadioButton';
import { XyzPlusFilterStateType } from '../../XyzPlusContentList/XyzPlusContentList';

import styles from './XyzPlusContentCategorySelectorUi.scss';

type Props = {
    state: XyzPlusFilterStateType;
    categories?: { id: string; title: string }[];
    courses?: any;
    onCategorySelected: (val: string | undefined) => void;
    onCourseSelected: (val: string | undefined) => void;
    onSearch: (val: string) => void;
};

const ALL_VALUE = '';

const XyzPlusContentCategorySelectorUi = ({
    state,
    categories,
    courses,
    onCategorySelected,
    onCourseSelected,
    onSearch,
}: Props) => {
    const { t } = useTranslation(['subscription']);
    const [search, setSearch] = useState<string>('');

    const handleSearch = useCallback(
        debounce((val) => {
            onSearch(val);
        }, 1000),
        [onSearch],
    );

    return (
        <div className={styles.filters}>
            {categories && categories.length > 1 && (
                <div className={styles.categorySelectorWrap}>
                    <RadioButton
                        id="all"
                        label={t('ALL_CATEGORIES_TITLE')}
                        value={ALL_VALUE}
                        name="categorySelector"
                        onChange={() => onCategorySelected(undefined)}
                        isChecked={state.category === undefined}
                    />
                    {categories.map((category) => (
                        <RadioButton
                            key={category.id}
                            id={category.id}
                            label={category.title}
                            value={category.id}
                            name="categorySelector"
                            onChange={(val) => onCategorySelected(val)}
                            isChecked={state.category === category.id}
                        />
                    ))}
                </div>
            )}

            {courses && courses.length > 1 && (
                <div className={styles.categorySelectorWrap}>
                    <RadioButton
                        id="allCourses"
                        label="Все курсы"
                        value={ALL_VALUE}
                        name="courseSelector"
                        onChange={() => onCourseSelected(undefined)}
                        isChecked={state.relatedCourse === undefined}
                    />
                    {courses.map((course: any) => (
                        <RadioButton
                            key={course.id}
                            id={course.id}
                            label={course.title}
                            value={course.id}
                            name="courseSelector"
                            onChange={(val) => onCourseSelected(val)}
                            isChecked={state.relatedCourse === course.id}
                        />
                    ))}
                </div>
            )}

            <Input
                value={search}
                label="Поиск"
                iconName={state?.title_Icontains?.length ? 'close' : 'search'}
                onClickIcon={() => {
                    setSearch('');
                    handleSearch('');
                }}
                onChange={(e) => {
                    setSearch(e.target.value);
                    handleSearch(e.target.value);
                }}
            />
        </div>
    );
};

export default XyzPlusContentCategorySelectorUi;
