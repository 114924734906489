import React from 'react';
import { gql } from '@apollo/client';

import {
    CourseContentKind,
    EnrollmentCardFragment,
    EnrollmentCardEnrolledFragmentDoc,
    EnrollmentBundleCardFragmentDoc,
} from '@/generated/graphql';
import EnrollmentCardEnrolled from '@/models/Enrollment/EnrollmentCardEnrolled';
import EnrollmentBundleCard from '@/models/Enrollment/EnrollmentBundleCard/EnrollmentBundleCard';

gql`
    fragment EnrollmentCard on EnrollmentType {
        course {
            contentKind
        }
        ...EnrollmentCardEnrolled
        ...EnrollmentBundleCard
    }
    ${EnrollmentCardEnrolledFragmentDoc}
    ${EnrollmentBundleCardFragmentDoc}
`;

type Props = {
    enrollment: EnrollmentCardFragment;
};

const EnrollmentCard = ({ enrollment }: Props) => {
    if (enrollment.course.contentKind === CourseContentKind.Courses) {
        return <EnrollmentBundleCard enrollment={enrollment} />;
    }

    return <EnrollmentCardEnrolled enrollment={enrollment} />;
};
export default EnrollmentCard;
