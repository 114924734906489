import React from 'react';
import classnames from 'classnames';
import { Heading } from '@xyz-school/xyz-react-frontend';

import styles from './DiscountBadge.scss';

type Props = {
    value: number;
    color?: 'success' | 'accent';
};

const DiscountBadge = ({ value, color = 'accent' }: Props) => {
    return (
        <div className={styles.discountBadge}>
            <svg viewBox="0 0 80 80" fill="none" className={styles.bgShape}>
                <path
                    d="M36.9385 1.2681C38.8988 0.45615 41.1012 0.45615 43.0615 1.2681L65.2228 10.4476C67.183 11.2596 68.7404 12.817 69.5524 14.7772L78.7319 36.9385C79.5439 38.8988 79.5439 41.1012 78.7319 43.0615L69.5524 65.2228C68.7404 67.183 67.183 68.7404 65.2228 69.5524L43.0615 78.7319C41.1012 79.5439 38.8988 79.5439 36.9385 78.7319L14.7772 69.5524C12.817 68.7404 11.2596 67.183 10.4476 65.2228L1.2681 43.0615C0.45615 41.1012 0.45615 38.8988 1.2681 36.9385L10.4476 14.7772C11.2596 12.817 12.817 11.2596 14.7772 10.4476L36.9385 1.2681Z"
                    className={classnames(styles.bgShapePath, [styles[color]])}
                />
            </svg>

            <Heading level={3} className={styles.value}>
                {`${value}%`}
            </Heading>
        </div>
    );
};

export default DiscountBadge;
