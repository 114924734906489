import React, { useState, useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useNotificationActions } from '@xyz-school/xyz-react-frontend';

import usePhoneVerification from '@/features/Auth/usePhoneVerification';
import { usePageAuthQuery } from '@/generated/graphql';
import Dashboard from '@/pages/Dashboard';
import AuthPhoneStep from './AuthPhoneStep';
import AuthCodeStep from './AuthCodeStep';

gql`
    query PageAuth {
        currentUser {
            id
        }
    }
`;

const STEPS = {
    phone: 'phone',
    code: 'code',
} as const;

type StepType = keyof typeof STEPS;

const PageAuth = () => {
    const { search } = useLocation();
    const { pushNotification } = useNotificationActions();
    const { data, refetch } = usePageAuthQuery();
    const [step, setStep] = useState<StepType>(STEPS.phone);
    const [phone, setPhone] = useState<string>('');
    const [agreement, setAgreement] = useState<boolean>(true);
    const [subscription, setSubscription] = useState<boolean>(true);
    const [currentVerificationId, setCurrentVerificationId] = useState<string | null>(null);
    const [currentVerificationEndDate, setCurrentVerificationEndDate] = useState<string | null>(null);

    const { handlePhoneSubmit } = usePhoneVerification({
        onSuccess: ({
            verificationId,
            verificationEndDate,
        }: {
            verificationId: string;
            verificationEndDate: string | null;
        }) => {
            if (step === STEPS.code) {
                pushNotification({
                    type: 'success',
                    message: `Мы отправили код повторно на номер ${phone}`,
                    name: 'ResendCodeSuccess',
                });
            }

            setCurrentVerificationId(verificationId);
            setCurrentVerificationEndDate(verificationEndDate);
            setStep(STEPS.code);
        },
    });

    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const redirectUrl = searchParams.get('next');

    const handlePhoneEdit = () => {
        setStep(STEPS.phone);
    };

    if (data?.currentUser?.id) {
        return <Redirect to={redirectUrl || Dashboard.getUrl(Dashboard.tab.My)} />;
    }

    if (step === STEPS.code && currentVerificationId) {
        return (
            <AuthCodeStep
                phone={phone}
                verificationId={currentVerificationId}
                verificationEndDate={currentVerificationEndDate}
                onEditPhone={handlePhoneEdit}
                onResendCode={() => {
                    handlePhoneSubmit({ phone, isProcessingAgreed: agreement, isAdvertisingAgreed: subscription });
                }}
                onSuccess={() => refetch()}
            />
        );
    }

    return (
        <AuthPhoneStep
            phone={phone}
            onChangePhone={(val) => setPhone(val)}
            agreement={agreement}
            onChangeAgreement={(val) => setAgreement(val)}
            subscription={subscription}
            onChangeSubscription={(val) => setSubscription(val)}
            onSubmit={() =>
                handlePhoneSubmit({ phone, isProcessingAgreed: agreement, isAdvertisingAgreed: subscription })
            }
            redirectUrl={redirectUrl}
        />
    );
};

export default PageAuth;
