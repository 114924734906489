import React, { ReactNode } from 'react';

import { Heading } from '@xyz-school/xyz-react-frontend';

import styles from './EnrollmentBundleCardUi.scss';

type Props = {
    heading: string;
    children: ReactNode[];
};

const EnrollmentBundleCardUi = ({ heading, children }: Props) => {
    return (
        <>
            <div className={styles.headingWrap}>
                <Heading level={3}>{heading}</Heading>
            </div>
            <div className={styles.childrenWrap}>{children}</div>
        </>
    );
};

export default EnrollmentBundleCardUi;
