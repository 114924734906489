import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@xyz-school/xyz-react-frontend';

import Dashboard from '@/pages/Dashboard';
import BreadcrumbsWrapper from '@/features/BreadcrumbsWrapper';

const PageSubscriptionBreadCrumbs = () => {
    const { t } = useTranslation(['subscription']);

    return (
        <BreadcrumbsWrapper>
            <Link to={Dashboard.getUrl(Dashboard.tab.XyzPlus)}>{t('XYZ_PLUS_PAGE_TITLE')}</Link>
            <Text>{t('SETTINGS_TITLE')}</Text>
        </BreadcrumbsWrapper>
    );
};

export default PageSubscriptionBreadCrumbs;
