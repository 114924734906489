import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { EditorJsBlockCodeType } from '@/models/EditorJsData';

type Props = {
    block: EditorJsBlockCodeType;
};

const ContentCode = ({ block }: Props) => {
    return <SyntaxHighlighter style={docco}>{block.data.code}</SyntaxHighlighter>;
};

export default ContentCode;
