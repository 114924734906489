import React, { ReactNode } from 'react';
import { Heading, Container } from '@xyz-school/xyz-react-frontend';
import styles from './CardShort.scss';

type Props = {
    title: ReactNode;
    image: ReactNode;
    controls?: ReactNode;
    price?: ReactNode;
};

const CardShort = ({ title, image, controls, price }: Props) => {
    return (
        <div className={styles.CardShort}>
            <div>
                <Container className={styles.imageContainer} marginBottom={2}>
                    {image}
                </Container>
                <Container marginBottom={1}>
                    <Heading wrap level={3}>
                        {title}
                    </Heading>
                </Container>
                {price}
            </div>
            <Container paddingTop={3}>{controls}</Container>
        </div>
    );
};
export default CardShort;
