import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import {
    XyzPlusSubscriptionTariff,
    PageSubscriptionCurrentSubscriptionFragmentDoc,
    usePageSubscriptionSettingsQuery,
    XyzPlusStatusInformerFragmentDoc,
    XyzPlusSubscriptionStatus,
} from '@/generated/graphql';
import { Container, Heading } from '@xyz-school/xyz-react-frontend';

import GridLayout from '@/ui/GridLayout';
import XyzPlusStatusInformer from '@/models/XyzPlus/XyzPlusStatusInformer';

import PageSubscriptionBreadCrumbs from './PageSubscriptionBreadCrumbs';
import PageSubscriptionTariffs from './PageSubscriptionTariffs';
import PageSubscriptionSettingsFaq from './PageSubscriptionSettingsFaq';
import PageSubscriptionCurrentSubscription from './PageSubscriptionCurrentSubscription';
import PageSubscriptionOfferNotification from './PageSubscriptionOfferNotification';

gql`
    query PageSubscriptionSettings {
        xyzPlus {
            ...XyzPlusStatusInformer
            currentSubscription {
                status
                ...PageSubscriptionCurrentSubscription
            }
        }
    }
    ${XyzPlusStatusInformerFragmentDoc}
    ${PageSubscriptionCurrentSubscriptionFragmentDoc}
`;

type Props = {
    onTariffSelected: (kind: XyzPlusSubscriptionTariff) => void;
};

const PageSubscriptionSettings = ({ onTariffSelected }: Props) => {
    const { data } = usePageSubscriptionSettingsQuery();
    const { t } = useTranslation(['subscription']);

    const haveActiveSubscription: boolean =
        data?.xyzPlus?.currentSubscription?.status === XyzPlusSubscriptionStatus.Active ||
        data?.xyzPlus?.currentSubscription?.status === XyzPlusSubscriptionStatus.Pastdue;

    return (
        <GridLayout>
            <Container marginTop={6}>
                <PageSubscriptionBreadCrumbs />
            </Container>

            <Heading level={1}>{t('SETTINGS_TITLE')}</Heading>

            {!!data?.xyzPlus && (
                <Container marginTop={6}>
                    <XyzPlusStatusInformer data={data.xyzPlus} />
                </Container>
            )}

            {data?.xyzPlus?.currentSubscription && haveActiveSubscription ? (
                <Container marginTop={8} marginBottom={3}>
                    <PageSubscriptionCurrentSubscription subscription={data?.xyzPlus?.currentSubscription} />
                </Container>
            ) : (
                <Container marginTop={6} marginBottom={3}>
                    <PageSubscriptionTariffs onTariffSelected={onTariffSelected} />
                </Container>
            )}

            <Container marginBottom={8} marginBottomXs={6}>
                <PageSubscriptionOfferNotification isSubscriptionActive={!!data?.xyzPlus?.currentSubscription} />
            </Container>

            <PageSubscriptionSettingsFaq />
        </GridLayout>
    );
};

export default PageSubscriptionSettings;
