import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatures } from '@/models/User/useFeature';
import SidebarNavLinks from '@/ui/SidebarNavLink/SidebarNavLinks';
import Dashboard from './Dashboard';

const handleActiveNavLinkClick = (): void => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const DashboardNavigation = () => {
    const { t } = useTranslation(['dashboard']);
    const features = useFeatures();

    return (
        <SidebarNavLinks>
            <SidebarNavLinks.Item
                onActiveNavLinkClick={handleActiveNavLinkClick}
                to={Dashboard.getUrl(Dashboard.tab.My)}
                iconName="course-start"
            >
                {t('MY_COURSES')}
            </SidebarNavLinks.Item>
            {features.streams && (
                <SidebarNavLinks.Item
                    onActiveNavLinkClick={handleActiveNavLinkClick}
                    to={Dashboard.getUrl(Dashboard.tab.Streams)}
                    iconName="play"
                >
                    {t('STREAMS')}
                </SidebarNavLinks.Item>
            )}
            <SidebarNavLinks.Item
                onActiveNavLinkClick={handleActiveNavLinkClick}
                to={Dashboard.getUrl(Dashboard.tab.All)}
                iconName="course-all"
            >
                {t('ALL_COURSES')}
            </SidebarNavLinks.Item>
            <SidebarNavLinks.Item
                onActiveNavLinkClick={handleActiveNavLinkClick}
                to={Dashboard.getUrl(Dashboard.tab.XyzPlus)}
                iconName="xyz-plus"
            >
                {t('XYZPLUS')}
            </SidebarNavLinks.Item>
        </SidebarNavLinks>
    );
};

export default DashboardNavigation;
