import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Link } from '@xyz-school/xyz-react-frontend';

import Popover from '@/ui/Popover';
import Icon from '@/ui/Icon';

import styles from './EnrollmentProgressLayout.scss';

type Props = {
    progressLabel: string;
    progressValue: string;
    children: ReactNode;
    popover?: {
        title?: string;
        description: string;
        link?: string;
    };
};

const EnrollmentProgressLayout = ({ progressLabel, progressValue, children, popover }: Props) => {
    const { t } = useTranslation(['enrollment']);

    return (
        <>
            <div className={styles.progressLayoutHead}>
                <div className={styles.progressLayoutLabelWrap}>
                    <Text size="small" color="label" className={styles.progressLayoutLabel}>
                        {progressLabel}
                    </Text>
                    {popover && (
                        <Popover
                            className={styles.progressLayoutPopover}
                            placement="bottomRight"
                            content={
                                <div className={styles.progressLayoutPopoverContent}>
                                    {popover.title && <Text size="small">{popover.title}</Text>}
                                    {popover.description && (
                                        <Text size="small" color="label">
                                            {popover.description}
                                        </Text>
                                    )}
                                    {popover.link && (
                                        <Link target="_blank" to={popover.link}>
                                            <Text size="small" color="accent">
                                                {t('ENROLLMENT_MORE_BTN_TEXT')}
                                            </Text>
                                        </Link>
                                    )}
                                </div>
                            }
                        >
                            <Icon name="question" size="small" />
                        </Popover>
                    )}
                </div>
                <Text size="small">{progressValue}</Text>
            </div>
            {children}
        </>
    );
};

export default EnrollmentProgressLayout;
