import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './CardLayout.scss';

type Props = {
    className?: string;
    labels?: ReactNode;
    labelsPlacement?: 'topStart' | 'topEnd';
    thumbnail?: ReactNode;
    children?: ReactNode;
};

const CardLayout = ({ className, labels, labelsPlacement = 'topStart', thumbnail, children }: Props) => {
    return (
        <div className={classnames(styles.cardLayout, className, { [styles.isEmptyChildren]: !children })}>
            <div className={styles.thumbnailWrap}>
                <div className={classnames(styles.labelsWrap, [styles[labelsPlacement]])}>{labels}</div>
                {thumbnail}
            </div>
            {!!children && <div className={styles.contentWrap}>{children}</div>}
        </div>
    );
};

export default CardLayout;
