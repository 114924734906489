import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, Link } from '@xyz-school/xyz-react-frontend';
import NotificationItem from '@/ui/NotificationItem';

type Props = {
    isSubscriptionActive: boolean;
};

const PageSubscriptionOfferNotification = ({ isSubscriptionActive }: Props) => {
    const { t } = useTranslation(['subscription']);

    return (
        <NotificationItem>
            <Text size="small" inline>
                {!isSubscriptionActive && t('OFFER_LABEL_TEXT')}
                &nbsp;
                <Link to="https://network-xyz.com/ru/info/agreement-xyz-plus" target="_blank">
                    <Text color="accent" size="small" inline>
                        {t('OFFER_LINK_TEXT')}
                    </Text>
                </Link>
            </Text>
        </NotificationItem>
    );
};

export default PageSubscriptionOfferNotification;
