import React from 'react';

import { useTranslation } from 'react-i18next';

import UserContactInfoForm from '@/models/User/UserContactInfoForm';
import ContactsUi from './ui/ContactsUi';

type Props = {
    isSubmitDisabled: boolean;
    onSkipBtnClick: () => void;
    onFormSuccess: () => void;
    onFormError: () => void;
};

const PageSubscriptionContacts = ({ isSubmitDisabled, onSkipBtnClick, onFormSuccess, onFormError }: Props) => {
    const { t } = useTranslation(['user']);

    return (
        <ContactsUi onSkipBtnClick={onSkipBtnClick}>
            <UserContactInfoForm
                isSubmitBtnFullWidth
                submitBtnText={t('CONTACTS_FORM_SUBMIT_BTN_TEXT')}
                isSubmitDisabled={isSubmitDisabled}
                onFormSuccess={onFormSuccess}
                onFormError={onFormError}
            />
        </ContactsUi>
    );
};

export default PageSubscriptionContacts;
