import React, { ReactNode } from 'react';
import { Heading } from '@xyz-school/xyz-react-frontend';
import styles from './CardLong.scss';

type Props = {
    title: ReactNode;
    image: ReactNode;
    description?: ReactNode;
    footer?: ReactNode | null;
};

const CardLong = ({ title, image, description, footer }: Props) => {
    return (
        <div className={styles.CardLong}>
            <div className={styles.imageContainer}>{image}</div>
            <div className={styles.content}>
                <div className={styles.topContent}>
                    <div className={styles.infoContent}>
                        <Heading level={3}>{title}</Heading>
                        <div className={styles.description}>{description}</div>
                    </div>
                </div>
                <div className={styles.footer}>{footer}</div>
            </div>
        </div>
    );
};
export default CardLong;
