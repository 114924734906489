import React from 'react';
import { useTranslation } from 'react-i18next';

import { Footer, useLanguage } from '@xyz-school/xyz-react-frontend';

import styles from './PageLayout.scss';

const socialNetworks = [
    {
        iconName: 'youtube',
        link: 'https://www.youtube.com/channel/UCtJsE0SDhyS4ib2evb5k3gg/videos',
    },
    {
        iconName: 'telegram',
        link: 'https://t.me/xyz_podcast',
    },
    {
        iconName: 'vk',
        link: 'https://vk.com/xyz_gamedev',
    },
];

const defaultNavigation = [
    {
        title: 'Курсы',
        href: 'https://www.school-xyz.com/courses',
        target: '_blank',
    },
    {
        title: 'Мастер-классы',
        href: 'https://www.school-xyz.com/master-classes',
        target: '_blank',
    },
    {
        title: 'Блог',
        href: 'https://www.school-xyz.com/blog',
        target: '_blank',
    },
];

const PageLayoutFooter = () => {
    const { t } = useTranslation();
    const { currentLang } = useLanguage();

    const navigation = [
        defaultNavigation,
        [
            {
                title: 'Media',
                href: 'https://media-xyz.com/',
                target: '_blank',
            },
            {
                title: 'Network',
                href: 'https://network-xyz.com/',
                target: '_blank',
            },
        ],

        [
            {
                title: t('PRIVACY_POLICY'),
                href: `https://network-xyz.com/${currentLang}/info/privacy-policy`,
                target: '_blank',
            },
            {
                title: t('AGREEMENT'),
                href: `https://network-xyz.com/${currentLang}/info/agreement`,
                target: '_blank',
            },
            {
                title: t('REFERRAL_PROGRAM'),
                href: 'https://www.school-xyz.com/referalnaya-programma/',
                target: '_blank',
            },
        ],
    ];

    return (
        <div className={styles.footerWrap}>
            <Footer navigation={navigation} email="hello@school-xyz.com" socials={socialNetworks} />
        </div>
    );
};

export default PageLayoutFooter;
