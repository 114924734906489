import React, { useState } from 'react';

import PageSubscriptionSuccessWaiting from './PageSubscriptionSuccessWaiting';
import PageSubscriptionSuccessReady from './PageSubscriptionSuccessReady';

const PageSubscriptionSuccess = () => {
    const [xyzPlusActive, setXyzPlusActive] = useState<boolean>(false);

    if (!xyzPlusActive) {
        return <PageSubscriptionSuccessWaiting onXyzPlusBecomeActive={() => setXyzPlusActive(true)} />;
    }

    return <PageSubscriptionSuccessReady />;
};

export default PageSubscriptionSuccess;
