import React from 'react';
import { gql } from '@apollo/client';
import { CourseContentType, CourseType, PageLectureNavFragment } from '@/generated/graphql';

import CourseContentFastActions from '../../models/Course/CourseContent/CourseContentFastActions';
import LectureContentLink from './ui/LectureContentLink';
import LectureListWrapper from './ui/LectureListWrapper';

gql`
    fragment PageLectureNav on CourseLectureType {
        id
        contents {
            id
            title
        }
    }
`;

type Props = {
    lecture: PageLectureNavFragment;
    courseSlug: CourseType['slug'];
    activeContentId: CourseContentType['id'];
};

const PageLectureNav = ({ lecture, activeContentId, courseSlug }: Props) => {
    return (
        <LectureListWrapper>
            {lecture.contents.map((content) => {
                return (
                    <LectureContentLink
                        key={content.id}
                        courseSlug={courseSlug}
                        lectureId={lecture.id}
                        contentId={content.id}
                        title={content.title}
                        isActive={content.id === activeContentId}
                        fastActionsPanel={
                            <CourseContentFastActions
                                lectureId={lecture.id}
                                contentId={content.id}
                                contentIds={lecture.contents.map((item) => item.id)}
                            />
                        }
                    />
                );
            })}
        </LectureListWrapper>
    );
};

export default PageLectureNav;
