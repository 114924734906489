import React from 'react';
import { gql } from '@apollo/client';

import { Container } from '@xyz-school/xyz-react-frontend';
import {
    DashboardEnrollmentsListFragment,
    EnrollmentBundleCardFragmentDoc,
    EnrollmentCardFragmentDoc,
} from '@/generated/graphql';

import CardsLayout from '@/ui/CardsLayout';
import EnrollmentCard from '@/models/Enrollment/EnrollmentCard';

gql`
    fragment DashboardEnrollmentsList on EnrollmentTypeConnection {
        edges {
            node {
                ...EnrollmentCard
            }
        }
    }
    ${EnrollmentBundleCardFragmentDoc}
    ${EnrollmentCardFragmentDoc}
`;

type Props = {
    title: string;
    enrollments: DashboardEnrollmentsListFragment;
};

const DashboardEnrollmentsList = ({ title, enrollments }: Props) => {
    if (!enrollments?.edges?.length) {
        return null;
    }
    return (
        <CardsLayout title={title} count={enrollments.edges.length}>
            {enrollments.edges.map((enrollment) => {
                if (!enrollment?.node) {
                    return null;
                }
                return (
                    <Container key={enrollment?.node?.id} paddingBottomXs={2} paddingBottom={4}>
                        <EnrollmentCard enrollment={enrollment.node} />
                    </Container>
                );
            })}
        </CardsLayout>
    );
};

export default DashboardEnrollmentsList;
