import React from 'react';
import classnames from 'classnames';
import Icon from '@/ui/Icon';

import styles from './Image.scss';

type Props = {
    image?: string;
    coverAlt?: string;
    className?: string;
};

const Image = ({ image, coverAlt, className }: Props) => {
    return (
        <div className={className}>
            <div className={styles.posterSizeWrap}>
                <div className={classnames(styles.posterWrap, styles.background, { [styles.empty]: !image })}>
                    {image ? <img src={image} alt={coverAlt || ''} className={styles.poster} /> : <Icon name="image" />}
                </div>
            </div>
        </div>
    );
};

export default Image;
