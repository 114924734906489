import React, { ReactNode } from 'react';

import { GridContainer, Heading, Text } from '@xyz-school/xyz-react-frontend';

import styles from './СenteredLayout.scss';

type Props = {
    aboveHeading?: ReactNode;
    heading: ReactNode;
    description: ReactNode;
    children?: ReactNode;
};

const СenteredLayout = ({ aboveHeading, heading, description, children }: Props) => {
    return (
        <GridContainer className={styles.container}>
            <div className={styles.wrap}>
                {aboveHeading && <div className={styles.aboveHeading}>{aboveHeading}</div>}
                <Heading level={1} className={styles.heading}>
                    {heading}
                </Heading>
                <Text align="center" className={styles.description}>
                    {description}
                </Text>
                {children}
            </div>
        </GridContainer>
    );
};

export default СenteredLayout;
