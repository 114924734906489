import React from 'react';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

import { Heading, PageError404 } from '@xyz-school/xyz-react-frontend';
import {
    usePageXyzPlusContentQuery,
    PageXyzPlusContentBreadCrumbsFragmentDoc,
    PageXyzPlusContentPreviewFragmentDoc,
    PageXyzPlusContentControlsFragmentDoc,
    PageXyzPlusContentBodyFragmentDoc,
} from '@/generated/graphql';
import ContentPageLayout from '@/ui/ContentPageLayout';
import PageXyzPlusContentPreview from './PageXyzPlusContentPreview';
import PageXyzPlusContentBody from './PageXyzPlusContentBody';
import PageXyzPlusContentBreadCrumbs from './PageXyzPlusContentBreadCrumbs';
import PageXyzPlusContentControls from './PageXyzPlusContentControls';

gql`
    query PageXyzPlusContent($id: ID!) {
        xyzPlusContent(id: $id) {
            title
            access {
                view
            }
            ...PageXyzPlusContentBreadCrumbs
            ...PageXyzPlusContentPreview
            ...PageXyzPlusContentControls
            ...PageXyzPlusContentBody
        }
    }
    ${PageXyzPlusContentBreadCrumbsFragmentDoc}
    ${PageXyzPlusContentPreviewFragmentDoc}
    ${PageXyzPlusContentControlsFragmentDoc}
    ${PageXyzPlusContentBodyFragmentDoc}
`;

const PageXyzPlusContent = () => {
    const { id } = useParams<{ id: string }>();
    const { data, loading } = usePageXyzPlusContentQuery({ variables: { id } });
    const noAccess = !data?.xyzPlusContent?.access.view;

    if (loading) {
        return null;
    }

    if (!data?.xyzPlusContent && !loading) {
        return <PageError404 />;
    }

    return (
        <ContentPageLayout
            bredCrumbs={<PageXyzPlusContentBreadCrumbs data={data?.xyzPlusContent} />}
            heading={<Heading level={1}>{data?.xyzPlusContent?.title}</Heading>}
            preview={noAccess && <PageXyzPlusContentPreview data={data?.xyzPlusContent} />}
            controls={noAccess && <PageXyzPlusContentControls data={data?.xyzPlusContent} />}
        >
            <PageXyzPlusContentBody data={data?.xyzPlusContent} />
        </ContentPageLayout>
    );
};

export default PageXyzPlusContent;
