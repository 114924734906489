import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@xyz-school/xyz-react-frontend';

import Informer from '@/ui/Informer';

const DiscordRequiredInformer = () => {
    const { t } = useTranslation(['user']);

    return (
        <Informer
            title={t('DISCORD_REQUIRED_TITLE')}
            description={t('DISCORD_REQUIRED_DESCRIPTION')}
            buttons={
                <Button linkTo="/accounts/discord/login/" styleType="primary">
                    {t('DISCORD_REQUIRED_BTN_TEXT')}
                </Button>
            }
        />
    );
};

export default DiscordRequiredInformer;
