import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { XyzPlusStatusType, XyzPlusStatusInformerFragment, XyzPlusSubscriptionStatus } from '@/generated/graphql';
import Informer from '@/ui/Informer';
import { Button } from '@xyz-school/xyz-react-frontend';
import useFormatedDate from '@/core/useFormatedDate';

gql`
    fragment XyzPlusStatusInformer on XYZPlusType {
        xyzPlusExpirationDate
        xyzPlusStatus
        currentSubscription {
            status
        }
    }
`;

type Props = {
    data: XyzPlusStatusInformerFragment;
    isShowControls?: boolean;
    isPlusSaleActive?: boolean;
};

const XyzPlusStatusInformer = ({ data, isShowControls, isPlusSaleActive = true }: Props) => {
    const { t } = useTranslation(['subscription']);
    const { formatDate } = useFormatedDate();

    const aboutXyzPlusButton = (
        <Button linkTo="https://www.school-xyz.com/plus" linkTarget="_blank">
            {t('ABOUT_XYZ_PLUS_BTN_TEXT')}
        </Button>
    );

    const buySubscriptionButton = (
        <Button
            styleType={data.xyzPlusStatus === XyzPlusStatusType.Active ? 'secondary' : 'primary'}
            linkTo="/ru/subscription/"
        >
            {data.xyzPlusStatus === XyzPlusStatusType.Expired && t('BTN_EXPIRED_SUBSCRIPTION_TEXT')}
            {data.xyzPlusStatus !== XyzPlusStatusType.Expired && t('BTN_NO_SUBSCRIPTION_TEXT')}
        </Button>
    );

    const cantBuySubscriptionButton = (
        <Button linkTo="https://sale.school-xyz.com/" linkTarget="_blank" styleType="secondary">
            {t('BTN_CANT_BUY_SUBSCRIPTION_TEXT')}
        </Button>
    );

    const manageSubscriptionButton = <Button linkTo="/ru/subscription/">{t('MANAGE_SUBSCRIPTION_BTN_TEXT')}</Button>;

    const accessActiveInformer = () => {
        return (
            <Informer
                type={Informer.type.SUCCESS}
                title={
                    data.xyzPlusExpirationDate
                        ? t('TITLE_ACTIVE_XYZ_PlUS', {
                              untilDate: `${t('UNTIL')} ${formatDate(data.xyzPlusExpirationDate, 'D MMMM')}`,
                          })
                        : t('TITLE_ACTIVE_XYZ_PlUS')
                }
                description={t('DESCRIPTION_ACTIVE_XYZ_PlUS')}
                buttons={isShowControls && buySubscriptionButton}
            />
        );
    };

    if (!isPlusSaleActive && data.xyzPlusStatus !== XyzPlusStatusType.Active) {
        return (
            <Informer
                type={Informer.type.FAIL}
                title={t('TITLE_CANT_BUY_SUBSCRIPTION')}
                description={t('DESCRIPTION_CANT_BUY_SUBSCRIPTION')}
                buttons={cantBuySubscriptionButton}
            />
        );
    }

    if (!isPlusSaleActive && data.xyzPlusStatus === XyzPlusStatusType.Active) {
        return (
            <Informer
                type={Informer.type.SUCCESS}
                title={t('TITLE_ACTIVE_XYZ_PlUS')}
                description={t('DESCRIPTION_ACTIVE_SUBSCRIPTION')}
            />
        );
    }

    if (data.currentSubscription?.status === XyzPlusSubscriptionStatus.Active) {
        return (
            <Informer
                type={Informer.type.SUCCESS}
                title={t('TITLE_ACTIVE_SUBSCRIPTION')}
                description={t('DESCRIPTION_ACTIVE_SUBSCRIPTION')}
                buttons={isShowControls && manageSubscriptionButton}
            />
        );
    }

    if (data.currentSubscription?.status === XyzPlusSubscriptionStatus.Pastdue) {
        return (
            <Informer
                type={Informer.type.FAIL}
                title={
                    data.xyzPlusStatus === XyzPlusStatusType.Active
                        ? t('TITLE_ACTIVE_SUBSCRIPTION')
                        : t('TITLE_PASTDUE_SUBSCRIPTION')
                }
                description={t('DESCRIPTION_PASTDUE_SUBSCRIPTION')}
                buttons={isShowControls && manageSubscriptionButton}
            />
        );
    }

    if (
        data.currentSubscription?.status === XyzPlusSubscriptionStatus.Rejected &&
        data.xyzPlusStatus === XyzPlusStatusType.Active
    ) {
        return accessActiveInformer();
    }

    if (data.currentSubscription?.status === XyzPlusSubscriptionStatus.Rejected) {
        return (
            <Informer
                type={Informer.type.FAIL}
                title={t('TITLE_REJECTED_SUBSCRIPTION')}
                description={t('DESCRIPTION_REJECTED_SUBSCRIPTION')}
                buttons={isShowControls && manageSubscriptionButton}
            />
        );
    }

    if (data.xyzPlusStatus === XyzPlusStatusType.Active) {
        return accessActiveInformer();
    }

    if (data.xyzPlusStatus === XyzPlusStatusType.Expired) {
        return (
            <Informer
                type={Informer.type.WARNING}
                title={t('TITLE_EXPIRED_SUBSCRIPTION')}
                description={t('DESCRIPTION_EXPIRED_SUBSCRIPTION')}
                buttons={
                    isShowControls && (
                        <>
                            {buySubscriptionButton}
                            {aboutXyzPlusButton}
                        </>
                    )
                }
            />
        );
    }

    return (
        <Informer
            type={Informer.type.WARNING}
            title={t('TITLE_NO_SUBSCRIPTION')}
            description={t('DESCRIPTION_NO_SUBSCRIPTION')}
            buttons={
                isShowControls && (
                    <>
                        {buySubscriptionButton}
                        {aboutXyzPlusButton}
                    </>
                )
            }
        />
    );
};

export default XyzPlusStatusInformer;
