export const onLoadScript = (id: string, src: string, callback: () => void): void => {
    const scriptcript = document.getElementById(id);
    if (!scriptcript) {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        document.body.appendChild(script);
        script.onload = () => {
            if (callback) {
                callback();
            }
        };
    }
    if (scriptcript && callback) {
        callback();
    }
};
