import React from 'react';
import { useTranslation } from 'react-i18next';

import useFormatedDate from '@/core/useFormatedDate';
import { formatPrice } from '@/helpers';
import { Heading, Button, Text } from '@xyz-school/xyz-react-frontend';

import styles from './CurrentSubscriptionUi.scss';

type Props = {
    tariffTitle: string;
    priceWithDiscount: number;
    monthlyPrice: number;
    nextTransactionDate: string;
    prepaidDuration: string;
    onClickChangeTariff?: () => void;
    onClickCancelSubscription: () => void;
};

const CurrentSubscriptionUi = ({
    tariffTitle,
    priceWithDiscount,
    monthlyPrice,
    nextTransactionDate,
    prepaidDuration,
    onClickChangeTariff,
    onClickCancelSubscription,
}: Props) => {
    const { t } = useTranslation(['subscription']);
    const { formatDate } = useFormatedDate();

    const currentSubscriptionData = [
        {
            label: t('TARIFF_LABEL'),
            value: tariffTitle,
        },
        {
            label: t('PRICE_LABEL'),
            value: t('PRICE_VALUE_TEMPLATE', {
                priceWithDiscount: formatPrice(priceWithDiscount),
                duration: prepaidDuration,
                monthlyPrice: formatPrice(monthlyPrice),
            }),
        },
        {
            label: t('NEXT_TRANSACTION_LABEL'),
            value: `${formatDate(nextTransactionDate, 'DD MMMM YYYY')} / ${formatPrice(monthlyPrice)}`,
        },
    ];

    return (
        <>
            <Heading level={2}>{t('CURRENT_SUBSCRIPTION_TITLE')}</Heading>
            <div className={styles.currentSubscriptionInfoWrap}>
                {currentSubscriptionData.map((item) => (
                    <div className={styles.currentSubscriptionInfoItem}>
                        <div className={styles.currentSubscriptionInfoItemLabelWrap}>
                            <Text color="label">{item.label}</Text>
                        </div>
                        <Text>{item.value}</Text>
                    </div>
                ))}
                <div className={styles.controllsWrap}>
                    {!!onClickChangeTariff && (
                        <Button
                            styleType="primary"
                            onClick={onClickChangeTariff}
                            className={styles.currentSubscriptionBtn}
                        >
                            {t('CHANGE_TARIFF_BTN_TEXT')}
                        </Button>
                    )}
                    <Button onClick={onClickCancelSubscription}>{t('CANCEL_SUBSCRIPTION_BTN_TEXT')}</Button>
                </div>
            </div>
        </>
    );
};

export default CurrentSubscriptionUi;
