import React, { ReactNode } from 'react';
import classnames from 'classnames';

import { Button } from '@xyz-school/xyz-react-frontend';

import styles from './Modal.scss';

type Props = {
    children: ReactNode;
    className?: string;
    onClose: () => void;
};

const Modal = ({ children, className, onClose }: Props) => {
    return (
        <div className={classnames(styles.modalWrap, className)}>
            <div className={styles.overlay} />
            <div className={styles.body}>
                <div className={classnames(styles.contentWrap)}>
                    <Button icon="close" onClick={() => onClose()} styleType="primary" className={styles.closeButton} />
                    <div className={styles.content}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
