import React from 'react';

import { Container, Skeleton } from '@xyz-school/xyz-react-frontend';

import styles from './CourseProgressSkeleton.scss';

const CourseProgressSkeleton = () => (
    <>
        <Container marginRight={2}>
            <Skeleton round className={styles.progress} />
        </Container>
        <div className={styles.linesWrapper}>
            <Skeleton className={styles.line} />
            <Skeleton className={styles.line} />
        </div>
    </>
);

export default CourseProgressSkeleton;
