import React from 'react';
import InputMask from 'react-input-mask';
import classnames from 'classnames';

import styles from './Field.scss';

type Props = {
    className?: string;
    id?: string;
    isDisabled?: boolean;
    label?: string;
    mask?: string | Array<string | RegExp>;
    placeholder?: string;
    value?: string;
    onBlur?: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Field = React.forwardRef(
    ({ className, id, isDisabled, label, mask, placeholder, value, onBlur, onChange }: Props, ref) => {
        return (
            <div className={styles.inputWrap}>
                <InputMask
                    className={classnames(styles.input, className, {
                        [styles.noLabel]: !label,
                        [styles.isFilled]: !!value?.length,
                    })}
                    id={id}
                    disabled={isDisabled}
                    mask={mask || ''}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder={label ? '' : placeholder}
                    value={value}
                >
                    {(inputProps: any) => <input {...inputProps} disabled={isDisabled} ref={ref} />}
                </InputMask>
                {label && (
                    <label htmlFor={id} className={styles.label}>
                        {label}
                    </label>
                )}
            </div>
        );
    },
);

export default Field;
