import { useRef } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import usePolling from '@/core/usePolling';

const INTERVAL = 2000;

const GET_IS_XYZ_PLUS_ACTIVE = gql`
    query IsXyzPlusActive {
        xyzPlus {
            isXyzPlusActive
        }
    }
`;

const usePollingIsXyzPlusActive = () => {
    // TODO: use useIsXyzPlusActiveLazyQuery hook
    const [fetchIsXyzPlusActive, { data }] = useLazyQuery(GET_IS_XYZ_PLUS_ACTIVE, {
        fetchPolicy: 'no-cache',
    });
    const isXyzPlusActive = useRef<boolean>(false);

    if (data) {
        isXyzPlusActive.current = data.xyzPlus.isXyzPlusActive;
    }

    usePolling(INTERVAL, () => {
        fetchIsXyzPlusActive();
    });

    return { isXyzPlusActive: isXyzPlusActive.current };
};

export default usePollingIsXyzPlusActive;
