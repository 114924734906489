import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { EnrollmentStatusFragment } from '@/generated/graphql';

gql`
    fragment EnrollmentStatus on EnrollmentType {
        id
        startDate
        endDate
        state
    }
`;

type Props = {
    enrollment: EnrollmentStatusFragment;
};

const EnrollmentStatus = ({ enrollment }: Props) => {
    const { t } = useTranslation(['enrollment']);
    if (!enrollment.state) {
        return null;
    }
    return <>{t('ENROLLMENT_STATE', { context: enrollment.state })}</>;
};
export default EnrollmentStatus;
