import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AuthCodeVerification from '@/features/Auth/AuthCodeVerification';
import Button from '@/ui/Button';

import AuthPageLayout from '../AuthPageLayout';
import AuthCountdown from '../../../features/Auth/AuthCountdown';
import thumb from '../images/code-step-thumb.png';

import styles from './AuthCodeStep.scss';

type Props = {
    phone: string;
    verificationId: string;
    verificationEndDate: string | null;
    onEditPhone: () => void;
    onSuccess: () => void;
    onResendCode: () => void;
};

const AuthCodeStep = ({ phone, verificationId, verificationEndDate, onEditPhone, onSuccess, onResendCode }: Props) => {
    const { t } = useTranslation(['auth']);
    const [isResendActive, setIsResendActive] = useState(false);

    const handleCountdownFinish = () => {
        setIsResendActive(true);
    };

    return (
        <AuthPageLayout
            image={thumb}
            title={t('CODE_STEP_TITLE')}
            description={
                <>
                    {t('CODE_STEP_DESCRIPTION')}
                    &nbsp;
                    <b className={styles.phone}>{phone}</b>
                    <Button styleType="text" size="large" onClick={onEditPhone} className={styles.phoneEditBtn}>
                        {t('CODE_STEP_CHANGE_BTN_TEXT')}
                    </Button>
                </>
            }
        >
            <AuthCodeVerification verificationId={verificationId} onSuccess={onSuccess} />
            <div className={styles.resendBtnWrap}>
                <Button styleType="text" size="large" isDisabled={!isResendActive} onClick={onResendCode}>
                    {t('CODE_STEP_RESEND_BTN_TEXT')}
                </Button>
                &nbsp;
                {verificationEndDate && (
                    <AuthCountdown timestamp={parseFloat(verificationEndDate)} onFinish={handleCountdownFinish} />
                )}
            </div>
        </AuthPageLayout>
    );
};

export default AuthCodeStep;
