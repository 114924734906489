import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button } from '@xyz-school/xyz-react-frontend';

import { CommonDiscordServerInformerFragment } from '@/generated/graphql';
import Informer from '@/ui/Informer';

gql`
    fragment CommonDiscordServerInformer on DiscordServerType {
        inviteUrl
    }
`;

type Props = {
    serverInfo: CommonDiscordServerInformerFragment;
};

const CommonDiscordServerInformer = ({ serverInfo }: Props) => {
    const { t } = useTranslation(['discord']);

    return (
        <Informer
            title={t('COMMON_SERVER_INFORMER_TITLE')}
            description={t('COMMON_SERVER_INFORMER_DESCRIPTION')}
            buttons={
                <Button linkTo={serverInfo.inviteUrl} styleType="primary">
                    {t('COMMON_SERVER_INFORMER_LINK_TITLE')}
                </Button>
            }
        />
    );
};

export default CommonDiscordServerInformer;
