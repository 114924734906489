import { useContext } from 'react';

import CurrentUserProvider from './CurrentUserProvider';
import { FeaturesType } from './features';

export const useFeatures = (): FeaturesType => {
    const { features } = useContext(CurrentUserProvider.Context);

    return features;
};
