import React from 'react';
import { gql } from '@apollo/client';
import { CourseCardPriceFragment } from '@/generated/graphql';
import CourseFreeBadge from '@/models/Course/CourseFreeBadge';

import { formatPrice } from '@/helpers';
import { Text, Container } from '@xyz-school/xyz-react-frontend';

import styles from './CourseCardPrice.scss';

gql`
    fragment CourseCardPrice on CourseType {
        price
        oldPrice
        isFree
    }
`;

type Props = {
    course: CourseCardPriceFragment;
};

const CourseCardPrice = ({ course }: Props) => {
    if (!course.price && course.price !== 0) {
        return null;
    }

    if (course.price === 0 && course.isFree) {
        return <CourseFreeBadge />;
    }

    return (
        <div className={styles.courseCardPrice}>
            {course.oldPrice && course.oldPrice > course.price && (
                <Container marginRight={1}>
                    <Text color="label" size="small" className={styles.oldPrice}>
                        {formatPrice(course.oldPrice)}
                    </Text>
                </Container>
            )}
            <Text size="small"> {formatPrice(course.price)}</Text>
        </div>
    );
};

export default CourseCardPrice;
