import React from 'react';
import { Heading, Media } from '@xyz-school/xyz-react-frontend';
import { gql } from '@apollo/client';

import GridLayout from '@/ui/GridLayout';

import { PageCourseMainInfoFragment } from '@/generated/graphql';

gql`
    fragment PageCourseMainInfo on CourseType {
        courseKind {
            label
        }
    }
`;

type Props = {
    course: PageCourseMainInfoFragment;
};

const PageCourseMainInfo = ({ course }: Props) => {
    return (
        <GridLayout
            leftSideContent={
                <Media greaterThanOrEqual="sm">
                    <Heading level={2}>{course.courseKind.label}</Heading>
                </Media>
            }
        />
    );
};

export default PageCourseMainInfo;
