import React from 'react';

import { Heading, Text } from '@xyz-school/xyz-react-frontend';
import { textRemoveHtml } from '@xyz-school/xyz-react-frontend/lib/utils';

import { EditorJsBlockHeaderType } from '@/models/EditorJsData';

type Props = {
    block: EditorJsBlockHeaderType;
};

const ContentHeader = ({ block }: Props) => {
    /**
     * В нашей дизайн систем нет заголовков меньше чем 3 уровня, так что заголовки, мы показываем либо как Heading, либо как Text
     */
    const LEVELS = {
        1: 2,
        2: 3,
        3: 3,
        4: 3,
    } as const;

    // TODO написть бы как-то это получше :)
    if (block.data.level === 5 || block.data.level === 6) {
        return (
            <Text wrap size={block.data.level === 5 ? 'regular' : 'small'}>
                {textRemoveHtml(block.data.text)}
            </Text>
        );
    }

    return (
        <Heading level={LEVELS[block.data.level]} wrap>
            {/* TODO использовать в будущем нормализация текста от html, на данный момент этот контент заполняют только препады, так что все будет нормально */}
            {textRemoveHtml(block.data.text)}
        </Heading>
    );
};

export default ContentHeader;
