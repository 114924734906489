import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@xyz-school/xyz-react-frontend';

import Informer from '@/ui/Informer';
import Modal from '@/ui/Modal';

import styles from './Quiz.scss';

const Quiz = () => {
    const { t } = useTranslation(['quiz']);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    return (
        <>
            <Informer
                title={t('QUIZ_TITLE')}
                buttons={
                    <Button onClick={() => setIsOpened(true)} styleType="primary">
                        {t('QUIZ_BTN_TEXT')}
                    </Button>
                }
            />
            {isOpened && (
                <Modal onClose={() => setIsOpened(false)}>
                    <iframe
                        className={styles.quizFrame}
                        title="quiz"
                        src="https://sale.school-xyz.com/sale-quiz?utm_source=platform&utm_medium=organic&utm_campaign=quiz"
                    />
                </Modal>
            )}
        </>
    );
};

export default Quiz;
