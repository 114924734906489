import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Text } from '@xyz-school/xyz-react-frontend';
import useFormatedDate from '@/core/useFormatedDate';
import Avatar from '@/ui/Avatar';
import Icon from '@/ui/Icon';
import styles from './CheckerAssignmentListItemUi.scss';

type Props = {
    pk: string;
    courseTitle: string;
    lectureTitle: string;
    contentTitle?: string;
    avatar?: string;
    userName: string;
    dateSubmited: string;
    dateChecked?: string;
    awaitingMinutes?: number;
};

const convertMinutesToDaysAndHours = (minutes: number) => {
    const h = minutes / 60;
    const days = Math.floor(h / 24);
    const hours = Math.floor(h % 24);
    return { days, hours };
};

const TimeStatus = {
    Success: 'success',
    Attention: 'attention',
    Warning: 'warning',
    Fail: 'fail',
} as const;

type TimeStatusType = typeof TimeStatus[keyof typeof TimeStatus];

const CheckerAssignmentListItemUi = ({
    pk,
    courseTitle,
    lectureTitle,
    contentTitle,
    avatar,
    userName,
    dateSubmited,
    dateChecked,
    awaitingMinutes,
}: Props) => {
    const { t } = useTranslation(['staff']);
    const { formatDate } = useFormatedDate();

    const awaitingDays = awaitingMinutes ? convertMinutesToDaysAndHours(awaitingMinutes).days : 0;
    const awaitingHours = awaitingMinutes ? convertMinutesToDaysAndHours(awaitingMinutes).hours : 0;

    const getAwaitingTimeStatus = (days: number): TimeStatusType => {
        if (days <= 2) {
            return TimeStatus.Success;
        }
        if (days > 2 && days <= 3) {
            return TimeStatus.Attention;
        }
        if (days > 3 && days <= 4) {
            return TimeStatus.Warning;
        }
        return TimeStatus.Fail;
    };

    const awaitingTimeStatus = getAwaitingTimeStatus(awaitingDays);
    const showWarningIcon = awaitingTimeStatus === TimeStatus.Warning || awaitingTimeStatus === TimeStatus.Fail;

    return (
        <a href={`/admin/students/assignment/${pk}/change/`} className={styles.checkerAssignmentListItem}>
            <div>
                <Text color="label" size="small">
                    {t('DATE_SUBMITED_LABEL')}
                </Text>
                <Text className={styles.date} size="small">
                    {formatDate(dateSubmited, 'DD:MM:YYYY hh:mm')}
                </Text>
            </div>
            <div className={styles.infoIconsWrap}>
                {dateChecked && (
                    <div title={t('IS_CORRECTION_LABEL')}>
                        <Icon name="works" size="medium" />
                    </div>
                )}
            </div>
            <div>
                <div className={styles.nestingList}>
                    <Text color="label" size="small">
                        {courseTitle}
                    </Text>
                    <Text color="label" size="small">
                        {lectureTitle}
                    </Text>
                    {contentTitle && (
                        <Text color="label" size="small">
                            {contentTitle}
                        </Text>
                    )}
                </div>
                <div className={styles.userWrap}>
                    <div className={styles.userAvatarWrap}>
                        <Avatar src={avatar} alt={userName} userName={userName} size="small" />
                    </div>
                    <Text size="small">{userName}</Text>
                </div>
            </div>
            <div>
                {showWarningIcon && (
                    <Icon name="course-error" color={awaitingTimeStatus} className={styles.attentionIcon} />
                )}
            </div>
            <div className={styles.awaitingWrap}>
                <Text color="label" size="small">
                    {t('AWAITING_TIME_LABEL')}
                </Text>
                <div className={styles.keyValueWrap}>
                    <Text color="label" size="small">
                        {t('DAYS_LABEL')}
                    </Text>
                    <Text size="small" className={classnames(styles[awaitingTimeStatus])}>
                        {awaitingDays}
                    </Text>
                </div>
                <div className={styles.keyValueWrap}>
                    <Text color="label" size="small">
                        {t('HOURS_LABEL')}
                    </Text>
                    <Text size="small" className={classnames(styles[awaitingTimeStatus])}>
                        {awaitingHours}
                    </Text>
                </div>
            </div>
        </a>
    );
};

export default CheckerAssignmentListItemUi;
