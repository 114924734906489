import React from 'react';
import { gql } from '@apollo/client';
import { DateFormatter } from '@xyz-school/xyz-react-frontend';

import { EnrollmentDateFragment } from '@/generated/graphql';

gql`
    fragment EnrollmentDate on EnrollmentType {
        startDate
        endDate
    }
`;

type Props = {
    enrollment: EnrollmentDateFragment;
};

const EnrollmentDate = ({ enrollment }: Props) => {
    if (!enrollment.startDate) {
        return null;
    }

    return (
        <>
            <DateFormatter date={enrollment.startDate} type="simpleDate" />
            {!!enrollment.endDate && (
                <>
                    <span> - </span>
                    <DateFormatter date={enrollment.endDate} type="simpleDate" />
                </>
            )}
        </>
    );
};
export default EnrollmentDate;
