/* eslint-disable no-undef */

import { useState, useEffect } from 'react';

const ANIMATION_PENDING = 300;

const useLoading = (isLoading: boolean): boolean => {
    const [isLoadingShow, setIsLoadingShow] = useState<boolean>(false);
    const [isAnimationPending, setIsAnimationPending] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        if (!isLoadingShow && isLoading) {
            setIsLoadingShow(true);
            setIsAnimationPending(true);
            setTimeoutId(setTimeout(() => setIsAnimationPending(false), ANIMATION_PENDING));
        }
        if (isLoadingShow && !isLoading && !isAnimationPending) {
            setIsLoadingShow(false);
        }
    }, [isLoading, isLoadingShow, isAnimationPending]);

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return isLoadingShow;
};

export default useLoading;
