import React, { ReactNode } from 'react';
import classnames from 'classnames';
import Icon from '@/ui/Icon';

import styles from './NotificationItem.scss';

type Props = {
    className?: string;
    children: ReactNode;
};

const NotificationItem = ({ className, children }: Props) => {
    return (
        <div className={classnames(styles.notificationItem, className)}>
            <Icon name="attention" className={styles.notificationIcon} />
            <div className={styles.notificationContent}>{children}</div>
        </div>
    );
};

export default NotificationItem;
