import React from 'react';

import {
    ContentImage,
    ContentList,
    ContentParagraph,
    ContentQuote,
    ContentTable,
    ContentWarning,
    ContentCaption,
} from '@xyz-school/xyz-react-frontend/lib/content';
import { Attachment, Link, Separator } from '@xyz-school/xyz-react-frontend';
import {
    EDITOR_JS_BLOCK_TYPE,
    EDITOR_JS_EMBED_SERVICE,
    EditorJsBlockType,
    EditorJsBlockTypeType,
} from '@/models/EditorJsData';

import ContentHeader from './ContentHeader';
import ContentCode from './ContentCode';
import ContentVimeo from './ContentVimeo';
import ContentYoutube from './ContentYoutube';
import ContentKinescope from './ContentKinescope';

import { getKinescopeVideoId } from './ContentKinescope/helpers';

type Props = {
    block: EditorJsBlockType;
    prevBlockType?: EditorJsBlockTypeType;
};

const EditorJsDataViewBlock = ({ block, prevBlockType }: Props) => {
    switch (block.type) {
        case EDITOR_JS_BLOCK_TYPE.code:
            return <ContentCode block={block} />;

        case EDITOR_JS_BLOCK_TYPE.paragraph:
            return (
                <ContentParagraph text={block.data.text} isHasIndent={prevBlockType === EDITOR_JS_BLOCK_TYPE.header} />
            );

        case EDITOR_JS_BLOCK_TYPE.linkTool:
            return (
                <Link to={block.data.link} target="_blank">
                    <Attachment
                        type="link"
                        title={block.data.meta.title}
                        subtitle={block.data.meta.description}
                        preview={typeof block.data.meta.image.url === 'string' ? block.data.meta.image.url : undefined}
                        isFullWidth
                    />
                </Link>
            );

        case EDITOR_JS_BLOCK_TYPE.image:
            return (
                <ContentImage
                    src={block.data.file.url}
                    width={block.data.file.width}
                    height={block.data.file.height}
                    title={block.data.caption}
                    description={block.data.caption}
                />
            );

        case EDITOR_JS_BLOCK_TYPE.quote:
            return <ContentQuote authorName={block.data.caption}>{block.data.text}</ContentQuote>;

        case EDITOR_JS_BLOCK_TYPE.warning:
            return <ContentWarning title={block.data.title}>{block.data.message}</ContentWarning>;

        case EDITOR_JS_BLOCK_TYPE.table:
            return <ContentTable data={block.data.content} />;

        case EDITOR_JS_BLOCK_TYPE.header:
            return <ContentHeader block={block} />;

        case EDITOR_JS_BLOCK_TYPE.list:
            return <ContentList items={block.data.items} isOrdered={block.data.style === 'ordered'} />;

        case EDITOR_JS_BLOCK_TYPE.delimiter:
            return <Separator />;

        case EDITOR_JS_BLOCK_TYPE.attaches:
            return (
                <Link to={block.data.file.url} target="_blank">
                    <Attachment
                        type="file"
                        title={block.data.title}
                        /**
                         * TODO в subtitle мы должны писать размер файла, в media для этого используеться библиотека prettyBytes
                         * Возможно надо перенести prettyBytes прямо в Attachment в ds
                         * */
                        subtitle=""
                        isFullWidth
                    />
                </Link>
            );

        case EDITOR_JS_BLOCK_TYPE.embed:
            switch (block.data.service) {
                case EDITOR_JS_EMBED_SERVICE.youtube:
                    return (
                        <>
                            <ContentYoutube url={block.data.source} />
                            {block.data.caption && <ContentCaption>{block.data.caption}</ContentCaption>}
                        </>
                    );
                case EDITOR_JS_EMBED_SERVICE.vimeo:
                    // @todo для текущих курсов с видео Vimeo используется ссылка из `data.embed`, а в `data.source` прописана кривая ссылка на 404 страницу
                    return (
                        <>
                            <ContentVimeo url={block.data.embed} />
                            {block.data.caption && <ContentCaption>{block.data.caption}</ContentCaption>}
                        </>
                    );
                case EDITOR_JS_EMBED_SERVICE.kinescope: {
                    const kinescopeVideoId = getKinescopeVideoId({ url: block.data.embed });

                    if (kinescopeVideoId) {
                        return (
                            <>
                                <ContentKinescope id={kinescopeVideoId} />
                                {block.data.caption && <ContentCaption>{block.data.caption}</ContentCaption>}
                            </>
                        );
                    }
                    return null;
                }
                default:
                    return null;
            }

        default:
            return null;
    }
};

export default EditorJsDataViewBlock;
