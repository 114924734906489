import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Container, Heading, Text } from '@xyz-school/xyz-react-frontend';

import Icon from '@/ui/Icon';
import styles from './CollapseItemLayout.scss';

type Props = {
    children: ReactNode;
    description?: string;
    isOpen?: boolean;
    title: string;
    onToogle: () => void;
};

const CollapseItemLayout = ({ children, isOpen = false, title, description, onToogle }: Props) => {
    return (
        <div className={styles.CollapseItemLayout}>
            <Container
                paddingTop={3}
                paddingTopXs={2}
                paddingBottom={3}
                paddingBottomXs={3}
                className={styles.titleContainer}
            >
                <button type="button" className={styles.titleRow} onClick={onToogle}>
                    <Heading level={3} className={styles.title}>
                        {title}
                    </Heading>
                    <Container paddingLeft={2}>
                        <Icon
                            name="caret-down"
                            color="text"
                            className={classnames(styles.caretIcon, { [styles.caretIcon_collapsed]: !isOpen })}
                        />
                    </Container>
                </button>
                {description && isOpen && (
                    <Container marginTop={1}>
                        <Text color="label" size="small">
                            {description}
                        </Text>
                    </Container>
                )}
            </Container>
            {isOpen && <div>{children}</div>}
        </div>
    );
};

export default CollapseItemLayout;
