import React from 'react';
import { gql } from '@apollo/client';

import { PageXyzPlusContentControlsFragment } from '@/generated/graphql';
import PageXyzPlusContentControlsUi from './ui/PageXyzPlusContentControlsUi';

gql`
    fragment PageXyzPlusContentControls on XyzPlusContentType {
        access {
            view
        }
    }
`;

type Props = {
    data?: PageXyzPlusContentControlsFragment;
};

const PageXyzPlusContentControls = ({ data }: Props) => {
    if (data?.access?.view) {
        return null;
    }

    return <PageXyzPlusContentControlsUi />;
};

export default PageXyzPlusContentControls;
