import React from 'react';
import { gql } from '@apollo/client';

import { EnrollmentBundleCourseCardFragment } from '@/generated/graphql';
import { useTranslation } from 'react-i18next';

import { BlockLabel, Container, Text } from '@xyz-school/xyz-react-frontend';

import useFormatedDate from '@/core/useFormatedDate';
import { useFeatures } from '@/models/User/useFeature';
import BadgePlacer from '@/ui/BadgePlacer';
import Image from '@/ui/Image';
import { courseHelpers } from '@/models/Course';
import CardLong from '@/ui/CardLong';

gql`
    fragment EnrollmentBundleCourseCard on CourseType {
        courseKind {
            label
        }
        thumbnail
        title
    }
`;

type Props = {
    course: EnrollmentBundleCourseCardFragment;
    startDate: string | null;
};

const EnrollmentBundleCourseCard = ({ course, startDate }: Props) => {
    const { t } = useTranslation(['dashboard', 'course']);
    const { formatDate } = useFormatedDate();
    const features = useFeatures();

    return (
        <CardLong
            title={course.title}
            description={
                <Text size="small" color="label">
                    {t('course:NOT_ENROLLED_INFO')}

                    {features.BundleNextCourseStartDate && (
                        <Container display="inline" marginLeft={2}>
                            {startDate ? (
                                <>
                                    <Container display="inline" marginRight={1}>
                                        {t('course:EXPECTED_START_DATE', {
                                            startDate: formatDate(startDate, 'DD MMMM'),
                                        })}
                                    </Container>
                                </>
                            ) : (
                                t('course:UNKNOWN_START_DATE')
                            )}
                        </Container>
                    )}
                </Text>
            }
            image={
                <BadgePlacer topLeft={<BlockLabel>{course.courseKind.label}</BlockLabel>}>
                    <Image image={courseHelpers.getCover(course)} coverAlt={course.title} />
                </BadgePlacer>
            }
        />
    );
};
export default EnrollmentBundleCourseCard;
