import React, { useMemo, ReactNode } from 'react';

import LazyLoad from 'react-lazyload';
import { gql } from '@apollo/client';
import { useStreamsQuery, StreamCardFragmentDoc } from '@/generated/graphql';

import { useTranslation } from 'react-i18next';
import { Container, Heading, Link, Text, useNotificationActions } from '@xyz-school/xyz-react-frontend';

import { routes } from '@/Routes';
import CardListGridLayout from '@/ui/CardListGridLayout';
import StreamListSkeleton from '../ui/StreamListSkeleton';
import { isToday, isInFuture, isInPast, byClosestUpcomingDate, byClosestPastDate } from '../helpers';

import StreamCard from '../StreamCard';

gql`
    query streams($after: String) {
        streams(after: $after) {
            edges {
                node {
                    id
                    startDate
                    access {
                        view
                    }
                    ...StreamCard
                }
            }
        }
    }
    ${StreamCardFragmentDoc}
`;

const StreamList = () => {
    const { data, loading } = useStreamsQuery();
    const { t } = useTranslation(['stream']);
    const { pushNotification } = useNotificationActions();

    const streams = useMemo(() => {
        const edges = data?.streams?.edges;
        return {
            TODAY: edges
                ?.filter((stream) => isToday(stream?.node?.startDate))
                .sort((a, b) => byClosestUpcomingDate(a?.node?.startDate, b?.node?.startDate)),
            FUTURE: edges
                ?.filter((stream) => isInFuture(stream?.node?.startDate))
                .sort((a, b) => byClosestUpcomingDate(a?.node?.startDate, b?.node?.startDate)),
            PAST: edges
                ?.filter((stream) => isInPast(stream?.node?.startDate))
                .sort((a, b) => byClosestPastDate(a?.node?.startDate, b?.node?.startDate)),
        };
    }, [data?.streams?.edges]);

    // const streams = {
    //     TODAY: data?.streams?.edges,
    // };

    if (loading) {
        return <StreamListSkeleton />;
    }

    if (!loading && !data?.streams?.edges.length) {
        return (
            <Container marginBottom={4}>
                <Text color="label">{t('TITLE_NO_STREAMS')}</Text>
                <Text color="label">{t('DESCRIPTION_NO_STREAMS')}</Text>
            </Container>
        );
    }

    return (
        <>
            {Object.entries(streams).map(([key, list]) => {
                if (!list?.length) {
                    return null;
                }
                return (
                    <StreamList.Section title={t(`${key}_STREAMS_TITLE`)}>
                        <CardListGridLayout>
                            {list.map((item) => {
                                if (!item?.node) {
                                    return null;
                                }
                                return (
                                    <LazyLoad once offset={200} key={item?.node?.id}>
                                        <StreamList.CardTrigger
                                            hasAssess={item?.node?.access.view}
                                            id={item?.node?.id}
                                            onNoAccessClick={() => {
                                                pushNotification({
                                                    type: 'warning',
                                                    message: t('NO_ACCESS_NOTIFICATION_TEXT'),
                                                    name: 'CourseClassNotStartedYet',
                                                });
                                            }}
                                        >
                                            <StreamCard stream={item?.node} key={item?.node?.id} />
                                        </StreamList.CardTrigger>
                                    </LazyLoad>
                                );
                            })}
                        </CardListGridLayout>
                    </StreamList.Section>
                );
            })}
        </>
    );
};

type StreamListSectionProp = {
    title: string;
    children: ReactNode;
};

StreamList.Section = ({ title, children }: StreamListSectionProp) => {
    return (
        <>
            <Heading level={2}>{title}</Heading>
            <Container paddingTop={3} paddingBottom={6}>
                {children}
            </Container>
        </>
    );
};

type StreamListCardTriggerProp = {
    id: string;
    hasAssess: boolean;
    children: ReactNode;
    onNoAccessClick: () => void;
};

StreamList.CardTrigger = ({ children, hasAssess, id, onNoAccessClick }: StreamListCardTriggerProp) => {
    if (hasAssess) {
        return <Link to={routes.STREAM(id)}>{children}</Link>;
    }
    return <div onClick={onNoAccessClick}>{children}</div>;
};

export default StreamList;
