import React from 'react';
import classnames from 'classnames';

import { Text } from '@xyz-school/xyz-react-frontend';

import styles from './RadioButton.scss';

type Props<T> = {
    id: string;
    label: string;
    value: T;
    name: string;
    className?: string;
    isChecked?: boolean;
    isFullWidth?: boolean;
    onChange?: (value: T) => void;
};

const RadioButton = <T extends number | string>({
    id,
    label,
    value,
    name,
    className,
    isChecked = false,
    isFullWidth = false,
    onChange,
}: Props<T>) => {
    const handleChange = (): void => {
        if (onChange) {
            onChange(value);
        }
    };

    return (
        <label htmlFor={id} className={className}>
            <input
                className={styles.nativeRadio}
                id={id}
                type="radio"
                value={value}
                name={name}
                checked={isChecked}
                onChange={handleChange}
            />
            <div
                className={classnames(styles.radioButton, {
                    [styles.checked]: isChecked,
                    [styles.isFullWidth]: isFullWidth,
                })}
            >
                <Text tag="span" size="small">
                    {label}
                </Text>
            </div>
        </label>
    );
};

export default RadioButton;
