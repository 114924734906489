import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Link, Button } from '@xyz-school/xyz-react-frontend';

import styles from './ContactsUi.scss';
import СenteredLayout from '../СenteredLayout';

import contactsFormIcon from './images/contactsFormIcon.png';

type Props = {
    children?: ReactNode;
    onSkipBtnClick: () => void;
};

const ContactsUi = ({ children, onSkipBtnClick }: Props) => {
    const { t } = useTranslation(['subscription']);

    return (
        <СenteredLayout
            aboveHeading={<img src={contactsFormIcon} alt="" className={styles.contactsFormIcon} />}
            heading={t('CONTACTS_TITLE')}
            description={t('CONTACTS_DESCRIPTION')}
        >
            <div className={styles.childrenWrap}>{children}</div>
            <Button styleType="text" size="large" className={styles.skipBtn} onClick={onSkipBtnClick}>
                {t('CONTACTS_SKIP_BTN_TEXT')}
            </Button>
            <div className={styles.agreementWrap}>
                <Text color="label" align="center" inline>
                    {t('AGREEMENT_LABEL_TEXT')}
                </Text>
                &nbsp;
                <Link to="https://network-xyz.com/ru/info/privacy-policy" target="_blank">
                    <Text color="accent" inline>
                        {t('AGREEMENT_LINK_TEXT')}
                    </Text>
                </Link>
            </div>
        </СenteredLayout>
    );
};

export default ContactsUi;
