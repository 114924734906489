import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlockLabel, Heading, Text } from '@xyz-school/xyz-react-frontend';

import CardLayout from '@/ui/CardLayout';
import Image from '@/ui/Image';

import styles from './XyzPlusContentCardUi.scss';

type Props = {
    title: string;
    thumbnail?: string;
    courseTitle?: string;
    categoryTitle?: string;
    isNew?: boolean;
    isDraft?: boolean;
};

const XyzPlusContentCardUi = ({ title, thumbnail, courseTitle, categoryTitle, isNew, isDraft }: Props) => {
    const { t } = useTranslation(['subscription']);

    return (
        <CardLayout
            className={styles.contetntCard}
            labels={
                <>
                    {!!categoryTitle && <BlockLabel className={styles.label}>{categoryTitle}</BlockLabel>}
                    {isNew && <BlockLabel type="success">{t('CONTENT_IS_NEW')}</BlockLabel>}
                    {isDraft && <BlockLabel type="warning">{t('CONTENT_IS_DRAFT')}</BlockLabel>}
                </>
            }
            thumbnail={<Image image={thumbnail} coverAlt={title} />}
        >
            <Heading level={3} className={styles.title}>
                {title}
            </Heading>
            {!!courseTitle && (
                <Text color="label" size="small">
                    {t('CONTENT_FROM_COURSE', { courseTitle })}
                </Text>
            )}
        </CardLayout>
    );
};

export default XyzPlusContentCardUi;
