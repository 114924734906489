import React from 'react';
import classnames from 'classnames';
import { Button, Container, Heading, Separator, Text } from '@xyz-school/xyz-react-frontend';

import DiscountBadge from '../DiscountBadge';
import InfoLabel from '../InfoLabel';
import styles from './TariffItem.scss';

type Props = {
    title: string;
    duration: string;
    label?: string;
    description: string;
    priceWithDiscount: string;
    price?: string;
    discount?: number;
    btnText: string;
    info: string;
    isHighlighted?: boolean;
    onBtnClick: () => void;
};

const TariffItem = ({
    title,
    duration,
    label,
    description,
    price,
    priceWithDiscount,
    discount,
    btnText,
    info,
    isHighlighted,
    onBtnClick,
}: Props) => {
    return (
        <div className={classnames(styles.tariffItem, { [styles.isHighlighted]: isHighlighted })}>
            <div>
                {!!discount && (
                    <div className={styles.discountBadgeWrap}>
                        <DiscountBadge value={discount} />
                    </div>
                )}
                <div className={styles.headingWrap}>
                    <Heading className={styles.heading} level={2}>
                        {title}
                    </Heading>
                    <Text>{duration}</Text>
                </div>
                <Container paddingTop={1} className={styles.labelWrap}>
                    {!!label && <InfoLabel value={label} color={isHighlighted ? 'success' : 'accent'} />}
                </Container>
                <Separator marginTop={0} marginBottom={2} />
                <Text>{description}</Text>
            </div>
            <div>
                <Separator marginTop={2} marginBottom={2} />
                <Container marginBottom={2} className={styles.priceWrap}>
                    <Heading level={3}>{priceWithDiscount}</Heading>
                    {!!price && (
                        <Text size="small" color="label" className={styles.price}>
                            {price}
                        </Text>
                    )}
                </Container>
                <Button styleType="primary" size="large" isFullWidth onClick={onBtnClick}>
                    {btnText}
                </Button>
                <Text size="small" color="label" align="center" className={styles.info}>
                    {info}
                </Text>
            </div>
        </div>
    );
};

export default TariffItem;
