import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { CourseCardControlsFragment } from '@/generated/graphql';

import { courseHelpers } from '@/models/Course';
import { Button, Container } from '@xyz-school/xyz-react-frontend';

import styles from './CourseCardControls.scss';

gql`
    fragment CourseCardControls on CourseType {
        slug
        isFree
        infoUrl
        purchaseUrl
        access {
            view
        }
    }
`;

type Props = {
    course: CourseCardControlsFragment;
    isRecomended?: boolean;
    infoUrl: string;
};

const CourseCardControls = ({ course, isRecomended, infoUrl }: Props) => {
    const { t } = useTranslation(['course']);

    return (
        <div className={styles.courseCardControls}>
            {course.purchaseUrl && !isRecomended && (
                <Container marginRight={1}>
                    <Button linkTo={`${course.purchaseUrl}`} styleType="primary">
                        {t('PURCHASE_BTN_TEXT')}
                    </Button>
                </Container>
            )}
            {course.isFree ? (
                <Button linkTo={courseHelpers.getUrl(course)}>{t('SIGNUP_BTN_TEXT')}</Button>
            ) : (
                <Button linkTo={course.access.view ? courseHelpers.getUrl(course) : infoUrl}>
                    {t('INFO_BTN_TEXT')}
                </Button>
            )}
        </div>
    );
};

export default CourseCardControls;
