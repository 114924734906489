import React from 'react';
import { Skeleton } from '@xyz-school/xyz-react-frontend';

import ImageSkeleton from '@/ui/ImageSkeleton';
import CourseProgressSkeleton from '@/models/Course/ui/CourseProgressSkeleton';

import CardLong from '@/ui/CardLong';
import styles from './CardLongSkeleton.scss';

const CardLongSkeleton = () => {
    return (
        <CardLong
            title={<Skeleton className={styles.title} />}
            image={<ImageSkeleton />}
            description={<Skeleton className={styles.description} />}
            footer={<CourseProgressSkeleton />}
        />
    );
};

export default CardLongSkeleton;
