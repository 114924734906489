import dayjs from 'dayjs';

type VacationDatesType = { vacationStartDate: string | null; vacationEndDate: string | null };

type getVacationDaysCountDTO = {
    startDate: string | null;
    endDate: string | null;
};
type getVacationDatesDTO = {
    enrollmentEndDate: string;
    isEnrollmentCourseFree: boolean;
    nextCourseId?: string;
    isNextCourseFree?: boolean;
    nextCourseToEnrollId?: string;
    nextCourseToEnrollStartDate?: string;
    nextEnrollmentStartDate?: string;
};

export const getVacationDaysCount = ({ startDate, endDate }: getVacationDaysCountDTO) => {
    if (startDate && endDate) {
        return dayjs(endDate).diff(startDate, 'day');
    }
    return 0;
};

export const getVacationDates = ({
    enrollmentEndDate,
    isEnrollmentCourseFree,
    nextCourseId,
    isNextCourseFree,
    nextCourseToEnrollId,
    nextCourseToEnrollStartDate,
    nextEnrollmentStartDate,
}: getVacationDatesDTO): VacationDatesType => {
    const dates: VacationDatesType = {
        vacationStartDate: null,
        vacationEndDate: null,
    };

    if (isEnrollmentCourseFree || isNextCourseFree) {
        return dates;
    }

    if (nextCourseToEnrollId && nextCourseId && nextCourseToEnrollId === nextCourseId) {
        dates.vacationStartDate = enrollmentEndDate;
        dates.vacationEndDate = nextCourseToEnrollStartDate || null;
    }

    if (nextEnrollmentStartDate) {
        dates.vacationStartDate = enrollmentEndDate;
        dates.vacationEndDate = nextEnrollmentStartDate;
    }

    const vacationDaysCount = getVacationDaysCount({
        startDate: dates.vacationStartDate,
        endDate: dates.vacationEndDate,
    });

    if (vacationDaysCount <= 1) {
        dates.vacationStartDate = null;
        dates.vacationEndDate = null;
    }

    return dates;
};
