import React, { ReactNode } from 'react';
import classnames from 'classnames';

import styles from './Popover.scss';

export const PLACEMENT = {
    left: 'left',
    right: 'right',
    top: 'top',
    bottom: 'bottom',
    topLeft: 'topLeft',
    topRight: 'topRight',
    bottomLeft: 'bottomLeft',
    bottomRight: 'bottomRight',
} as const;

type Props = {
    children: ReactNode;
    content: ReactNode;
    placement?: keyof typeof PLACEMENT;
    className?: string;
};

const Popover = ({ children, content, placement = PLACEMENT.bottom, className }: Props) => {
    return (
        <div className={classnames(styles.popover, className)}>
            <div className={styles.popoverTriggerWrap}>{children}</div>
            <div
                className={classnames(styles.popoverContent, {
                    [styles[`popoverContent_${placement}`]]: placement,
                })}
            >
                {content}
            </div>
        </div>
    );
};

export default Popover;
