import React, { ComponentProps, useCallback } from 'react';
import { useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import BreadcrumbsWrapperUi from './BreadcrumbsWrapperUi';

type Props = Pick<ComponentProps<typeof BreadcrumbsWrapperUi>, 'children' | 'className'>;

const BreadcrumbsWrapper = (props: Props) => {
    const history = useLocalizedHistory();
    const handleBack = useCallback(() => {
        history.goBack();
    }, [history]);

    return <BreadcrumbsWrapperUi {...props} isLinkBack={history.length > 2} onGoBack={handleBack} />;
};

export default BreadcrumbsWrapper;
