export const EDITOR_JS_BLOCK_TYPE = {
    paragraph: 'paragraph',
    delimiter: 'delimiter',
    linkTool: 'linkTool',
    list: 'list',
    header: 'header',
    image: 'image',
    quote: 'quote',
    warning: 'warning',
    table: 'table',
    attaches: 'attaches',
    embed: 'embed',
    code: 'code',
} as const;

export type EditorJsBlockTypeType = typeof EDITOR_JS_BLOCK_TYPE[keyof typeof EDITOR_JS_BLOCK_TYPE];

export const EDITOR_JS_EMBED_SERVICE = {
    vimeo: 'vimeo',
    youtube: 'youtube',
    kinescope: 'kinescope',
} as const;

export type EditorJsEmbedServiceType = typeof EDITOR_JS_EMBED_SERVICE[keyof typeof EDITOR_JS_EMBED_SERVICE];

type BlockType<N, T> = {
    data: T;
    type: N;
};

export type EditorJsBlockParagraphType = BlockType<typeof EDITOR_JS_BLOCK_TYPE.paragraph, { text: string }>;
export type EditorJsBlockCodeType = BlockType<typeof EDITOR_JS_BLOCK_TYPE.code, { code: string }>;
export type EditorJsBlockDelimiterType = BlockType<typeof EDITOR_JS_BLOCK_TYPE.delimiter, {}>;
export type EditorJsBlockLinkToolType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.linkTool,
    {
        link: string;
        meta: {
            image: {
                url: {} | string;
            };
            title: string;
            description: string;
        };
    }
>;
export type EditorJsBlockListType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.list,
    {
        items: string[];
        style: 'ordered' | 'unordered';
    }
>;
export type EditorJsBlockHeaderType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.header,
    {
        text: string;
        level: 1 | 2 | 3 | 4 | 5 | 6;
    }
>;
export type EditorJsBlockImageType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.image,
    {
        file: {
            url: string;
            name: string;
            size: number;
            width?: number;
            height?: number;
            extension: string;
        };
        caption?: string;
        stretched: boolean;
        withBorder: boolean;
        withBackground: boolean;
    }
>;
export type EditorJsBlockQuoteType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.quote,
    {
        text: string;
        caption?: string;
    }
>;
export type EditorJsBlockWarningType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.warning,
    {
        title: string;
        message: string;
    }
>;
export type EditorJsBlockTableType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.table,
    {
        content: string[][];
    }
>;
export type EditorJsBlockAttachesType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.attaches,
    {
        file: {
            url: string;
            name: string;
            size: number;
            extension: string;
        };
        title: string;
    }
>;
export type EditorJsBlockEmbedType = BlockType<
    typeof EDITOR_JS_BLOCK_TYPE.embed,
    {
        embed: string;
        width: number;
        height: number;
        source: string;
        caption: string;
        service: EditorJsEmbedServiceType;
    }
>;
export type EditorJsBlockType =
    | EditorJsBlockParagraphType
    | EditorJsBlockCodeType
    | EditorJsBlockDelimiterType
    | EditorJsBlockLinkToolType
    | EditorJsBlockListType
    | EditorJsBlockHeaderType
    | EditorJsBlockImageType
    | EditorJsBlockQuoteType
    | EditorJsBlockWarningType
    | EditorJsBlockTableType
    | EditorJsBlockAttachesType
    | EditorJsBlockEmbedType;
