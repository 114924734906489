import React, { ReactNode } from 'react';
import classnames from 'classnames';
import { Container, Text } from '@xyz-school/xyz-react-frontend';

import styles from './Informer.scss';

const TYPE = {
    ACCENT: 'ACCENT',
    WARNING: 'WARNING',
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
} as const;

type InformerTypeType = keyof typeof TYPE;

type Props = {
    className?: string;
    type?: InformerTypeType;
    title?: string;
    description?: string;
    buttons?: ReactNode;
    isCompact?: boolean;
};

const Informer = ({ type = TYPE.ACCENT, title, description, className, buttons, isCompact = false }: Props) => {
    return (
        <div
            className={classnames(styles.informer, className, {
                [styles.accent]: type === TYPE.ACCENT,
                [styles.warning]: type === TYPE.WARNING,
                [styles.success]: type === TYPE.SUCCESS,
                [styles.fail]: type === TYPE.FAIL,
                [styles.compact]: isCompact,
            })}
        >
            <div>
                {title && (
                    <Container marginBottom={0.5}>
                        <Text>{title}</Text>
                    </Container>
                )}
                {description && (
                    <Text size="small" color="label">
                        {description}
                    </Text>
                )}
            </div>
            {!!buttons && (
                <Container marginTop={1.5} className={styles.buttons}>
                    {buttons}
                </Container>
            )}
        </div>
    );
};

Informer.type = TYPE;

export default Informer;
