import React from 'react';
import { Link, LinkBack, Breadcrumbs, Container } from '@xyz-school/xyz-react-frontend';
import { routes } from '@/Routes';

import styles from './BreadcrumbsWrapperUi.scss';

type Props = {
    children?: React.ReactNode;
    className?: string;
    onGoBack: () => void;
    isLinkBack: boolean;
};

const BreadcrumbsWrapperUi = ({ children, className, isLinkBack, onGoBack }: Props) => (
    <Container marginBottom={4} marginBottomSm={2} marginBottomXs={2} className={styles.container}>
        <Breadcrumbs
            className={className}
            linkBack={
                isLinkBack && (
                    <Container marginRight={3} marginRightSm={0} paddingRight={0}>
                        <LinkBack className={styles.backLink} onClick={onGoBack} />
                    </Container>
                )
            }
            linkHome={<Link to={routes.HOME}>Главная страница</Link>}
        >
            {children}
        </Breadcrumbs>
    </Container>
);

export default BreadcrumbsWrapperUi;
