import React, { ReactNode } from 'react';
import classnames from 'classnames';

import useScrollDirection from './useScrollDirection';
import useWindowSize from './useWindowSize';
import styles from './MobileBottomBar.scss';

type Props = {
    children: ReactNode;
};

const SMALL_MOBILE_HEIGHT: number = 500;

const MobileBottomBar = ({ children }: Props) => {
    // TODO: use hooks only if window height is less then SMALL_MOBILE_HEIGHT
    const { isDirectionDown } = useScrollDirection();
    const { height } = useWindowSize();

    const hideBottomBar: boolean = !!height && height <= SMALL_MOBILE_HEIGHT && isDirectionDown;
    return (
        <div className={classnames(styles.MobileBottomBar, { [styles.MobileBottomBar_hidden]: hideBottomBar })}>
            {children}
        </div>
    );
};

export default MobileBottomBar;
