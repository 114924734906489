/* eslint-disable react/button-has-type */
import React, { ReactNode, MouseEvent } from 'react';
import classnames from 'classnames';

import { LoadingSpinner, Icon, IconSizeType } from '@xyz-school/xyz-react-frontend';
import { ThemeProvider } from '@xyz-school/xyz-react-frontend/lib/theme';

import useLoading from './useLoading';
import styles from './Button.scss';

const SIZE = {
    normal: 'normal',
    large: 'large',
} as const;

const STYLE = {
    accent: 'accent',
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    text: 'text',
} as const;

const TYPE = {
    button: 'button',
    submit: 'submit',
    reset: 'reset',
} as const;

type ButtonSizeType = keyof typeof SIZE;

type ButtonStyleType = keyof typeof STYLE;

type ButtonTypeType = keyof typeof TYPE;

type Props = {
    className?: string;
    icon?: string;
    children?: ReactNode;
    styleType?: ButtonStyleType;
    size?: ButtonSizeType;
    isDisabled?: boolean;
    isActive?: boolean;
    isFullWidth?: boolean;
    isLoading?: boolean;
    isHover?: boolean;
    isPressed?: boolean;
    isFocus?: boolean;
    isSquare?: boolean;
    type?: ButtonTypeType;
    onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
    linkTo?: string;
    linkTarget?: string;
};

type ButtonIconProps = {
    styleType?: ButtonStyleType;
    isLoading?: boolean;
    icon?: string;
    size?: IconSizeType | null;
};

const ButtonIcon = ({ styleType, isLoading, icon, size }: ButtonIconProps) => {
    if (isLoading) {
        if (styleType === STYLE.secondary) {
            return (
                <ThemeProvider theme="invert" className={styles.icon}>
                    <LoadingSpinner />
                </ThemeProvider>
            );
        }
        return <LoadingSpinner className={styles.icon} />;
    }
    if (icon) {
        return <Icon name={icon} size={size} className={styles.icon} color="inherit" />;
    }
    return null;
};

const Button = ({
    className,
    children = null,
    size = SIZE.normal,
    type = TYPE.button,
    styleType = STYLE.secondary,
    isDisabled = false,
    isActive,
    isHover,
    isPressed,
    isFocus,
    isFullWidth = false,
    isSquare = false,
    icon,
    linkTo,
    linkTarget,
    onClick,
    isLoading = false,
}: Props) => {
    const isLoadingShow = useLoading(isLoading);

    const handleClick = (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (isLoadingShow || isDisabled || !onClick) {
            return;
        }
        onClick(event);
    };

    const content = (
        <>
            <ButtonIcon
                styleType={styleType}
                isLoading={isLoadingShow}
                icon={icon}
                size={size === SIZE.large ? 'extra-large' : null}
            />
            {!!children && <span className={styles.content}>{children}</span>}
        </>
    );

    const buttonClassName = classnames(styles.button, className, styles[styleType], [styles[size]], {
        [styles.active]: isActive,
        [styles.pressed]: isPressed,
        [styles.focus]: isFocus,
        [styles.hover]: isHover,
        [styles.full]: isFullWidth,
        [styles.square]: isSquare,
        [styles.loadingWithText]: !icon && isLoadingShow && !!children,
    });

    if (linkTo && !isDisabled && !isLoadingShow) {
        return (
            <a className={buttonClassName} href={linkTo} target={linkTarget}>
                {content}
            </a>
        );
    }

    return (
        <button className={buttonClassName} type={type} disabled={isDisabled} onClick={handleClick}>
            {content}
        </button>
    );
};

Button.SIZE = SIZE;
Button.STYLE = STYLE;

export default Button;
