import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import { PageError404 } from '@xyz-school/xyz-react-frontend';

import { CourseContentType, CourseLectureType, CourseType } from '@/generated/graphql';
import { useFeatures } from '@/models/User/useFeature';

import PageAuth from '@/pages/PageAuth';
import Dashboard from '@/pages/Dashboard';
import PageCourse from '@/pages/PageCourse';
import PageLecture from '@/pages/PageLecture';
import PageSubscription from '@/pages/PageSubscription';
import PageXyzPlusContent from '@/pages/PageXyzPlusContent';
import PageStream from '@/pages/PageStream';
import PageProfile from '@/pages/PageProfile';
import PageAgreement from '@/pages/PageAgreement';
import PageCheckersDashboard from '@/pages/PageCheckersDashboard';

// TODO: Надо переработать механизм построения url для роута. Функция генерации ссылки должна быть методом компоненты, которой она принадлежит. Например: PageCourse.getUrl()
export const routes = {
    HOME: '/',
    AUTH: '/auth',
    AGREEMENT: '/agreement',
    COURSE: (slug: CourseType['slug'], tab?: string): string => `/new/courses/${slug}/${tab}`,
    LECTURE: (
        courseSlug: CourseType['slug'],
        lectureId: CourseLectureType['id'],
        contentId: CourseContentType['id'],
    ): string => `/new/courses/${courseSlug}/${lectureId}/${contentId}`,
    SUBSCRIPTION: '/subscription/',
    XYZ_PLUS_CONTENT: (contentId: string): string => `/xyz-plus/${contentId}`,
    STREAM: (id: string): string => `/streams/${id}`,
    PROFILE: '/profile/',
    CHECKERS_DASHBOARD: '/checkers-dashboard/',
};

const Routes = () => {
    const { path } = useRouteMatch();
    const features = useFeatures();

    return (
        <Switch>
            <Route path={`${path}/auth`} component={PageAuth} />
            <Route path={`${path}/agreement`} component={PageAgreement} />
            <Route
                path={`${path}/dashboard/:type?`}
                render={({ match }) => {
                    const activeTab = match.params.type;

                    if (!activeTab || !Object.values<string>(Dashboard.tab).includes(activeTab)) {
                        return <Redirect to={Dashboard.getUrl(Dashboard.tab.My)} />;
                    }

                    return <Dashboard activeTab={activeTab} />;
                }}
            />
            <Route
                path={`${path}/new/courses/:courseSlug/:lectureId/:contentId`}
                render={({ match }) => (
                    <PageLecture
                        courseSlug={match.params.courseSlug}
                        lectureId={match.params.lectureId}
                        contentId={match.params.contentId}
                    />
                )}
            />
            <Route path={`${path}/new/courses/:slug/:tab?`} component={PageCourse} />
            <Route
                path={`${path}/subscription/`}
                render={() => {
                    if (features.xyzPlusCanBuy) {
                        return <PageSubscription />;
                    }
                    return <Redirect to={Dashboard.getUrl(Dashboard.tab.XyzPlus)} />;
                }}
            />
            <Route path={`${path}/xyz-plus/:id`} component={PageXyzPlusContent} />
            {features.streams && <Route path={`${path}/streams/:id`} component={PageStream} />}
            <Route path={`${path}/profile`} component={PageProfile} />
            {features.checkersDashboard && (
                <Route path={`${path}/checkers-dashboard`} component={PageCheckersDashboard} />
            )}
            <Route
                path="/:lang"
                render={() => {
                    return <Redirect to={Dashboard.getUrl(Dashboard.tab.My)} />;
                }}
            />
            <Route path="*" render={() => <PageError404 />} />
        </Switch>
    );
};

export default Routes;
