import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@xyz-school/xyz-react-frontend';
import { useFeatures } from '@/models/User/useFeature';

const PageXyzPlusContentControlsUi = () => {
    const { t } = useTranslation(['subscription']);
    const features = useFeatures();

    return (
        <Button
            styleType="primary"
            size="large"
            icon="play"
            isFullWidth
            linkTo={features.xyzPlusCanBuy ? '/ru/subscription/' : 'https://sale.school-xyz.com/'}
            linkTarget={features.xyzPlusCanBuy ? '_self' : '_blank'}
        >
            {features.xyzPlusCanBuy ? t('WATCH_BY_SUBSCRIPTION_BTN_TEXT') : t('GET_ACCESS')}
        </Button>
    );
};

export default PageXyzPlusContentControlsUi;
