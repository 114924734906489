import React, { ReactNode } from 'react';

import { GridContainer } from '@xyz-school/xyz-react-frontend';

import styles from './PageProfileLayout.scss';

type Props = {
    avatar: ReactNode;
    children: ReactNode;
};

const PageProfileLayout = ({ avatar, children }: Props) => {
    return (
        <GridContainer className={styles.PageProfileLayout}>
            <div className={styles.avatarWrap}>{avatar}</div>
            <div className={styles.contentWrap}>{children}</div>
        </GridContainer>
    );
};

export default PageProfileLayout;
