import React, { ReactNode } from 'react';

import { Container, Link, Text } from '@xyz-school/xyz-react-frontend';

import { CourseLectureType, CourseType } from '@/generated/graphql';
import { routes } from '@/Routes';

import LectureIcon from '../LectureIcon';

import styles from './LectureContentLink.scss';

type Props = {
    isActive?: boolean;
    courseSlug: CourseType['slug'];
    lectureId: CourseLectureType['id'];
    title: string;
    contentId: string;
    fastActionsPanel?: ReactNode;
};

const LectureContentLink = ({ title, isActive, courseSlug, lectureId, contentId, fastActionsPanel }: Props) => {
    if (isActive) {
        return (
            <div className={styles.row}>
                <Container marginRight={1} marginTop={0.5}>
                    <LectureIcon type="lesson" />
                </Container>
                <Text>{title}</Text>
                <div className={styles.fastActionPanelWrap}>{fastActionsPanel}</div>
            </div>
        );
    }
    return (
        <Link className={styles.row} to={routes.LECTURE(courseSlug, lectureId, contentId)}>
            <Container marginRight={1} marginTop={0.5}>
                <LectureIcon type="lesson" />
            </Container>
            <Text>{title}</Text>
            <div className={styles.fastActionPanelWrap}>{fastActionsPanel}</div>
        </Link>
    );
};

export default LectureContentLink;
