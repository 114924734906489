import React from 'react';
import { gql } from '@apollo/client';
import { StreamCardFragment } from '@/generated/graphql';

import { BlockLabel, Container, Heading, Text } from '@xyz-school/xyz-react-frontend';
import useFormatedDate from '@/core/useFormatedDate';
import CardLayout from '@/ui/CardLayout';
import Image from '@/ui/Image';

import styles from './StreamCard.scss';

gql`
    fragment StreamCard on LiveStreamType {
        id
        title
        thumbnail
        courses {
            id
            title
        }
        description
        startDate
    }
`;

type Props = {
    stream: StreamCardFragment;
};

const StreamCard = ({ stream }: Props) => {
    const { formatDate } = useFormatedDate();

    return (
        <CardLayout
            className={styles.streamCard}
            labels={<BlockLabel>{formatDate(stream.startDate, 'DD MMMM, HH:mm')}</BlockLabel>}
            labelsPlacement="topEnd"
            thumbnail={<Image image={stream.thumbnail} />}
        >
            <Container marginBottom={2}>
                <Heading level={3}>{stream.title}</Heading>
            </Container>

            {stream?.courses?.map((course) => (
                <Container display="inline" marginRight={1} key={course.id}>
                    <Text size="small" inline>
                        {course.title}
                    </Text>
                </Container>
            ))}

            <Text color="label" size="small" wrap>
                {stream.description}
            </Text>
        </CardLayout>
    );
};

export default StreamCard;
