import React from 'react';
import { gql } from '@apollo/client';
import { Container } from '@xyz-school/xyz-react-frontend';
import LazyLoad from 'react-lazyload';

import { CourseCardPromoFragmentDoc, DashboardCoursesListFragment } from '@/generated/graphql';

import CardsLayout from '@/ui/CardsLayout';
import CardListGridLayout from '@/ui/CardListGridLayout';
import CourseCardPromo from '@/models/Course/CourseCardPromo';

gql`
    fragment DashboardCoursesList on CourseTypeConnection {
        edges {
            node {
                id
                ...CourseCardPromo
            }
        }
    }
    ${CourseCardPromoFragmentDoc}
`;

type Props = {
    title: string;
    isRecomended?: boolean;
    listData: DashboardCoursesListFragment;
};

const DashboardCoursesList = ({ title, isRecomended, listData }: Props) => {
    if (!listData.edges.length) {
        return null;
    }
    return (
        <CardsLayout title={title} count={listData.edges.length}>
            <Container paddingTop={4} paddingTopXs={2}>
                <CardListGridLayout>
                    {listData.edges.map((course) => {
                        if (!course?.node) {
                            return null;
                        }
                        return (
                            <LazyLoad once offset={100} key={course.node.slug}>
                                <CourseCardPromo
                                    course={course.node}
                                    key={course.node.id}
                                    isRecomended={isRecomended}
                                />
                            </LazyLoad>
                        );
                    })}
                </CardListGridLayout>
            </Container>
        </CardsLayout>
    );
};

export default DashboardCoursesList;
