import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useXyzPlusContentsQuery, XyzPlusContentCardFragmentDoc } from '@/generated/graphql';

import LazyLoad from 'react-lazyload';

import { Container, Text } from '@xyz-school/xyz-react-frontend';
import InfiniteScrollLoader from '@/features/InfiniteScrollLoader';
import CardListGridLayout from '@/ui/CardListGridLayout';
import XyzPlusContentCategorySelector from '../XyzPlusContentCategorySelector';
import XyzPlusContentCard from '../XyzPlusContentCard';

export type XyzPlusFilterStateType = {
    category: undefined | string;
    relatedCourse: undefined | string;
    // eslint-disable-next-line camelcase
    title_Icontains: undefined | string;
};

gql`
    query xyzPlusContents($category: ID, $relatedCourse: ID, $title_Icontains: String, $after: String) {
        xyzPlusContents(
            category: $category
            relatedCourse: $relatedCourse
            title_Icontains: $title_Icontains
            after: $after
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    ...XyzPlusContentCard
                }
                cursor
            }
        }
    }
    ${XyzPlusContentCardFragmentDoc}
`;

const XyzPlusContentList = () => {
    const { data, loading, fetchMore, refetch } = useXyzPlusContentsQuery();
    const [filterState, setFilterState] = useState<XyzPlusFilterStateType>({
        category: undefined,
        relatedCourse: undefined,
        title_Icontains: undefined,
    });

    const handleFiltersChange = (val: XyzPlusFilterStateType) => {
        setFilterState(val);
        refetch(val);
    };

    const onLoadMore = () => {
        fetchMore({
            variables: { ...filterState, after: data?.xyzPlusContents?.pageInfo?.endCursor },
            updateQuery(previousResult, { fetchMoreResult }) {
                const prevEdges = previousResult?.xyzPlusContents?.edges;

                if (fetchMoreResult?.xyzPlusContents) {
                    const newEdges = fetchMoreResult?.xyzPlusContents?.edges;
                    const newPageInfo = fetchMoreResult?.xyzPlusContents?.pageInfo;

                    return {
                        xyzPlusContents: {
                            edges: prevEdges ? [...prevEdges, ...newEdges] : newEdges,
                            pageInfo: newPageInfo,
                        },
                    };
                }

                return previousResult;
            },
        });
    };

    return (
        <Container paddingBottom={12} paddingBottomXs={4}>
            <XyzPlusContentCategorySelector state={filterState} onChange={handleFiltersChange} />
            <CardListGridLayout>
                {data?.xyzPlusContents?.edges.map((content) => (
                    <LazyLoad once offset={100} key={content?.node?.id}>
                        <XyzPlusContentCard content={content?.node} />
                    </LazyLoad>
                ))}
            </CardListGridLayout>
            {!data?.xyzPlusContents?.edges.length && <Text align="center">Ничего не найдено</Text>}
            {data?.xyzPlusContents?.pageInfo && (
                <Container paddingTop={3}>
                    <InfiniteScrollLoader
                        loading={loading}
                        hasNextPage={data?.xyzPlusContents?.pageInfo?.hasNextPage}
                        onLoadMore={onLoadMore}
                    />
                </Container>
            )}
        </Container>
    );
};

export default XyzPlusContentList;
