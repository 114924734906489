import React from 'react';
import { gql } from '@apollo/client';
import { useXyzPlusContentsCategoriesQuery } from '@/generated/graphql';

import XyzPlusContentCategorySelectorUi from '../ui/XyzPlusContentCategorySelectorUi';
import { XyzPlusFilterStateType } from '../XyzPlusContentList/XyzPlusContentList';

gql`
    query xyzPlusContentsCategories {
        xyzPlus {
            categories {
                id
                title
            }
            courses {
                id
                title
            }
        }
    }
`;

type Props = {
    state: XyzPlusFilterStateType;
    onChange: (val: XyzPlusFilterStateType) => void;
};

const XyzPlusContentCategorySelector = ({ state, onChange }: Props) => {
    const { data } = useXyzPlusContentsCategoriesQuery();

    return (
        <>
            <XyzPlusContentCategorySelectorUi
                state={state}
                categories={data?.xyzPlus?.categories}
                courses={data?.xyzPlus?.courses}
                onCategorySelected={(val) => onChange({ ...state, category: val })}
                onCourseSelected={(val) => onChange({ ...state, relatedCourse: val })}
                onSearch={(val) => onChange({ ...state, title_Icontains: val })}
            />
        </>
    );
};

export default XyzPlusContentCategorySelector;
