import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useNotificationActions } from '@xyz-school/xyz-react-frontend';

import { useRequestAuthMutation } from '@/generated/graphql';
import MultiInputsField from '@/ui/MultiInputsField/MultiInputsField';

gql`
    mutation RequestAuth($input: RequestAuthDTO!) {
        requestAuth(input: $input) {
            success
        }
    }
`;

type Props = {
    verificationId: string;
    onSuccess: () => void;
};

const AuthCodeVerification = ({ verificationId, onSuccess }: Props) => {
    const { pushNotification } = useNotificationActions();
    const [code, setCode] = useState('');
    const [isCodeError, setIsCodeError] = useState(false);

    const [requestAuth] = useRequestAuthMutation({
        onCompleted: (res) => {
            if (res.requestAuth?.success) {
                onSuccess();
            }
        },
        onError: (error) => {
            setIsCodeError(true);

            pushNotification({
                type: 'error',
                message: error.message,
                name: 'CodeVerificationError',
            });
        },
    });

    const handleCodeInput = (value: string) => {
        setCode(value);
        setIsCodeError(false);

        if (value.length === 4) {
            requestAuth({
                variables: {
                    input: {
                        code: value,
                        verificationId,
                    },
                },
            });
        }
    };

    return <MultiInputsField hasError={isCodeError} name="code" value={code} onChange={handleCodeInput} />;
};

export default AuthCodeVerification;
