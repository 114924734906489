import { useEffect } from 'react';

const usePolling = (interval: number, execute: () => void): void => {
    useEffect(() => {
        const timerId = setInterval(() => {
            if (!document.hidden) {
                execute();
            }
        }, interval);

        return () => clearInterval(timerId);
    }, [execute, interval]);
};

export default usePolling;
