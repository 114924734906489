import React, { useEffect, ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import {
    AmoWidgetFragmentDoc,
    CurrentUserProviderFragmentDoc,
    PageLayoutHeaderCurrentUserFragmentDoc,
    usePageLayoutQuery,
} from '@/generated/graphql';
import { PageLayout as PageLayoutUi, NotificationList, PageError500 } from '@xyz-school/xyz-react-frontend';

import CurrentUserProvider from '@/models/User/CurrentUserProvider';
import AmoWidget from '@/features/AmoWidget';
import { routes } from '@/Routes';
import HeaderBase from './components/HeaderBase';
import FooterUnauthorized from './components/FooterUnauthorized';
import PageLayoutFooter from './PageLayoutFooter';
import PageLayoutHeader from './PageLayoutHeader';

import styles from './PageLayout.scss';

type Props = {
    children: ReactNode;
};

gql`
    query PageLayout {
        ...CurrentUserProvider
        currentUser {
            ...AmoWidget
            ...PageLayoutHeaderCurrentUser
            isAgreementAccepted
        }
    }
    ${AmoWidgetFragmentDoc}
    ${PageLayoutHeaderCurrentUserFragmentDoc}
    ${CurrentUserProviderFragmentDoc}
`;

const PageLayout = ({ children }: Props) => {
    const { data, loading, error } = usePageLayoutQuery();
    const { pathname } = useLocation();
    const canViewContent = (!loading && data?.currentUser) || pathname.includes(routes.AUTH);

    // @todo: remove
    useEffect(() => {
        if (!error && !loading && !data?.currentUser && !window.location.href.includes(routes.AUTH)) {
            window.location.href = `/auth/?next=${window.location.pathname}`;
        }
    }, [data?.currentUser, error, loading, pathname]);

    if (error) {
        return <PageError500 />;
    }

    if (loading) {
        return <>Loading...</>;
    }

    const isAuthView = pathname.includes(routes.AUTH);

    // if (!loading && !data?.currentUser && !isAuthView) {
    //     return <Redirect to={routes.AUTH} />;
    // }

    if (data?.currentUser && !data?.currentUser?.isAgreementAccepted) {
        return <Redirect to={routes.AGREEMENT} />;
    }

    return (
        <CurrentUserProvider data={data}>
            <PageLayoutUi
                header={
                    <>
                        {isAuthView ? (
                            <>
                                <HeaderBase />
                                <div className={styles.NotificationListWrapper}>
                                    <NotificationList />
                                </div>
                            </>
                        ) : (
                            <>
                                <PageLayoutHeader currentUser={data?.currentUser} />
                                <div className={styles.NotificationListWrapper}>
                                    <NotificationList />
                                </div>
                            </>
                        )}
                    </>
                }
                footer={isAuthView ? <FooterUnauthorized /> : <PageLayoutFooter />}
            >
                <>
                    {canViewContent && <>{children}</>}
                    <AmoWidget currentUser={data?.currentUser} />
                </>
            </PageLayoutUi>
        </CurrentUserProvider>
    );
};

export default PageLayout;
