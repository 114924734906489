import React, { ReactNode } from 'react';
import { Heading, Container } from '@xyz-school/xyz-react-frontend';
import styles from './CardsLayout.scss';

type Props = {
    title: ReactNode;
    children: ReactNode;
    count?: number;
    filter?: ReactNode;
};

const CardsLayout = ({ title, children, filter = null, count }: Props) => {
    return (
        <Container marginBottom={10} marginBottomXs={6}>
            <Container className={styles.CardsLayout__head} paddingBottom={4} paddingBottomXs={2}>
                <Heading level={2} className={styles.CardsLayout__title}>
                    {title}
                    {count && (
                        <Container marginLeft={2} tag="span">
                            {count}
                        </Container>
                    )}
                </Heading>
            </Container>
            {filter}
            {children}
        </Container>
    );
};
export default CardsLayout;
