import React from 'react';
import classnames from 'classnames';

import SVG from 'react-inlinesvg';
import IconsSVG from './assets/icons.svg';

import styles from './Icon.scss';

type IconSizeType = 'small' | 'medium' | 'large' | 'extra-large' | 'largest' | 'inherit';
type IconColorType =
    | 'attention'
    | 'text'
    | 'text-inverted'
    | 'label'
    | 'icon'
    | 'border'
    | 'input'
    | 'background'
    | 'accent'
    | 'success'
    | 'warning'
    | 'fail'
    | 'inherit';

type Props = {
    name: string;
    size?: IconSizeType;
    color?: IconColorType;
    className?: string;
    xlinkHref?: string;
    onClick?: () => void;
};

const IconLoader = () => {
    return <SVG src={IconsSVG} style={{ display: 'none' }} />;
};

const Icon = ({ name, size = 'medium', color = 'icon', className, xlinkHref, onClick }: Props) => {
    return (
        <svg
            className={classnames(
                styles.icon,
                styles[`color-${String(color)}`],
                {
                    [styles[`size-${String(size)}`]]: size !== 'inherit',
                },
                className,
            )}
            focusable={false}
            onClick={onClick}
        >
            <use xlinkHref={xlinkHref || `#icon-${name}`} />
        </svg>
    );
};

Icon.Loader = IconLoader;

export default Icon;
