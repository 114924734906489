import React, { ReactNode } from 'react';
import { useMedia } from '@xyz-school/xyz-react-frontend';
import styles from '../SidebarLink/SidebarLink.scss';

import SidebarLink from '../SidebarLink/SidebarLink';

type Props = {
    children: ReactNode;
};

const SidebarNavLinks = ({ children }: Props) => {
    const { lg } = useMedia();

    const newChildren = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }
        return (
            <div className={styles.sidebarLinkWrapper}>
                <child.type {...child.props} label={lg ? null : child.props.label} />
            </div>
        );
    });

    return <div className={styles.sidebarLinkContainer}>{newChildren}</div>;
};

SidebarNavLinks.Item = SidebarLink;
export default SidebarNavLinks;
