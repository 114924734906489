import { gql } from '@apollo/client';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNotificationActions } from '@xyz-school/xyz-react-frontend';

import { usePhoneVerificationMutation } from '@/generated/graphql';

gql`
    mutation PhoneVerification($input: PhoneVerificationDTO!) {
        phoneVerification(input: $input) {
            verificationId
            expiredAt
        }
    }
`;

const usePhoneVerification = ({
    onSuccess,
}: {
    onSuccess: ({
        verificationId,
        verificationEndDate,
    }: {
        verificationId: string;
        verificationEndDate: string | null;
    }) => void;
}) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { pushNotification } = useNotificationActions();
    const [verifyPhone] = usePhoneVerificationMutation({
        onCompleted: (data) => {
            if (data.phoneVerification?.verificationId) {
                onSuccess({
                    verificationId: data.phoneVerification?.verificationId,
                    verificationEndDate: data.phoneVerification?.expiredAt || null,
                });
            }
        },
        onError: (error) => {
            pushNotification({
                type: 'error',
                message: error.message,
                name: 'PhoneVerificationError',
            });
        },
    });

    const handlePhoneSubmit = async ({
        phone,
        isProcessingAgreed,
        isAdvertisingAgreed,
    }: {
        phone: string;
        isProcessingAgreed: boolean;
        isAdvertisingAgreed: boolean;
    }) => {
        if (executeRecaptcha) {
            const token = await executeRecaptcha();

            verifyPhone({
                variables: {
                    input: {
                        phone,
                        recaptchaToken: token,
                        isProcessingAgreed,
                        isAdvertisingAgreed,
                    },
                },
            });
        }
    };

    return { handlePhoneSubmit };
};

export default usePhoneVerification;
