import React, { ReactNode, useState } from 'react';
import classnames from 'classnames';
import Icon from '@/ui/Icon';

import { Text } from '@xyz-school/xyz-react-frontend';

import styles from './Checkbox.scss';

type Props = {
    children?: ReactNode;
    value?: string | string[] | number;
    name?: string;
    className?: string;
    isChecked?: boolean;
    isReadonly?: boolean;
    isDisabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage?: string | boolean;
};

const Checkbox = ({
    children,
    value = 'on',
    name,
    className,
    isChecked,
    isReadonly,
    isDisabled,
    onChange,
    errorMessage,
}: Props) => {
    const [checked, setChecked] = useState(!!isChecked);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isDisabled && !isReadonly) {
            setChecked((prevState) => !prevState);
            if (onChange) {
                onChange(e);
            }
        }
    };

    return (
        <div className={classnames(styles.wrapper, className)}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label
                className={classnames(styles.checkboxWrap, {
                    [styles.disabled]: isDisabled,
                    [styles.readonly]: isReadonly,
                })}
            >
                <div className={styles.checkboxControl}>
                    <input
                        name={name}
                        type="checkbox"
                        value={value}
                        checked={checked}
                        readOnly={isReadonly}
                        disabled={isDisabled}
                        onChange={handleChange}
                        className={styles.checkboxInput}
                    />
                    <div className={classnames(styles.checkboxBox, { [styles.checkboxBox_error]: !!errorMessage })}>
                        <Icon name="checkbox-check" size="medium" color="background" className={styles.checkboxIcon} />
                    </div>
                </div>
                {children && <Text size="small">{children}</Text>}
            </label>
            {!!errorMessage && (
                <Text tag="div" color="fail" className={styles.errorMessage}>
                    {errorMessage}
                </Text>
            )}
        </div>
    );
};

export default Checkbox;
