import React from 'react';
import { useTranslation } from 'react-i18next';

import FaqList from '@/features/Faq/FaqList';
import { Container, Heading } from '@xyz-school/xyz-react-frontend';

import { XYZ_PLUS_FAQ_DATA } from './faqData';

const PageSubscriptionSettingsFaq = () => {
    const { t } = useTranslation(['subscription']);

    return (
        <Container marginBottom={20}>
            <Container marginBottom={5}>
                <Heading level={2}>{t('SETTINGS_FAQ_TITLE')}</Heading>
            </Container>
            <FaqList course={XYZ_PLUS_FAQ_DATA} />
        </Container>
    );
};

export default PageSubscriptionSettingsFaq;
