import React from 'react';
import { gql } from '@apollo/client';

import { BannerFragmentDoc, DashboardXyzPlusFragmentDoc, useDashboardQuery } from '@/generated/graphql';

import { useFeatures } from '@/models/User/useFeature';
import GridLayout from '@/ui/GridLayout';
import { Container, useMedia } from '@xyz-school/xyz-react-frontend';
import DashboardXyzPlus from '@/pages/Dashboard/DashboardXyzPlus';
import DashboardMy from '@/pages/Dashboard/DashboardMy/DashboardMy';
import DashboardAll from '@/pages/Dashboard/DashboardAll/DashboardAll';
import DashboardStreams from '@/pages/Dashboard/DashboardStreams';
import DashboardNavigation from '@/pages/Dashboard/DashboardNavigation';
import MobileBottomBar from '@/ui/MobileBottomBar/MobileBottomBar';
import Banner from '@/models/Banner';

gql`
    query Dashboard {
        banner: banner(position: DASHBOARD) {
            ...Banner
        }
        ...DashboardXyzPlus
    }
    ${BannerFragmentDoc}
    ${DashboardXyzPlusFragmentDoc}
`;

const DashboardTab = {
    My: 'my',
    All: 'all',
    Streams: 'streams',
    XyzPlus: 'xyz-plus',
} as const;

export type DashboardTabType = typeof DashboardTab[keyof typeof DashboardTab];

type Props = {
    activeTab: DashboardTabType;
};

const Dashboard = ({ activeTab }: Props) => {
    const { data } = useDashboardQuery({ fetchPolicy: 'cache-and-network' });
    const { lg, xl } = useMedia();
    const features = useFeatures();

    return (
        <Container marginTop={6}>
            <GridLayout leftSideContent={(lg || xl) && <DashboardNavigation />}>
                {activeTab === DashboardTab.My && (
                    <DashboardMy banner={data?.banner && <Banner banner={data.banner} />} />
                )}

                {activeTab === DashboardTab.All && (
                    <DashboardAll banner={data?.banner && <Banner banner={data.banner} />} />
                )}

                {features.streams && activeTab === DashboardTab.Streams && <DashboardStreams />}

                {activeTab === DashboardTab.XyzPlus && <DashboardXyzPlus {...data} />}
            </GridLayout>
            {!(lg || xl) && (
                <MobileBottomBar>
                    <DashboardNavigation />
                </MobileBottomBar>
            )}
        </Container>
    );
};

Dashboard.tab = DashboardTab;
Dashboard.getUrl = (type: DashboardTabType = DashboardTab.My): string => `/dashboard/${type}`;

export default Dashboard;
