import React from 'react';
import { gql } from '@apollo/client';

import Icon from '@/ui/Icon';
import { useStaffEditBtnQuery } from '@/generated/graphql';

gql`
    query StaffEditBtn {
        currentUser {
            isStaff
        }
    }
`;

type Props = {
    link: string;
};

const StaffEditBtn = ({ link }: Props) => {
    const { data, loading } = useStaffEditBtnQuery();

    if (!data?.currentUser?.isStaff && !loading) {
        return null;
    }

    return (
        <a href={link}>
            <Icon name="edit" color="accent" />
        </a>
    );
};

export default StaffEditBtn;
