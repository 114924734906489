import React from 'react';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';

import { AmoWidgetFragment } from '@/generated/graphql';

gql`
    fragment AmoWidget on UserType {
        id
        username
    }
`;

type Props = {
    currentUser?: AmoWidgetFragment;
};

const AmoWidget = ({ currentUser }: Props) => {
    const widget = `
        (function(a,m,o,c,r,m){a[m]={id:"378687",hash:"006bab204ebbbf21d4260fbdee31a04cb5f0393276a609adf0c82664f42bc123",locale:"ru",inline:true,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.ru/js/button.js?1674223943';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));

        amo_social_button.setMeta({
            contact: {
                name: '${currentUser?.id}'
            }
        });
    `;
    return (
        <Helmet>
            <script>{widget}</script>
        </Helmet>
    );
};

export default AmoWidget;
