import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import LoadingSpinner from '@/ui/LoadingSpinner';

import styles from './InfiniteScrollLoader.scss';

type Props = {
    loading: boolean;
    hasNextPage: boolean;
    onLoadMore: () => void;
};

const InfiniteScrollLoader = ({ loading, hasNextPage, onLoadMore }: Props) => {
    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => onLoadMore(),
    });

    if (!hasNextPage) {
        return null;
    }

    return (
        <div className={styles.loaderContainer} ref={sentryRef}>
            <LoadingSpinner />
        </div>
    );
};

export default InfiniteScrollLoader;
