import React, { useCallback } from 'react';

import { HeaderNotifications, HeaderNotificationUiType } from '@xyz-school/xyz-react-frontend';

import { notificationApi } from '@/models/Notification';

import Icon from '@/ui/Icon';
import usePullingNotification from './usePullingNotification';

const HeaderNotification = () => {
    const [unreadCount, setUnreadCount] = usePullingNotification();

    const getNotifications = useCallback(async (): Promise<Array<HeaderNotificationUiType>> => {
        const data = await notificationApi.getNotificationList({ limit: 20 });
        const notifications = data.allList;
        const countReadNotification = notifications.filter((item) => !item.isRead).length;

        notifications.forEach((item) => {
            if (!item.isRead) {
                notificationApi.markAsReadNotification({ id: item.id });
            }
        });

        setUnreadCount((prevUnreadCount) => prevUnreadCount - countReadNotification);

        return notifications.map((notification) => {
            return {
                ...notification,
                icon: <Icon name="comment" />,
            };
        });
    }, [setUnreadCount, notificationApi]);

    return (
        <HeaderNotifications count={unreadCount} getNotifications={getNotifications} reset={() => {}} isNext={false} />
    );
};

export default HeaderNotification;
