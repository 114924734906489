import React from 'react';
import { gql } from '@apollo/client';

import XyzPlusContentPreviewUi from '@/models/XyzPlus/ui/XyzPlusContentPreviewUi';
import { PageXyzPlusContentPreviewFragment } from '@/generated/graphql';

gql`
    fragment PageXyzPlusContentPreview on XyzPlusContentType {
        description
        thumbnail
        category {
            title
        }
        relatedCourse {
            title
        }
    }
`;

type Props = {
    data?: PageXyzPlusContentPreviewFragment;
};

const PageXyzPlusContentPreview = ({ data }: Props) => {
    if (!data) {
        return null;
    }

    return (
        <XyzPlusContentPreviewUi
            thumbnail={data?.thumbnail}
            courseTitle={data?.relatedCourse?.title}
            categoryTitle={data?.category?.title}
            description={data?.description}
        />
    );
};

export default PageXyzPlusContentPreview;
