import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, Text } from '@xyz-school/xyz-react-frontend';
import Modal from '@/ui/Modal';
import Button from '@/ui/Button';
import AuthCodeVerification from '@/features/Auth/AuthCodeVerification';
import AuthCountdown from '@/features/Auth/AuthCountdown';

import styles from './UserPhoneVerificationModal.scss';

type Props = {
    phone: string;
    verificationId: string;
    verificationEndDate?: string;
    onSuccess: () => void;
    onClose: () => void;
};

const UserPhoneVerificationModal = ({ phone, verificationId, verificationEndDate, onSuccess, onClose }: Props) => {
    const { t } = useTranslation(['user']);
    const [isResendActive, setIsResendActive] = useState(false);

    const handleCoundownFinish = () => {
        setIsResendActive(true);
    };

    const handleResendCode = () => {};

    return (
        <Modal onClose={onClose}>
            <div className={styles.modalContainer}>
                <Heading level={1}>{t('PHONE_VERIFICATION_MODAL_TITLE')}</Heading>
                <Text className={styles.modalDescription}>
                    {t('PHONE_VERIFICATION_MODAL_DESCRIPTION')} <b>{phone}</b>
                </Text>

                <AuthCodeVerification verificationId={verificationId} onSuccess={onSuccess} />

                <div className={styles.resendWrap}>
                    <Text>{t('PHONE_VERIFICATION_MISS_CODE')}</Text>
                    &nbsp;
                    <Button styleType="text" size="large" isDisabled={!isResendActive} onClick={handleResendCode}>
                        {t('PHONE_VERIFICATION_RESEND_CODE_BTN_TEXT')}
                    </Button>
                    {verificationEndDate && (
                        <div className={styles.countDownWrap}>
                            <AuthCountdown
                                timestamp={parseFloat(verificationEndDate)}
                                onFinish={handleCoundownFinish}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default UserPhoneVerificationModal;
