import React from 'react';
import classNames from 'classnames';

import { normalizeProgress } from './utils';
import styles from './ProgressBar.scss';

export const COLOR = {
    accent: 'accent',
    fail: 'fail',
    success: 'success',
    warning: 'warning',
} as const;

type Props = {
    progress: number; // значение от 0 до 1
    color?: typeof COLOR[keyof typeof COLOR];
    className?: string;
};

const ProgressBar = ({ progress, color = COLOR.accent, className }: Props) => {
    return (
        <div className={classNames(styles.progressBar, className)}>
            <div
                className={classNames(styles.progressLine, { [styles[`progressLine_${color}`]]: color })}
                style={{ width: `${normalizeProgress(progress)}%` }}
            />
        </div>
    );
};

export default ProgressBar;
