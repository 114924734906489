import React from 'react';
import { Container, Skeleton, GridRow, GridCol } from '@xyz-school/xyz-react-frontend';

import CardsLayout from '@/ui/CardsLayout';
import CardShortSkeleton from '@/ui/CardShortSkeleton';

import styles from './DashboardAllSkeleton.scss';

const items = Array(9).fill(null);

const DashboardAllSkeleton = () => (
    <CardsLayout title={<Skeleton className={styles.title} />}>
        <GridRow>
            {items.map((_, i) => (
                <GridCol xl={3} lg={4} sm={2} xs={4} key={i}>
                    <Container
                        paddingTopXs={0.5}
                        paddingTop={2}
                        paddingBottomXs={2}
                        paddingBottom={4}
                        className={styles.card}
                    >
                        <CardShortSkeleton />
                    </Container>
                </GridCol>
            ))}
        </GridRow>
    </CardsLayout>
);

export default DashboardAllSkeleton;
