import React, { lazy, useState } from 'react';
import LazyLoad from 'react-lazyload';

import ContentVideoError from '../ContentVideoError';

const ReactPlayer = lazy(() => import('react-player/lazy'));

type Props = {
    url: string;
    className?: string;
};

const ContentVimeo = ({ url, className }: Props) => {
    const [isVideoLoadingError, setIsVideoLoadingError] = useState<boolean>(false);

    if (isVideoLoadingError) {
        return <ContentVideoError />;
    }

    return (
        <LazyLoad offset={100} once>
            <ReactPlayer
                className={className}
                url={url}
                width="100%"
                height="100%"
                config={{
                    vimeo: {
                        playerOptions: {
                            autopause: true,
                            autoplay: false,
                            showByline: true,
                            controls: true,
                            loop: false,
                            showPortrait: true,
                            showTitle: true,
                            muted: false,
                            background: false,
                            responsive: true,
                        },
                    },
                }}
                onError={(error: unknown) => {
                    if (error) {
                        setIsVideoLoadingError(true);
                    }
                }}
            />
        </LazyLoad>
    );
};

export default ContentVimeo;
