import React, { ComponentProps } from 'react';

import { Container, Text, Media } from '@xyz-school/xyz-react-frontend';

import Image from '@/ui/Image';
import LectureStatus from '@/ui/LectureStatus';
import Icon from '@/ui/Icon';

import styles from './LectureItemUi.scss';

type Props = {
    title: string;
    description?: string;
    image?: string;
} & Pick<ComponentProps<typeof LectureStatus>, 'status'>;

const LectureItemUi = ({ title, description, image, status }: Props) => {
    const statusNode = <LectureStatus status={status} className={styles.lectureStatus} />;
    const imageNode = (
        <Container marginRight={3} className={styles.imageWrap}>
            <Image image={image} coverAlt={title} className={styles.image} />
        </Container>
    );
    const titleNode = <Text>{title}</Text>;
    const descriptionNode = description && (
        <Text size="small" color="label">
            {description}
        </Text>
    );

    return (
        <>
            <Media greaterThanOrEqual="sm">
                <div className={styles.row}>
                    {statusNode}
                    {imageNode}
                    <div className={styles.text}>
                        <Container marginBottom={1}>{titleNode}</Container>
                        {descriptionNode}
                    </div>
                    <Text size="small">0</Text>
                    <Text size="small" color="label">
                        /4 лекций
                    </Text>
                    <Icon name="arrow-right" size="large" color="text" className={styles.icon} />
                </div>
            </Media>
            <Media lessThan="sm">
                <div>
                    <div className={styles.mobileHeader}>
                        {statusNode}
                        {titleNode}
                    </div>
                    <div className={styles.row}>
                        {imageNode}
                        {descriptionNode}
                    </div>
                </div>
            </Media>
        </>
    );
};

export default LectureItemUi;
