import React, { useMemo } from 'react';
import classNames from 'classnames';

import styles from './Progress.scss';

const normalizeProgress = (progress: number): number => {
    // с сервера прогресс приходит в формате float от 0 до 1
    const p = progress * 100;
    if (p < 0) {
        return 0;
    }
    if (p > 100) {
        return 100;
    }
    return p;
};

export const SIZE = {
    normal: 'normal',
    large: 'large',
} as const;

type Props = {
    progress: number; // значение от 0 до 1
    size?: typeof SIZE[keyof typeof SIZE];
    className?: string;
};

const Progress = ({ progress, size = SIZE.normal, className }: Props) => {
    const normalizedProgress = normalizeProgress(progress);
    const diameter = 60;
    const radius = diameter / 2;
    const circumference = Math.PI * diameter;
    const offset = ((100 - normalizedProgress) / 100) * circumference;

    const maskId = useMemo(() => Date.now().toString(), []);

    return (
        <svg
            className={classNames(
                styles.Progress,
                {
                    [styles[`Progress_size_${size}`]]: size,
                },
                className,
            )}
            viewBox={`0 0 ${diameter} ${diameter}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask id={maskId} maskUnits="userSpaceOnUse" x="0" y="0" width={diameter} height={diameter}>
                <path
                    className={styles.Progress_maskBg}
                    d="M30 0L51.2132 8.7868L60 30L51.2132 51.2132L30 60L8.7868 51.2132L0 30L8.7868 8.7868L30 0Z"
                />
            </mask>
            <g mask={`url(#${maskId})`}>
                <circle r={radius} cx={radius} cy={radius} className={styles.Progress_bg} />
                {normalizedProgress > 0 && (
                    <circle
                        r={radius}
                        cx={radius}
                        cy={radius}
                        strokeWidth={diameter}
                        className={styles.Progress_bg_active}
                        strokeDasharray={circumference}
                        strokeDashoffset={offset}
                    />
                )}
            </g>
        </svg>
    );
};

export default Progress;
