import React from 'react';
import { gql } from '@apollo/client';
import { DashboardXyzPlusFragment } from '@/generated/graphql';

import { Button, Container } from '@xyz-school/xyz-react-frontend';
import XyzPlusContentList from '@/models/XyzPlus/XyzPlusContentList';
import XyzPlusDashboardHead from '@/models/XyzPlus/XyzPlusDashboardHead';

import Informer from '@/ui/Informer';

gql`
    fragment DashboardXyzPlus on Query {
        xyzPlus {
            isXyzPlusActive
        }
    }
`;

const DashboardXyzPlus = ({ xyzPlus }: DashboardXyzPlusFragment) => {
    return (
        <>
            <Container>
                <XyzPlusDashboardHead />

                {!xyzPlus?.isXyzPlusActive && (
                    <Container paddingBottom={2}>
                        <Informer
                            isCompact
                            type="FAIL"
                            title="У тебя еще нет подписки"
                            description="Получи доступ к растущей базе знаний XYZ"
                            buttons={
                                <Button
                                    linkTo="https://www.school-xyz.com/biblioteka-kursov-xyz-plus"
                                    linkTarget="_blank"
                                >
                                    Оформить подписку
                                </Button>
                            }
                        />
                    </Container>
                )}
            </Container>

            <XyzPlusContentList />
        </>
    );
};

export default DashboardXyzPlus;
