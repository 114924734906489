import { useEffect, useState, useRef } from 'react';
import { Scalars } from '@/generated/graphql';
import { onLoadScript } from './helpers';

const SCRIPT_ID = 'cloudpayments';
const SCRIPT_PATH = 'https://widget.cloudpayments.ru/bundles/cloudpayments';

type openCloudPaymentsWidgetDTO = {
    options?: Scalars['CloudPaymentsWidgetOptions'];
    onSuccess?: () => void;
};

type useCloudPaymentsWidgetReturnType = [boolean, (data: openCloudPaymentsWidgetDTO) => void];

const useCloudPaymentsWidget = (): useCloudPaymentsWidgetReturnType => {
    const isCpScriptLoaded = useRef<boolean>(false);
    const [isWidgetOpened, setIsWidgetOpened] = useState<boolean>(false);

    useEffect(() => {
        onLoadScript(SCRIPT_ID, SCRIPT_PATH, () => {
            isCpScriptLoaded.current = true;
        });
    }, []);

    const openCloudPaymentsWidget = (data: openCloudPaymentsWidgetDTO): void => {
        if (isCpScriptLoaded.current) {
            setIsWidgetOpened(true);
            const cpWidget = new window.cp.CloudPayments();
            cpWidget.charge(
                data.options,
                // onSuccess
                () => {
                    if (data.onSuccess) {
                        data.onSuccess();
                    }
                },
                // onFail
                () => {},
                // onComplete
                () => {
                    setIsWidgetOpened(false);
                },
            );
        }
    };

    return [isWidgetOpened, openCloudPaymentsWidget];
};

export default useCloudPaymentsWidget;
