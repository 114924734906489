import { useEffect, useState, useRef } from 'react';

type ScrollDirectionType = 'UP' | 'DOWN' | null;

const useScrollDirection = (): {
    scrollDirection: ScrollDirectionType;
    isDirectionUp: boolean;
    isDirectionDown: boolean;
} => {
    const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>(null);
    const scrollTopRef = useRef<number>(0);
    const handleScrollDirection = (): void => {
        const { scrollY } = window;
        if (scrollY === 0) {
            setScrollDirection(null);
        }
        if (scrollY > scrollTopRef.current) {
            setScrollDirection('DOWN');
        } else if (scrollY < scrollTopRef.current) {
            setScrollDirection('UP');
        }
        scrollTopRef.current = scrollY;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScrollDirection);
        return () => {
            window.removeEventListener('scroll', handleScrollDirection);
        };
    });

    return { scrollDirection, isDirectionUp: scrollDirection === 'UP', isDirectionDown: scrollDirection === 'DOWN' };
};

export default useScrollDirection;
