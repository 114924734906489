import React from 'react';

import { Link } from '@xyz-school/xyz-react-frontend';
import { gql } from '@apollo/client';

import { PageCourseLectureItemFragment } from '@/generated/graphql';
import LectureItemUi from '@/pages/PageCourse/ui/LectureItemUi/LectureItemUi';

import { routes } from '@/Routes';

gql`
    fragment PageCourseLectureItem on CourseLectureType {
        id
        title
        description
        thumbnail
        contents {
            id
        }
    }
`;

type Props = {
    courseSlug: string;
    lecture: PageCourseLectureItemFragment;
};

const PageCourseLectureItem = ({ courseSlug, lecture }: Props) => {
    // FIXME remove lecture.contents[0].id, because it created extra load on server
    return (
        <Link to={routes.LECTURE(courseSlug, lecture.id, lecture.contents[0].id)}>
            <LectureItemUi
                title={lecture.title}
                description={lecture.description}
                status="finished"
                image={lecture.thumbnail}
            />
        </Link>
    );
};

export default PageCourseLectureItem;
