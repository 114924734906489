import React from 'react';

import { Heading, Text } from '@xyz-school/xyz-react-frontend';

import styles from './AuthPageLayout.scss';

type Props = {
    image: string;
    title: string;
    description: React.ReactNode;
    children: React.ReactNode;
};

const AuthPageLayout = ({ image, title, description, children }: Props) => {
    return (
        <div className={styles.authPageLayout}>
            <img src={image} alt="thumb" className={styles.authPageImg} />
            <Heading level={1} className={styles.authHeading}>
                {title}
            </Heading>
            <Text className={styles.authPageDescription} wrap={false}>
                {description}
            </Text>
            <div className={styles.authPageContent}>{children}</div>
        </div>
    );
};

export default AuthPageLayout;
