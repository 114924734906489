import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

import KinescopePlayer from '@kinescope/react-kinescope-player';
import ContentVideoError from '../ContentVideoError';

import styles from './ContentKinescope.scss';

type Props = {
    id: string;
};

const ContentKinescope = ({ id }: Props) => {
    const [isVideoLoadingError, setIsVideoLoadingError] = useState<boolean>(false);

    if (isVideoLoadingError) {
        return <ContentVideoError />;
    }

    return (
        <LazyLoad offset={100} once>
            <div className={styles.contentKinescope}>
                <KinescopePlayer
                    videoId={id}
                    width="100%"
                    height="100%"
                    className={styles.kinescopePlayer}
                    onJSLoadError={() => {
                        setIsVideoLoadingError(true);
                    }}
                />
            </div>
        </LazyLoad>
    );
};

export default ContentKinescope;
