import React from 'react';

import { Skeleton } from '@xyz-school/xyz-react-frontend';
import CardListGridLayout from '@/ui/CardListGridLayout';
import CardShortSkeleton from '@/ui/CardShortSkeleton';

import styles from './StreamListSkeleton.scss';

const items = Array(9).fill(null);

const StreamListSkeleton = () => (
    <>
        <Skeleton className={styles.title} />
        <CardListGridLayout>
            {items.map((_, i) => (
                <CardShortSkeleton key={i} />
            ))}
        </CardListGridLayout>
    </>
);

export default StreamListSkeleton;
