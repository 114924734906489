import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Link, Text } from '@xyz-school/xyz-react-frontend';

import Dashboard from '@/pages/Dashboard';
import BreadcrumbsWrapper from '@/features/BreadcrumbsWrapper';
import { PageXyzPlusContentBreadCrumbsFragment } from '@/generated/graphql';

gql`
    fragment PageXyzPlusContentBreadCrumbs on XyzPlusContentType {
        title
    }
`;

type Props = {
    data?: PageXyzPlusContentBreadCrumbsFragment;
};

const PageXyzPlusContentBreadCrumbs = ({ data }: Props) => {
    const { t } = useTranslation(['subscription']);

    if (!data) {
        return null;
    }

    return (
        <BreadcrumbsWrapper>
            <Link to={Dashboard.getUrl(Dashboard.tab.XyzPlus)}>{t('XYZ_PLUS_PAGE_TITLE')}</Link>
            <Text>{data?.title}</Text>
        </BreadcrumbsWrapper>
    );
};

export default PageXyzPlusContentBreadCrumbs;
