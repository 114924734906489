import React from 'react';
import classnames from 'classnames';

import styles from './LoadingSpinner.scss';

type Props = {
    className?: string;
};

const LoadingSpinner = ({ className }: Props) => {
    return <div className={classnames(styles.loadingSpinner, className)} />;
};

export default LoadingSpinner;
