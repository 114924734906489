import React from 'react';
import { gql } from '@apollo/client';
import { BannerFragment } from '@/generated/graphql';
import { Link, useMedia } from '@xyz-school/xyz-react-frontend';

import styles from './Banner.scss';

gql`
    fragment Banner on BannerType {
        url
        desktopImage
        mobileImage
    }
`;

type Props = {
    banner: BannerFragment;
};

const Banner = ({ banner }: Props) => {
    const { xs } = useMedia();

    return (
        <Link to={banner.url} className={styles.bannerLink}>
            <img src={xs ? banner.mobileImage : banner.desktopImage} alt="" className={styles.bannerImage} />
        </Link>
    );
};

export default Banner;
