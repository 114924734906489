import React from 'react';
import { gql } from '@apollo/client';

import { GridContainer, Container, Heading, GridRow, GridCol } from '@xyz-school/xyz-react-frontend';

import {
    CourseContentType,
    CourseLectureType,
    CourseType,
    PageLectureBreadCrumbsCourseFragmentDoc,
    PageLectureBreadCrumbsLectureFragmentDoc,
    PageLectureNavFragmentDoc,
    usePageLectureQuery,
} from '@/generated/graphql';

import GridLayout from '@/ui/GridLayout';
import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';

import PageLectureNav from '@/pages/PageLecture/PageLectureNav';
import NextMaterialLink from './ui/NextMaterialLink';
import PageLectureBreadCrumbs from './PageLectureBreadCrumbs';

import styles from './PageLecture.scss';

type Props = {
    courseSlug: CourseType['slug'];
    lectureId: CourseLectureType['id'];
    contentId: CourseContentType['id'];
};

gql`
    query PageLecture($courseSlug: String!, $lectureId: ID!, $contentId: ID!) {
        course(slug: $courseSlug) {
            ...PageLectureBreadCrumbsCourse
            lecture(lectureId: $lectureId) {
                title
                ...PageLectureBreadCrumbsLecture
                ...PageLectureNav
                content(contentId: $contentId) {
                    id
                    title
                    body
                }
            }
        }
    }
    ${PageLectureBreadCrumbsCourseFragmentDoc}
    ${PageLectureBreadCrumbsLectureFragmentDoc}
    ${PageLectureNavFragmentDoc}
`;

const PageLecture = ({ courseSlug, lectureId, contentId }: Props) => {
    const { data } = usePageLectureQuery({
        variables: {
            courseSlug,
            lectureId,
            contentId,
        },
    });

    // TODO улучшить проверку данных сервера, сейчас нет обработки состояния загрузки
    if (!data || !data.course || !data.course.lecture || !data.course.lecture.content) {
        return null;
    }

    const { course } = data;
    const { lecture } = data.course;
    const { content } = data.course.lecture;

    return (
        <GridContainer>
            <Container marginTop={6} marginBottom={4}>
                <PageLectureBreadCrumbs course={course} lecture={lecture} />
            </Container>
            <GridLayout
                leftSideContent={
                    <>
                        <div className={styles.leftSideContentDesktop}>
                            <Heading level={3}>{lecture.title}</Heading>
                            <Container marginTop={2}>
                                <PageLectureNav lecture={lecture} courseSlug={courseSlug} activeContentId={contentId} />
                            </Container>
                        </div>
                        <div className={styles.leftSideContentMobile} />
                    </>
                }
            >
                <GridRow>
                    <GridCol xl={6} lg={6} sm={4} xs={4}>
                        <Container paddingBottom={20} paddingBottomXs={8}>
                            <Heading level={1} wrap>
                                {content.title}
                            </Heading>
                            <Container marginTop={8} marginTopXs={4}>
                                {!!content.body && <EditorJsDataView content={content.body} />}
                                <NextMaterialLink to="/" title={courseSlug} description="" />
                            </Container>
                        </Container>
                    </GridCol>
                </GridRow>
            </GridLayout>
        </GridContainer>
    );
};

export default PageLecture;
