import React from 'react';
import { Skeleton } from '@xyz-school/xyz-react-frontend';

import styles from '@/ui/Image/Image.scss';

const ImageSkeleton = () => (
    <div className={styles.posterSizeWrap}>
        <Skeleton className={styles.posterWrap} />
    </div>
);

export default ImageSkeleton;
