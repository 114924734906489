import React from 'react';

import { Container, Text, Separator, Link } from '@xyz-school/xyz-react-frontend';
import Icon from '@/ui/Icon';

import styles from './NextMaterialLink.scss';

type Props = {
    title: string;
    description: string;
    image?: string;
    to: string;
};

const NextMaterialLink = ({ title, description, image, to }: Props) => {
    return (
        <Link to={to}>
            <Separator marginTop={0} marginBottom={3} marginBottomXs={1} />
            <div className={styles.row}>
                {image && (
                    <Container marginRight={3} className={styles.imageWrap}>
                        <img src={image} alt={title} className={styles.image} />
                    </Container>
                )}
                <Container marginRight={2} className={styles.content}>
                    <Container marginBottom={1}>
                        <Text>{title}</Text>
                    </Container>
                    <Text size="small" color="label">
                        {description}
                    </Text>
                </Container>
                <Icon name="arrow-right" size="large" color="text" className={styles.icon} />
            </div>
            <Separator marginTop={3} marginTopXs={1} marginBottom={0} />
        </Link>
    );
};

export default NextMaterialLink;
