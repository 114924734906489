import React from 'react';
import { gql } from '@apollo/client';

import { GridCol, GridRow } from '@xyz-school/xyz-react-frontend';
import {
    usePageSubscriptionTariffsQuery,
    PageSubscriptionTariffItemFragmentDoc,
    XyzPlusSubscriptionTariff,
} from '@/generated/graphql';
import PageSubscriptionTariffItem from './PageSubscriptionTariffItem';

import styles from './PageSubscription.scss';

gql`
    query PageSubscriptionTariffs {
        xyzPlus {
            tariffs {
                ...PageSubscriptionTariffItem
            }
        }
    }
    ${PageSubscriptionTariffItemFragmentDoc}
`;

type Props = {
    onTariffSelected: (kind: XyzPlusSubscriptionTariff) => void;
};

const PageSubscriptionTariffs = ({ onTariffSelected }: Props) => {
    const { data } = usePageSubscriptionTariffsQuery();

    return (
        <GridRow>
            {data?.xyzPlus?.tariffs?.map(
                (tariff) =>
                    tariff && (
                        <GridCol xl={3} lg={4} sm={4} key={tariff.kind} className={styles.tariffItemCol}>
                            <PageSubscriptionTariffItem tariff={tariff} onTariffSelected={onTariffSelected} />
                        </GridCol>
                    ),
            )}
        </GridRow>
    );
};

export default PageSubscriptionTariffs;
