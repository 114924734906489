import { CourseType } from '@/generated/graphql';

export const getCover = ({ thumbnail }: { thumbnail?: CourseType['thumbnail'] }): string => {
    if (!thumbnail) {
        return '';
    }

    if (thumbnail.startsWith('http')) {
        return thumbnail;
    }

    // Пока на сервере не верные пути для картинок, они относительные, позже это поправим
    if (!thumbnail.startsWith('/')) {
        return `/${thumbnail}`;
    }
    return '';
};

// @todo temp https://xyz-school.atlassian.net/browse/XYZ-2130
export const getUrl = ({ slug }: { slug: CourseType['slug'] }): string => {
    return `/courses/${slug}`;
};

// @todo НЕ УДАЛЯТЬ! Позже нужно будет вернуть эту логику построения ссылки на курс.
/* export const getUrl = ({
    access,
    slug,
    infoUrl,
}: {
    access: Pick<CourseType['access'], 'view'>;
    slug: CourseType['slug'];
    infoUrl?: CourseType['infoUrl'];
}): string => {
    if (access && access.view) {
        return `/courses/${slug}`;
    }
    return infoUrl || '';
}; */
