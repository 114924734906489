import React from 'react';

import styles from './BgShapes.scss';

import orangePolygon from './images/orangePolygon.svg';
import greenStick from './images/greenStick.png';
import greenPolygon from './images/greenPolygon.svg';
import joystick from './images/joystick.png';
import purplePolygon from './images/purplePolygon.svg';
import orangeStick from './images/orangeStick.png';

const BgShapes = () => {
    return (
        <div className={styles.shapes}>
            <img src={orangePolygon} alt="" className={styles.orangePolygon} />
            <img src={greenStick} alt="" className={styles.greenStick} />

            <img src={greenPolygon} alt="" className={styles.greenPolygon} />
            <img src={joystick} alt="" className={styles.joystick} />

            <img src={purplePolygon} alt="" className={styles.purplePolygon} />
            <img src={orangeStick} alt="" className={styles.orangeStick} />
        </div>
    );
};

export default BgShapes;
