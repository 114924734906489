import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';

import useFormatedDate from '@/core/useFormatedDate';
import { Heading, PageError404, Text } from '@xyz-school/xyz-react-frontend';
import { usePageStreamQuery, PageStreamBreadCrumbsFragmentDoc } from '@/generated/graphql';
import Dashboard from '@/pages/Dashboard';
import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';
import StaffEditBtn from '@/features/StaffEditBtn';
import ContentPageLayout from '@/ui/ContentPageLayout';
import PageStreamBreadCrumbs from './PageStreamBreadCrumbs';
import PageStreamVideoPlayer from './PageStreamVideoPlayer';

import styles from './PageStream.scss';

gql`
    query PageStream($id: ID!) {
        stream(id: $id) {
            body
            courses {
                slug
                title
            }
            access {
                view
            }
            description
            startDate
            title
            url
            pk
            ...PageStreamBreadCrumbs
        }
    }
    ${PageStreamBreadCrumbsFragmentDoc}
`;

const PageStream = () => {
    const { formatDate } = useFormatedDate();
    const { id } = useParams<{ id: string }>();
    const { data, loading } = usePageStreamQuery({ variables: { id } });

    if (loading) {
        return null;
    }

    if (!data?.stream?.access.view) {
        return <Redirect to={Dashboard.getUrl(Dashboard.tab.Streams)} />;
    }

    if (!data?.stream && !loading) {
        return <PageError404 />;
    }

    return (
        <ContentPageLayout
            bredCrumbs={
                <div className={styles.breadcrumbsWrap}>
                    <PageStreamBreadCrumbs data={data?.stream} />
                    {data?.stream?.pk && <StaffEditBtn link={`/admin/livestreams/livestream/${data.stream.pk}`} />}
                </div>
            }
            heading={
                <>
                    <div className={styles.coursesWrap}>
                        {data?.stream?.courses?.map((course) => (
                            <Text color="label" size="small">
                                {course.title}
                            </Text>
                        ))}
                    </div>
                    <div className={styles.headingWrap}>
                        <Heading level={1}>{data?.stream?.title}</Heading>
                        <Text>{formatDate(data?.stream?.startDate, 'DD MMMM, HH:mm')}</Text>
                    </div>
                </>
            }
            preview={
                <>
                    <Text color="label" wrap>
                        {data?.stream?.description}
                    </Text>

                    {data?.stream?.url && (
                        <div className={styles.videoWrap}>
                            <PageStreamVideoPlayer url={data.stream.url} />
                        </div>
                    )}
                </>
            }
        >
            {data?.stream?.body && <EditorJsDataView content={data?.stream?.body} />}
        </ContentPageLayout>
    );
};

export default PageStream;
