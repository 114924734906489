import React from 'react';
import { gql } from '@apollo/client';
import {
    CourseType,
    EnrollmentBundleCardFragment,
    EnrollmentCardEnrolledFragmentDoc,
    EnrollmentBundleCourseCardFragmentDoc,
} from '@/generated/graphql';

import EnrollmentCardEnrolled from '@/models/Enrollment/EnrollmentCardEnrolled';
import EnrollmentBundleCardUi from '@/models/Enrollment/ui/EnrollmentBundleCardUi';
import EnrollmentBundleCourseCard from './EnrollmentBundleCourseCard';
import EnrollmentBundleVacationNotification from './EnrollmentBundleVacationNotification';
import { getVacationDates } from './helpers';

gql`
    fragment EnrollmentBundleCard on EnrollmentType {
        course {
            title
            courseKind {
                label
            }
            courses {
                id
                isFree
                ...EnrollmentBundleCourseCard
            }
        }
        enrollments {
            startDate
            endDate
            course {
                id
                isFree
            }
            ...EnrollmentCardEnrolled
        }
        nextCourse {
            course {
                id
            }
            startDate
        }
    }
    ${EnrollmentCardEnrolledFragmentDoc}
    ${EnrollmentBundleCourseCardFragmentDoc}
`;

type Props = {
    enrollment: EnrollmentBundleCardFragment;
};

const EnrollmentBundleCard = ({ enrollment }: Props) => {
    if (!enrollment.course.courses?.length) {
        return null;
    }

    const getCourseEnrollmentByCourse = (course: Pick<CourseType, 'id'>) => {
        if (enrollment?.enrollments && course) {
            return enrollment?.enrollments?.find((currentEnrollment) => currentEnrollment.course.id === course.id);
        }
        return null;
    };

    const nextCourseToEnroll = enrollment?.nextCourse;
    const bundleCourses = enrollment.course.courses;

    return (
        <EnrollmentBundleCardUi heading={`${enrollment.course.courseKind.label} «${enrollment.course.title}»`}>
            {bundleCourses.map((course, i) => {
                const courseEnrollment = getCourseEnrollmentByCourse(course);
                const nextCourse: Pick<CourseType, 'id' | 'isFree'> | undefined = bundleCourses[i + 1];
                const nextCourseEnrollment = getCourseEnrollmentByCourse(nextCourse);

                if (courseEnrollment) {
                    const { vacationStartDate, vacationEndDate } = getVacationDates({
                        enrollmentEndDate: courseEnrollment.endDate,
                        isEnrollmentCourseFree: courseEnrollment.course.isFree,
                        nextCourseId: nextCourse?.id,
                        isNextCourseFree: nextCourse?.isFree,
                        nextCourseToEnrollId: nextCourseToEnroll?.course?.id,
                        nextCourseToEnrollStartDate: nextCourseToEnroll?.startDate,
                        nextEnrollmentStartDate: nextCourseEnrollment?.startDate,
                    });

                    return (
                        <>
                            <EnrollmentCardEnrolled key={course.id} enrollment={courseEnrollment} />

                            {!!vacationStartDate && !!vacationEndDate && (
                                <EnrollmentBundleVacationNotification
                                    startDate={vacationStartDate}
                                    endDate={vacationEndDate}
                                />
                            )}
                        </>
                    );
                }

                return (
                    <>
                        <EnrollmentBundleCourseCard
                            key={course.id}
                            course={course}
                            startDate={
                                course.id === nextCourseToEnroll?.course.id ? nextCourseToEnroll?.startDate : null
                            }
                        />
                    </>
                );
            })}
        </EnrollmentBundleCardUi>
    );
};

export default EnrollmentBundleCard;
