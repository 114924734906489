import React, { ReactNode } from 'react';

type Props = {
    isLoading?: boolean;
    isEmpty?: boolean;
    loadingComponent?: ReactNode;
    emptyComponent?: ReactNode;
    children: ReactNode;
};

const Loading = ({ isLoading, isEmpty, loadingComponent, emptyComponent, children }: Props) => {
    if (isLoading) {
        return <>{loadingComponent}</>;
    }
    if (isEmpty) {
        return <>{emptyComponent}</>;
    }
    return <>{children}</>;
};

export default Loading;
