import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Header, Button, Text, useLanguage } from '@xyz-school/xyz-react-frontend';
import { routes } from '@/Routes';
import { PageLayoutHeaderCurrentUserFragment } from '@/generated/graphql';

import HeaderNotification from '@/features/HeaderNotification';
import { useFeatures } from '@/models/User/useFeature';

import styles from './PageLayout.scss';

gql`
    fragment PageLayoutHeaderCurrentUser on UserType {
        username
        avatar
        isAgreementAccepted
        isStaff
        awaitingAssignment
    }
`;

type Props = {
    currentUser?: PageLayoutHeaderCurrentUserFragment;
};

const PageLayoutHeader = ({ currentUser }: Props) => {
    const { t } = useTranslation(['staff']);
    const { toLocalizedRoute } = useLanguage();
    const features = useFeatures();
    const profileLinks = currentUser?.isAgreementAccepted
        ? [
              {
                  title: 'Профиль',
                  href: routes.PROFILE,
                  icon: 'profile',
              },
          ]
        : [];

    return (
        <Header
            user={
                currentUser
                    ? {
                          username: currentUser.username,
                          avatar: currentUser.avatar,
                          roleLabel: 'Студент',
                      }
                    : undefined
            }
            navigation={[
                {
                    title: 'Акции',
                    href:
                        'https://www.school-xyz.com/konsultatsiya?utm_source=platform&utm_medium=social&utm_campaign=konsultacia-wrld-spec-&utm_content=knopka_akcia&utm_term=shapka_platformi',
                    target: '_blank',
                },
                {
                    title: 'FAQ',
                    href: '/ru/courses/faq',
                },
                {
                    title: 'Приведи друга',
                    target: '_blank',
                    href: 'https://www.school-xyz.com/referalnaya-programma',
                },
            ]}
            additionalContentSlot={
                features.checkersDashboard && currentUser?.isStaff ? (
                    <Button className={styles.headerStaffBtn} linkTo={toLocalizedRoute(routes.CHECKERS_DASHBOARD)}>
                        {t('CHECKERS_DASHBOARD_TITLE')}
                        {!!currentUser.awaitingAssignment && (
                            <Text color="accent" size="small" inline>
                                {` (${currentUser.awaitingAssignment})`}
                            </Text>
                        )}
                    </Button> // @todo: move btn fom DS to make link history mode
                ) : (
                    <div />
                )
            }
            onClickLogout={() => {
                window.location.assign('/accounts/logout/');
            }}
            onClickRegister={() => {}}
            profileLinks={profileLinks}
            notifications={currentUser?.isAgreementAccepted ? <HeaderNotification /> : <div />}
        />
    );
};

export default PageLayoutHeader;
