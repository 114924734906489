import React, { ReactNode } from 'react';

import styles from './LectureListWrapper.scss';

type Props = {
    children?: ReactNode;
};

const LectureListWrapper = ({ children }: Props) => {
    const childClone = React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return null;
        }
        return (
            <div className={styles.listItemWrap}>
                <child.type {...child.props} />
            </div>
        );
    });
    if (!children) {
        return null;
    }
    return <>{childClone}</>;
};

export default LectureListWrapper;
