import React from 'react';
import { Container, Skeleton } from '@xyz-school/xyz-react-frontend';

import CardsLayout from '@/ui/CardsLayout';
import CardLongSkeleton from '@/ui/CardLongSkeleton';

import styles from './DashboardMySkeleton.scss';

const DashboardMySkeleton = () => (
    <CardsLayout title={<Skeleton className={styles.title} />}>
        <Container paddingBottomXs={2} paddingBottom={4}>
            <CardLongSkeleton />
        </Container>
        <Container paddingBottomXs={2} paddingBottom={4}>
            <CardLongSkeleton />
        </Container>
    </CardsLayout>
);

export default DashboardMySkeleton;
