import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Container, Text } from '@xyz-school/xyz-react-frontend';

import Field from '@/ui/Field';
import RadioButton from '@/ui/RadioButton';

import {
    useUpdateUserContactInfoMutation,
    UserContactInfoDto,
    useContactInfoQuery,
    MemberGender,
} from '@/generated/graphql';

import styles from './UserContactInfoForm.scss';

gql`
    query ContactInfo {
        currentUser {
            firstName
            lastName
            middleName
            contactPhone
            contactEmail
            gender
        }
    }

    mutation UpdateUserContactInfo($input: UserContactInfoDTO) {
        updateUserContactInfo(input: $input) {
            userContactInfo {
                firstName
                lastName
                middleName
                contactEmail
                gender
            }
        }
    }
`;

type Props = {
    isSubmitDisabled?: boolean;
    submitBtnText: string;
    isSubmitBtnFullWidth?: boolean;
    onFormSuccess: () => void;
    onFormError: (message?: string) => void;
};

const UserContactInfoForm = ({
    isSubmitDisabled,
    submitBtnText,
    isSubmitBtnFullWidth = false,
    onFormSuccess,
    onFormError,
}: Props) => {
    const { data } = useContactInfoQuery();
    const { t } = useTranslation(['user']);
    const [updateUserContactInfo] = useUpdateUserContactInfoMutation();
    const [formState, setFormState] = useState<UserContactInfoDto>({});
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    const genders = Object.keys(MemberGender).map((key) => {
        return {
            key,
            value: MemberGender[key as keyof typeof MemberGender],
        };
    });

    useEffect(() => {
        if (data) {
            setFormState({
                firstName: data?.currentUser?.firstName,
                lastName: data?.currentUser?.lastName,
                middleName: data?.currentUser?.middleName,
                contactPhone: data?.currentUser?.contactPhone,
                contactEmail: data?.currentUser?.contactEmail,
                gender: data?.currentUser?.gender,
            });
        }
    }, [data]);

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                setIsSubmiting(true);
                updateUserContactInfo({
                    variables: { input: formState },
                })
                    .then(() => {
                        onFormSuccess();
                    })
                    .catch((error) => {
                        const cleanErrorMessage = error.message.split("'")[1];
                        onFormError(cleanErrorMessage);
                    })
                    .finally(() => {
                        setIsSubmiting(false);
                    });
            }}
        >
            <Container marginBottom={2}>
                <Field
                    label={t('CONTACTS_FORM_LAST_NAME_LABEL')}
                    value={formState.lastName || ''}
                    onChange={(e) => {
                        setFormState({ ...formState, lastName: e.currentTarget.value.trim() });
                    }}
                />
            </Container>
            <Container marginBottom={2}>
                <Field
                    label={t('CONTACTS_FORM_FIRST_NAME_LABEL')}
                    value={formState.firstName || ''}
                    onChange={(e) => {
                        setFormState({ ...formState, firstName: e.currentTarget.value.trim() });
                    }}
                />
            </Container>
            <Container marginBottom={2}>
                <Field
                    label={t('CONTACTS_FORM_MIDDLE_NAME_LABEL')}
                    value={formState.middleName || ''}
                    onChange={(e) => {
                        setFormState({ ...formState, middleName: e.currentTarget.value.trim() });
                    }}
                />
            </Container>

            <Container marginBottom={2}>
                <Field
                    label={t('CONTACTS_FORM_EMAIL_LABEL')}
                    value={formState.contactEmail || ''}
                    onChange={(e) => {
                        setFormState({ ...formState, contactEmail: e.currentTarget.value.trim() });
                    }}
                />
            </Container>

            <Container marginBottom={2}>
                <Text size="small" color="label">
                    {t('CONTACTS_FORM_GENDER_LABEL')}
                </Text>
                <div className={styles.radioButtonGroup}>
                    {genders.map((gender) => {
                        return (
                            <RadioButton
                                key={gender.key}
                                id={gender.key}
                                label={t(`CONTACTS_FORM_GENDER_${gender.value}`)}
                                value={gender.value}
                                name="genderSelector"
                                onChange={(val) => {
                                    setFormState({ ...formState, gender: val });
                                }}
                                isChecked={gender.value === formState.gender}
                                className={styles.radioButton}
                                isFullWidth
                            />
                        );
                    })}
                </div>
            </Container>

            <Button
                isFullWidth={isSubmitBtnFullWidth}
                styleType="primary"
                type="submit"
                size="large"
                isDisabled={isSubmiting || isSubmitDisabled}
            >
                {submitBtnText}
            </Button>
        </form>
    );
};

export default UserContactInfoForm;
