import { useState } from 'react';
import { gql } from '@apollo/client';
import { TariffDto, useCreateXyzPlusSubscriptionInvoiceMutation } from '@/generated/graphql';
import useCloudPaymentsWidget from '@/features/CloudPaymentsWidget';

gql`
    mutation createXyzPlusSubscriptionInvoice($input: TariffDTO!) {
        createXyzPlusSubscriptionInvoice(input: $input) {
            xyzPlusSubscriptionInvoice {
                cpWidgetData
            }
        }
    }
`;

type сreateXyzPlusSubscriptionType = {
    isInvoiceCreating: boolean;
    isWidgetOpened: boolean;
    сreateXyzPlusSubscription: (tariff: TariffDto) => void;
};

const useCreateXyzPlusSubscriptionInvoice = (onPaymentSuccess: () => void): сreateXyzPlusSubscriptionType => {
    const [isInvoiceCreating, setIsInvoiceCreating] = useState<boolean>(false);
    const [isWidgetOpened, openCloudPaymentsWidget] = useCloudPaymentsWidget();
    const [createXyzPlusSubscriptionInvoice] = useCreateXyzPlusSubscriptionInvoiceMutation();

    const сreateXyzPlusSubscription = (tariff: TariffDto) => {
        setIsInvoiceCreating(true);
        createXyzPlusSubscriptionInvoice({ variables: { input: tariff } }).then(({ data }) => {
            setIsInvoiceCreating(false);
            openCloudPaymentsWidget({
                options: data?.createXyzPlusSubscriptionInvoice?.xyzPlusSubscriptionInvoice?.cpWidgetData,
                onSuccess: onPaymentSuccess,
            });
        });
    };

    return { isInvoiceCreating, isWidgetOpened, сreateXyzPlusSubscription };
};

export default useCreateXyzPlusSubscriptionInvoice;
