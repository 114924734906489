import React from 'react';
import classnames from 'classnames';

import { Heading, Text } from '@xyz-school/xyz-react-frontend';
import styles from './Avatar.scss';

type Props = {
    src?: string;
    alt?: string;
    size?: 'auto' | 'normal' | 'small';
    userName?: string;
    className?: string;
};

function Avatar({ src, alt, userName, className, size = 'auto' }: Props) {
    const nameFirstLetter = userName ? userName.charAt(0) : '';

    if (src) {
        return (
            <div className={classnames(styles.avatar, className, { [styles[size]]: !!size })}>
                <img src={src} alt={alt || userName} className={styles.image} />
            </div>
        );
    }

    return (
        <div className={classnames(styles.avatar, className, { [styles[size]]: !!size })}>
            {size !== 'auto' ? (
                <Text color="label" className={styles.firstLetter}>
                    {nameFirstLetter}
                </Text>
            ) : (
                <Heading level={1} className={styles.firstLetter}>
                    {nameFirstLetter}
                </Heading>
            )}
        </div>
    );
}

export default Avatar;
