import React, { useState, lazy, Suspense } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import ContentVideoError from '../ContentVideoError';

import styles from './ContentYoutube.scss';

const ReactPlayer = lazy(() => import(/* webpackChunkName: "ReactPlayer" */ 'react-player/lazy'));

type Props = {
    url: string;
    className?: string;
};

const ContentYoutube = ({ url, className }: Props) => {
    const [isVideoLoadingError, setIsVideoLoadingError] = useState<boolean>(false);

    if (isVideoLoadingError) {
        return <ContentVideoError />;
    }

    return (
        <LazyLoad offset={100} once placeholder={<div className={styles.ContentYoutubeLazyLoadPlaceholder} />}>
            <Suspense fallback={<div className={styles.ContentYoutubeLazyLoadPlaceholder} />}>
                <ReactPlayer
                    url={url}
                    className={classnames(styles.ContentYoutube, className)}
                    width="100%"
                    height="auto"
                    controls
                    onError={(error: unknown) => {
                        if (error) {
                            setIsVideoLoadingError(true);
                        }
                    }}
                />
            </Suspense>
        </LazyLoad>
    );
};

export default ContentYoutube;
