import React from 'react';
import { gql } from '@apollo/client';
import { EnrollmentProgressFragment, useUserIdQuery } from '@/generated/graphql';

import EnrollmentProgressUi from './ui/EnrollmentProgressUi';

gql`
    query UserId {
        currentUser {
            id
        }
    }

    fragment EnrollmentProgress on EnrollmentType {
        course {
            slug
        }
        progress {
            completion
            expectedProgress
            completedHomeworks
            totalHomeworks
        }
        state
        canBuyHomework
    }
`;

type Props = {
    data: EnrollmentProgressFragment;
};

const EnrollmentProgress = ({ data: { course, progress, state, canBuyHomework } }: Props) => {
    const { data } = useUserIdQuery();

    return (
        <EnrollmentProgressUi
            enrollmentState={state}
            courseSlug={course?.slug}
            currentUserId={data?.currentUser?.id || ''}
            progress={progress?.completion}
            expectedProgress={progress?.expectedProgress}
            totalHomeworks={progress?.totalHomeworks}
            completedHomeworks={progress?.completedHomeworks}
            canBuyHomework={canBuyHomework}
        />
    );
};

export default EnrollmentProgress;
