import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { usePageProfileQuery, UserPhoneFormFragmentDoc, UserDiscordControlFragmentDoc } from '@/generated/graphql';

import { Heading, PageError500, useMedia, useNotificationActions } from '@xyz-school/xyz-react-frontend';
import UserPhoneForm from '@/models/User/UserPhoneForm';
import UserDiscordControl from '@/models/User/UserDiscordControl';
import UserContactInfoForm from '@/models/User/UserContactInfoForm';
import Avatar from '@/ui/Avatar';
import PageProfileLayout from './ui/PageProfileLayout';
import PageProfileBreadCrumbs from './PageProfileBreadCrumbs';

import styles from './PageProfile.scss';

gql`
    query PageProfile {
        currentUser {
            username
            avatar
            isDiscordRequired
            ...UserPhoneForm
            ...UserDiscordControl
        }
        ${UserPhoneFormFragmentDoc}
        ${UserDiscordControlFragmentDoc}
    }
`;

const PageProfile = () => {
    const { sm, xs } = useMedia();
    const { t } = useTranslation(['profile']);
    const { data, loading, error } = usePageProfileQuery();
    const { pushNotification } = useNotificationActions();

    if (!data && !loading) {
        return null;
    }

    if (error) {
        return <PageError500 />;
    }

    return (
        <PageProfileLayout
            avatar={
                !!data?.currentUser && (
                    <Avatar
                        src={data.currentUser.avatar}
                        alt={data.currentUser.username}
                        userName={data.currentUser.username}
                    />
                )
            }
        >
            <PageProfileBreadCrumbs />

            <Heading level={1} className={styles.title}>
                {t('EDIT_TITLE')}
            </Heading>

            <Heading level={2} className={styles.subtitle}>
                {t('PHONE_SUBTITLE')}
            </Heading>
            <UserPhoneForm data={data?.currentUser} />

            <Heading level={2} className={styles.subtitle}>
                {t('DISCORD_SUBTITLE')}
            </Heading>

            {data?.currentUser && <UserDiscordControl data={data.currentUser} />}

            <Heading level={2} className={styles.subtitle}>
                {t('CONTACTS_SUBTITLE')}
            </Heading>
            <UserContactInfoForm
                submitBtnText={t('SAVE_BTN_TEXT')}
                isSubmitBtnFullWidth={sm || xs}
                onFormSuccess={() => {
                    pushNotification({
                        type: 'success',
                        message: t('SAVE_SUCCESS_MESSAGE'),
                        name: 'successContactDataChange',
                    });
                }}
                onFormError={(message) => {
                    pushNotification({
                        type: 'error',
                        message: message || t('SAVE_ERROR_MESSAGE'),
                        name: 'errorContactDataChange',
                    });
                }}
            />
        </PageProfileLayout>
    );
};

export default PageProfile;
