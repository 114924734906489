import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Text } from '@xyz-school/xyz-react-frontend';

import styles from './ContentVideoError.scss';

const ContentVideoError = () => {
    const { t } = useTranslation(['editorContent']);

    return (
        <Container
            paddingTop={1}
            paddingRight={2}
            paddingBottom={1.5}
            paddingLeft={2}
            className={styles.contentVideoError}
        >
            <Text>{t('VIDEO_ERROR_TITLE')}</Text>
            <Container paddingTop={0.5}>
                <Text size="small" color="label">
                    {t('VIDEO_ERROR_DESCRIPTION')}
                </Text>
            </Container>
        </Container>
    );
};

export default ContentVideoError;
