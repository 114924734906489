import React from 'react';
import classnames from 'classnames';

import styles from './InfoLabel.scss';

type Props = {
    value: string;
    color?: 'success' | 'accent';
};

const InfoLabel = ({ value, color = 'accent' }: Props) => {
    return (
        <div className={classnames(styles.infoLabel, [styles[color]])}>
            <p className={styles.value}>{value}</p>
        </div>
    );
};

export default InfoLabel;
