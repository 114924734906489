import React, { ReactNode } from 'react';
import classNames from 'classnames';

import DefinitionListValue from './DefinitionListValue';
import DefinitionListLabel from './DefinitionListLabel';

import styles from './DefinitionList.scss';

type Props = {
    // TODO: change type to array of DefinitionListValue and DefinitionListLabel elements.
    children: ReactNode;
    className?: string;
};

const DefinitionList = ({ children, className }: Props) => {
    return <div className={classNames(styles.DefinitionList, className)}>{children}</div>;
};

DefinitionList.Value = DefinitionListValue;
DefinitionList.Label = DefinitionListLabel;

export default DefinitionList;
