import React, { ReactNode } from 'react';
import { GridContainer, GridCol, GridRow } from '@xyz-school/xyz-react-frontend';

type Props = {
    leftSideContent?: ReactNode;
    children?: ReactNode;
};

const GridLayout = ({ leftSideContent, children }: Props) => {
    return (
        <GridContainer>
            <GridRow>
                {leftSideContent && (
                    <GridCol xl={3} lg={12} sm={4}>
                        {leftSideContent}
                    </GridCol>
                )}
                <GridCol xl={9} lg={12} sm={4}>
                    {children}
                </GridCol>
            </GridRow>
        </GridContainer>
    );
};

export default GridLayout;
