import React, { ReactNode } from 'react';

import { Container, GridContainer, GridCol } from '@xyz-school/xyz-react-frontend';

import styles from './ContentPageLayout.scss';

type Props = {
    bredCrumbs: ReactNode;
    heading: ReactNode;
    preview?: ReactNode;
    controls?: ReactNode;
    children: ReactNode;
};

const ContentPageLayout = ({ bredCrumbs, heading, preview, controls, children }: Props) => {
    return (
        <GridContainer className={styles.contentPageLayout}>
            <GridCol xl={6} lg={8} sm={4}>
                {bredCrumbs}
                <Container marginBottom={5}>{heading}</Container>
                {preview && <>{preview}</>}
                {controls && <Container marginTop={4}>{controls}</Container>}
                <Container marginTop={10}>{children}</Container>
            </GridCol>
        </GridContainer>
    );
};

export default ContentPageLayout;
