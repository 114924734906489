import React from 'react';
import Field from '@/ui/Field';

import { useTranslation } from 'react-i18next';

type Props = {
    className?: string;
    id?: string;
    isDisabled?: boolean;
    value?: string;
    placeholder?: string;
    onBlur?: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const PhoneField = React.forwardRef(
    ({ className, id, isDisabled, value, placeholder, onBlur, onChange }: Props, ref) => {
        const { t } = useTranslation(['controls']);

        return (
            <Field
                ref={ref}
                className={className}
                id={id}
                isDisabled={isDisabled}
                label={t('PHONE_FIELD_LABEL')}
                mask="+7 (999) 999-99-99"
                value={value}
                placeholder={placeholder}
                onBlur={onBlur}
                onChange={onChange}
            />
        );
    },
);

export default PhoneField;
