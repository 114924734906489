import React, { ReactNode } from 'react';
import classnames from 'classnames';

import { LinkProvider, Media, NavLink, Text } from '@xyz-school/xyz-react-frontend';

import Icon from '@/ui/Icon';
import styles from './SidebarLink.scss';

type Props = {
    to: string;
    children: string;
    iconName: string;
    label?: ReactNode;
    className?: string;
    onActiveNavLinkClick: () => void;
};

const SidebarLink = ({ to, children, iconName, label, className, onActiveNavLinkClick }: Props) => {
    return (
        <LinkProvider history>
            <NavLink
                to={to}
                activeClassName={styles.sidebarLinkActive}
                className={classnames(styles.sidebarLink, className)}
                onActiveNavLinkClick={onActiveNavLinkClick}
            >
                <Icon name={iconName} className={styles.sidebarLinkIcon} />
                <Media greaterThanOrEqual="sm">
                    <Text className={styles.sidebarLinkText}>{children}</Text>
                </Media>
                <Media lessThan="sm">
                    <Text size="small" color="label" className={styles.sidebarLinkText}>
                        {children}
                    </Text>
                </Media>
                {label && <div className={styles.sidebarLinkLabelWrap}>{label}</div>}
            </NavLink>
        </LinkProvider>
    );
};

export default SidebarLink;
