import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { UserDiscordControlFragment } from '@/generated/graphql';

import { Button, Text } from '@xyz-school/xyz-react-frontend';
import Avatar from '@/ui/Avatar';
import Icon from '@/ui/Icon';

import styles from './UserDiscordControl.scss';

gql`
    fragment UserDiscordControl on UserType {
        username
        avatar
        isDiscordRequired
    }
`;

type Props = {
    data: UserDiscordControlFragment;
};

const UserDiscordControl = ({ data }: Props) => {
    const { t } = useTranslation(['user']);

    if (data.isDiscordRequired) {
        return (
            <a href="/accounts/discord/login/" className={styles.discordButton}>
                <div className={styles.discordButtonIconWrap}>
                    <Icon name="plus" color="text" />
                </div>
                <Text>{t('ADD_DISCORD_BTN_TEXT')}</Text>
            </a>
        );
    }

    return (
        <div className={styles.discordWrap}>
            <div className={styles.discordUserWrap}>
                <Avatar
                    src={data.avatar}
                    alt={data.username}
                    userName={data.username}
                    className={styles.discordAvatar}
                    size="normal"
                />
                <Text>{data.username}</Text>
            </div>
            <Button styleType="text" linkTo="/accounts/discord/login/">
                {t('CHANGE_BTN_TEXT')}
            </Button>
        </div>
    );
};

export default UserDiscordControl;
