import React, { createContext, ReactNode } from 'react';

import { gql } from '@apollo/client';
import { CurrentUserProviderFragment } from '@/generated/graphql';

import { FeatureType } from './features';

type CurrentUserContextType = {
    features: {
        [key in FeatureType]?: true;
    };
};

const Context = createContext<CurrentUserContextType>({ features: {} });

gql`
    fragment CurrentUserProvider on Query {
        features
    }
`;

type Props = {
    children: ReactNode;
    data?: CurrentUserProviderFragment;
};

const CurrentUserProvider = ({ children, data }: Props) => {
    const contextData = { features: data?.features || {} };
    return <Context.Provider value={contextData}>{children}</Context.Provider>;
};

CurrentUserProvider.Context = Context;

export default CurrentUserProvider;
