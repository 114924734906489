import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Heading } from '@xyz-school/xyz-react-frontend';

import StreamList from '@/models/Stream/StreamList';

const DashboardXyzPlus = () => {
    const { t } = useTranslation(['dashboard']);

    return (
        <>
            <Container marginBottom={6}>
                <Heading level={1}>{t('STREAMS')}</Heading>;
            </Container>
            <StreamList />
        </>
    );
};

export default DashboardXyzPlus;
