import React from 'react';
import { gql } from '@apollo/client';

import {
    EnrollmentCardEnrolledFragment,
    EnrollmentStatusFragmentDoc,
    EnrollmentProgressFragmentDoc,
    EnrollmentDateFragmentDoc,
} from '@/generated/graphql';

import { BlockLabel, Container, Text, Link } from '@xyz-school/xyz-react-frontend';

import BadgePlacer from '@/ui/BadgePlacer';
import Image from '@/ui/Image';
import { courseHelpers } from '@/models/Course';
import CardLong from '@/ui/CardLong';

import EnrollmentProgress from '@/models/Enrollment/EnrollmentProgress';
import EnrollmentDate from '@/models/Enrollment/EnrollmentDate';
import EnrollmentStatus from '@/models/Enrollment/EnrollmentStatus';

gql`
    fragment EnrollmentCardEnrolled on EnrollmentType {
        id
        startDate
        ...EnrollmentStatus
        ...EnrollmentDate
        ...EnrollmentProgress
        course {
            slug
            courseKind {
                label
            }
            thumbnail
            title
            isFree
        }
    }
    ${EnrollmentProgressFragmentDoc}
    ${EnrollmentStatusFragmentDoc}
    ${EnrollmentDateFragmentDoc}
`;

type Props = {
    enrollment: EnrollmentCardEnrolledFragment;
};

const EnrollmentCardEnrolled = ({ enrollment }: Props) => {
    const getCardDescription = () => {
        if (enrollment.course.isFree) {
            return null;
        }
        if (enrollment.startDate) {
            return (
                <Text color="label" size="small">
                    <EnrollmentStatus enrollment={enrollment} />
                    <Container display="inline" marginLeft={2}>
                        <EnrollmentDate enrollment={enrollment} />
                    </Container>
                </Text>
            );
        }
        return null;
    };

    const getCardProgress = () => {
        if (enrollment.progress) {
            return <EnrollmentProgress data={enrollment} />;
        }
        return null;
    };

    return (
        <div>
            <Link to={courseHelpers.getUrl(enrollment.course)}>
                <CardLong
                    title={enrollment.course.title}
                    description={getCardDescription()}
                    image={
                        <BadgePlacer topLeft={<BlockLabel>{enrollment.course.courseKind.label}</BlockLabel>}>
                            <Image
                                image={courseHelpers.getCover(enrollment.course)}
                                coverAlt={enrollment.course.title}
                            />
                        </BadgePlacer>
                    }
                    footer={getCardProgress()}
                />
            </Link>
        </div>
    );
};
export default EnrollmentCardEnrolled;
