import React from 'react';

import { Container } from '@xyz-school/xyz-react-frontend';
// TODO заменить импорт с /lib/content на /content после изменений в ds
import { Content } from '@xyz-school/xyz-react-frontend/lib/content';

import { EditorJsDataType } from '@/models/EditorJsData';

import EditorJsDataViewBlock from './EditorJsDataViewBlock';

type Props = {
    content: EditorJsDataType;
};

const EditorJsDataView = ({ content }: Props) => {
    return (
        <Content>
            {content.blocks.map((block, index) => (
                <Container marginBottom={4} marginBottomXs={2} key={index}>
                    <EditorJsDataViewBlock block={block} prevBlockType={content.blocks[index - 1]?.type} />
                </Container>
            ))}
        </Content>
    );
};

export default EditorJsDataView;
