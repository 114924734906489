import React from 'react';
import urlParser from 'js-video-url-parser';

import ContentKinescope from '@/models/EditorJsData/ui/ContentKinescope';
import ContentYoutube from '@/models/EditorJsData/ui/ContentYoutube';
import ContentVimeo from '@/models/EditorJsData/ui/ContentVimeo';

import { getKinescopeVideoId } from '@/models/EditorJsData/ui/ContentKinescope/helpers';

type Props = {
    url: string;
};

const PageStreamVideoPlayer = ({ url }: Props) => {
    const data = urlParser.parse(url);
    const kinescopeVideoId = getKinescopeVideoId({ url });

    if (data?.provider === 'youtube') {
        return <ContentYoutube url={url} />;
    }

    if (data?.provider === 'vimeo') {
        return <ContentVimeo url={url} />;
    }

    if (kinescopeVideoId) {
        return <ContentKinescope id={kinescopeVideoId} />;
    }

    return null;
};

export default PageStreamVideoPlayer;
