import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import usePollingIsXyzPlusActive from '@/models/XyzPlus/usePollingIsXyzPlusActive';
import { LoadingSpinner } from '@xyz-school/xyz-react-frontend';

import СenteredLayout from './ui/СenteredLayout';

type Props = {
    onXyzPlusBecomeActive: () => void;
};

const PageSubscriptionSuccessWaiting = ({ onXyzPlusBecomeActive }: Props) => {
    const { t } = useTranslation(['subscription']);
    const { isXyzPlusActive } = usePollingIsXyzPlusActive();

    useEffect(() => {
        if (isXyzPlusActive) {
            onXyzPlusBecomeActive();
        }
    }, [isXyzPlusActive, onXyzPlusBecomeActive]);

    return (
        <СenteredLayout
            aboveHeading={<LoadingSpinner />}
            heading={t('ACCESS_PENDING_TITLE')}
            description={t('ACCESS_PENDING_TEXT')}
        />
    );
};

export default PageSubscriptionSuccessWaiting;
