import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Container, Heading } from '@xyz-school/xyz-react-frontend';

import Loading from '@/core/Loading';
import { useDashboardAllQuery, DashboardCoursesListFragmentDoc } from '@/generated/graphql';

import Quiz from '@/features/Quiz';

import DashboardCoursesList from '../DashboardCoursesList';
import DashboardAllSkeleton from './DashboardAllSkeleton';

gql`
    query DashboardAll {
        draftCourses: courses(scope: "draft") {
            ...DashboardCoursesList
        }
        availableCourses: courses(scope: "available") {
            ...DashboardCoursesList
        }
        archiveCourses: courses(scope: "archive") {
            ...DashboardCoursesList
        }
        ${DashboardCoursesListFragmentDoc}
    }
`;

type Props = {
    banner?: React.ReactNode;
};

const DashboardAll = ({ banner }: Props) => {
    const { t } = useTranslation(['dashboard']);
    const { data, loading } = useDashboardAllQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-only',
    });

    return (
        <>
            <Container marginBottom={6}>
                <Heading level={1}>{t('ALL_COURSES')}</Heading>;
            </Container>

            <Container marginBottom={6}>
                <Quiz />
            </Container>

            <Loading isLoading={loading} loadingComponent={<DashboardAllSkeleton />}>
                {/* Черновики */}
                {!!data?.draftCourses?.edges.length && (
                    <DashboardCoursesList title={t('DRAFTS')} listData={data.draftCourses} />
                )}

                {/* Доступные */}
                {!!data?.availableCourses?.edges.length && (
                    <DashboardCoursesList title={t('ALL_COURSES')} listData={data.availableCourses} />
                )}

                {/* Архив */}
                {!!data?.archiveCourses?.edges.length && (
                    <DashboardCoursesList title={t('ARCHIVE')} listData={data.archiveCourses} />
                )}
            </Loading>

            {banner && (
                <Container marginBottom={10} marginBottomXs={6}>
                    {banner}
                </Container>
            )}
        </>
    );
};

export default DashboardAll;
