import React, { ReactElement, ReactNode } from 'react';

import { Heading, LinkProvider, NavLink } from '@xyz-school/xyz-react-frontend';

import styles from './Tab.scss';

type Props = {
    children: ReactNode;
    to: string;
};

const TabItem = ({ children, to }: Props): ReactElement => {
    return (
        <LinkProvider history>
            <NavLink
                to={to}
                className={styles.TabItem}
                activeClassName={styles.TabItem_Active}
                onActiveNavLinkClick={() => {}}
            >
                <Heading level={3}>{children}</Heading>
            </NavLink>
        </LinkProvider>
    );
};

export default TabItem;
