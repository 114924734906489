import dayjs from 'dayjs';

export const isToday = (date: string): boolean => dayjs().isSame(dayjs(date), 'day');

export const isInFuture = (date: string): boolean => dayjs().isBefore(dayjs(date), 'day');

export const isInPast = (date: string): boolean => dayjs().isAfter(dayjs(date), 'day');

export const byClosestUpcomingDate = (currentStartDate: any, nextStartDate: any) =>
    new Date(currentStartDate).getTime() - new Date(nextStartDate).getTime();

export const byClosestPastDate = (currentStartDate: any, nextStartDate: any) =>
    new Date(nextStartDate).getTime() - new Date(currentStartDate).getTime();
