import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { Link, Text } from '@xyz-school/xyz-react-frontend';

import BreadcrumbsWrapper from '@/features/BreadcrumbsWrapper';
import GridLayout from '@/ui/GridLayout';

import { PageCourseBreadCrumbsFragment } from '@/generated/graphql';

gql`
    fragment PageCourseBreadCrumbs on CourseType {
        title
    }
`;

type Props = {
    course: PageCourseBreadCrumbsFragment;
};

const PageCourseBreadCrumbs = ({ course }: Props) => {
    const { t } = useTranslation(['dashboard']);

    return (
        <GridLayout
            leftSideContent={
                <BreadcrumbsWrapper>
                    <Link to="/dashboard/my">{t('MY_COURSES')}</Link>
                    <Text>{course.title}</Text>
                </BreadcrumbsWrapper>
            }
        />
    );
};

export default PageCourseBreadCrumbs;
