import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@xyz-school/xyz-react-frontend';

import Dashboard from '@/pages/Dashboard';
import СenteredLayout from './ui/СenteredLayout';
import BgShapes from './ui/BgShapes';

import styles from './PageSubscription.scss';

const PageSubscriptionSuccessReady = () => {
    const { t } = useTranslation(['subscription']);

    return (
        <СenteredLayout heading={t('WELCOME_TITLE')} description={t('WELCOME_TEXT')}>
            <Button
                styleType="primary"
                size="large"
                // add ru because it not react url (without history mode)
                linkTo={`/ru${Dashboard.getUrl(Dashboard.tab.XyzPlus)}`}
                className={styles.successStateBtn}
            >
                {t('BTN_ACTIVE_SUBSCRIPTION_TEXT')}
            </Button>
            <BgShapes />
        </СenteredLayout>
    );
};

export default PageSubscriptionSuccessReady;
