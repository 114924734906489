import React from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Container, Heading, Media, PageError404 } from '@xyz-school/xyz-react-frontend';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import GridLayout from '@/ui/GridLayout';
import PageCourseMainInfo from '@/pages/PageCourse/PageCourseMainInfo';
import PageCourseBreadCrumbs from '@/pages/PageCourse/PageCourseBreadCrumbs';
import PageCourseSectionList from '@/pages/PageCourse/PageCourseSectionList';
import {
    PageCourseMainInfoFragmentDoc,
    PageCourseBreadCrumbsFragmentDoc,
    PageCourseSectionListFragmentDoc,
    usePageCoursePageQuery,
    FaqListFragmentDoc,
} from '@/generated/graphql';
import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';
import Tab from '@/ui/Tab';
import { routes } from '@/Routes';
import FaqList from '@/features/Faq/FaqList';

const TAB = {
    structure: 'structure',
    materials: 'materials',
    faq: 'faq',
} as const;

gql`
    query PageCoursePage($slug: String!) {
        course(slug: $slug) {
            ...PageCourseMainInfo
            ...PageCourseBreadCrumbs
            ...PageCourseSectionList
            ...FaqList
            materials
        }
    }
    ${PageCourseMainInfoFragmentDoc}
    ${PageCourseBreadCrumbsFragmentDoc}
    ${PageCourseSectionListFragmentDoc}
    ${FaqListFragmentDoc}
`;

const PageCourse = () => {
    const { t } = useTranslation(['course']);
    const { slug, tab } = useParams<{ slug: string; tab?: string }>();

    const { data, loading } = usePageCoursePageQuery({ variables: { slug } });

    const isNotFound = !loading && !data?.course;

    if (!tab) {
        return <Redirect to={routes.COURSE(slug, TAB.structure)} />;
    }

    if (!data?.course) {
        return null;
    }

    if (isNotFound) {
        return <PageError404 />;
    }

    const { course } = data;

    return (
        <>
            <Container marginTop={6} marginBottom={4} marginTopXs={4} marginBottomXs={0}>
                <PageCourseBreadCrumbs course={course} />
            </Container>
            <PageCourseMainInfo course={course} />
            <GridLayout
                leftSideContent={
                    <Media greaterThanOrEqual="sm">
                        <Heading level={2}>{t('SECTIONS_HEADING')}</Heading>
                    </Media>
                }
            >
                <Tab>
                    <Tab.Item to={routes.COURSE(slug, TAB.structure)}>{t('COURSES_STRUCTURE')}</Tab.Item>
                    <Tab.Item to={routes.COURSE(slug, TAB.materials)}>{t('COURSES_MATERIALS')}</Tab.Item>
                    <Tab.Item to={routes.COURSE(slug, TAB.faq)}>{t('COURSES_FAQ')}</Tab.Item>
                </Tab>
                {tab === TAB.structure && <PageCourseSectionList courseSlug={slug} course={course} />}
                {tab === TAB.materials && !!course.materials && <EditorJsDataView content={course.materials} />}
                {tab === TAB.faq && data?.course && <FaqList course={data?.course} />}
            </GridLayout>
        </>
    );
};

PageCourse.tab = TAB;
export default PageCourse;
