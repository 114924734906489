/* eslint-disable camelcase */

import { NotificationType } from './notification';

const BASE_API_URL = '/inbox/notifications';

type NotificationItemApiType = {
    id: number;
    unread: boolean; // Прочитал ли пользователь уведомление
    verb: string; // Текст сообщения
    timestamp: string; // Дата создания сообщения
    data?: {
        url?: string; // адресс куда сообщение должно перенаправить пользователя
    };
    // Прочие поля которые есть на сервере, но мы их не используем
    level: string;
    recipient: number;
    actor_content_type: number;
    actor_object_id: string;
    description: string;
    target_content_type?: string;
    target_object_id?: number;
    action_object_content_type: number;
    action_object_object_id?: number;
    public: boolean;
    deleted: boolean;
    emailed: boolean;
    slug: number;
    actor: string;
};

export type NotificationsApiListType = {
    allCount: number;
    allList: NotificationType[];
};

/**
 * Нормализация данных от сервера, так как сообщения прихдоят в не понятном формате, а мы на клиенте работает с ними по другому
 * @param item
 */
const transformServerNotification = (item: NotificationItemApiType): NotificationType => {
    return {
        id: item.slug.toString(),
        description: item.verb,
        date: item.timestamp,
        url: item.data?.url,
        isRead: !item.unread,
    };
};

export const getNotificationUnreadCount = (): Promise<{ unreadCount: number }> => {
    return fetch(`${BASE_API_URL}/api/unread_count/`)
        .then((value) => value.json())
        .then((response) => ({ unreadCount: response.unread_count }));
};

/**
 * Апи метод для получения списка всех уведомлений
 * Сервер не поддерживает пагинация
 */
export const getNotificationList = ({ limit }: { limit: number }): Promise<NotificationsApiListType> => {
    return fetch(`${BASE_API_URL}/api/all_list/?max=${limit}`)
        .then<{ all_count: number; all_list: NotificationItemApiType[] }>((dataInner) => dataInner.json())
        .then((response) => {
            return {
                allCount: response.all_count,
                allList: response.all_list.map(transformServerNotification),
            };
        });
};

export const markAsReadNotification = ({ id }: { id: number | string }) => {
    return fetch(`${BASE_API_URL}/mark-as-read/${id}/`, {
        method: 'GET',
        redirect: 'manual',
    });
};
