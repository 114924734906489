import React from 'react';
import { useTranslation } from 'react-i18next';
import { State } from '@/generated/graphql';

import ProgressBar from '@/ui/ProgressBar';
import { normalizeProgress } from '@/ui/ProgressBar/utils';
import DefinitionList from '@/ui/DefinitionList';

import EnrollmentProgressLayout from '../EnrollmentProgressLayout';
import useEnrollmentProgressState from '../../useEnrollmentProgressState';

import styles from './EnrollmentProgressUi.scss';

type Props = {
    courseSlug: string;
    currentUserId: string;
    progress?: number;
    expectedProgress?: number;
    totalHomeworks?: number;
    completedHomeworks?: number;
    enrollmentState?: State;
    canBuyHomework: boolean;
};

const EnrollmentProgressUi = ({
    courseSlug,
    currentUserId,
    progress,
    expectedProgress,
    totalHomeworks,
    completedHomeworks,
    enrollmentState,
    canBuyHomework,
}: Props) => {
    const { t } = useTranslation(['enrollment']);

    const progressState = useEnrollmentProgressState({
        progress,
        expectedProgress,
        totalHomeworks,
        completedHomeworks,
        courseSlug,
        currentUserId,
        canBuyHomework,
    });

    if (enrollmentState === State.IsAwaitingStart) {
        return null;
    }

    const isShowForActive = enrollmentState === State.IsActive;
    const isShowForCompleted = enrollmentState === State.IsCompleted && typeof progress === 'number' && progress < 1;
    const isShowExpectedProgress = isShowForActive || isShowForCompleted;

    return (
        <div className={styles.enrollmentProgress}>
            <div className={styles.enrollmentProgressBars}>
                {typeof progress === 'number' && (
                    <EnrollmentProgressLayout
                        progressLabel={progressState.label || t('ENROLLMENT_PROGRESS_LABEL')}
                        progressValue={`${normalizeProgress(progress)}%`}
                        popover={progressState.info}
                    >
                        <ProgressBar color={progressState.color} progress={progress} />
                    </EnrollmentProgressLayout>
                )}

                {isShowExpectedProgress && typeof expectedProgress === 'number' && (
                    <EnrollmentProgressLayout
                        progressLabel={t('ENROLLMENT_EXPECTED_PROGRESS_LABEL')}
                        progressValue={`${normalizeProgress(expectedProgress)}%`}
                    >
                        <ProgressBar progress={expectedProgress} />
                    </EnrollmentProgressLayout>
                )}
            </div>
            {!!totalHomeworks && typeof expectedProgress === 'number' && (
                <DefinitionList className={styles.enrollmentProgressInfo}>
                    <DefinitionList.Label>{t('ENROLLMENT_DONE_LABEL')}</DefinitionList.Label>
                    <DefinitionList.Value>
                        {completedHomeworks} / {totalHomeworks} {t('ENROLLMENT_HOMEWORKS_LABEL')}
                    </DefinitionList.Value>
                </DefinitionList>
            )}
        </div>
    );
};

export default EnrollmentProgressUi;
