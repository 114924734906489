import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '@/ui/Icon';
import { GridContainer, Heading } from '@xyz-school/xyz-react-frontend';

import styles from './HeaderBase.scss';

type Props = {
    children?: React.ReactNode;
};

const HeaderBase = ({ children }: Props) => {
    return (
        <GridContainer className={styles.header}>
            <Link to="/" className={styles.logoLink}>
                <div className={styles.logo}>
                    <Icon name="logo" color="inherit" size="inherit" className={styles.logoIcon} />
                </div>
                <Heading level={2}>School</Heading>
            </Link>
            {children}
        </GridContainer>
    );
};

export default HeaderBase;
