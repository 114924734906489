import React, { useRef } from 'react';
import { XyzPlusSubscriptionTariff } from '@/generated/graphql';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { routes } from '@/Routes';

import { useLocalizedHistory, useNotificationActions } from '@xyz-school/xyz-react-frontend';

import useCreateXyzPlusSubscription from '@/models/XyzPlus/useCreateXyzPlusSubscription';
import PageSubscriptionSettings from './PageSubscriptionSettings';
import PageSubscriptionContacts from './PageSubscriptionContacts';
import PageSubscriptionSuccess from './PageSubscriptionSuccess';

const SubscriptionStepType = {
    Contacts: 'contacts',
    Success: 'success',
} as const;

const PageSubscription = () => {
    const history = useLocalizedHistory();
    const location = useLocation();
    const { t } = useTranslation(['subscription']);
    const { pushNotification } = useNotificationActions();

    const onPaymentSuccess = () => {
        history.push(routes.SUBSCRIPTION, SubscriptionStepType.Success);
    };
    const { isInvoiceCreating, isWidgetOpened, сreateXyzPlusSubscription } = useCreateXyzPlusSubscription(
        onPaymentSuccess,
    );
    const selectedKind = useRef<XyzPlusSubscriptionTariff>(XyzPlusSubscriptionTariff.Pro);

    if (location.state === SubscriptionStepType.Success) {
        return <PageSubscriptionSuccess />;
    }

    if (location.state === SubscriptionStepType.Contacts) {
        return (
            <PageSubscriptionContacts
                isSubmitDisabled={isInvoiceCreating || isWidgetOpened}
                onFormSuccess={() => {
                    сreateXyzPlusSubscription({ tariff: selectedKind.current });
                }}
                onSkipBtnClick={() => сreateXyzPlusSubscription({ tariff: selectedKind.current })}
                onFormError={() => {
                    сreateXyzPlusSubscription({ tariff: selectedKind.current });
                    pushNotification(
                        {
                            type: 'warning',
                            message: t('NOTIFICATION_SAVE_CONTACTS_WARNING'),
                            name: 'saveContactsError',
                        },
                        5000,
                    );
                }}
            />
        );
    }

    return (
        <PageSubscriptionSettings
            onTariffSelected={(kind) => {
                history.push(routes.SUBSCRIPTION, SubscriptionStepType.Contacts);
                selectedKind.current = kind;
            }}
        />
    );
};

export default PageSubscription;
