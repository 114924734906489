import React, { ReactElement, ReactNode } from 'react';

import TabItem from '@/ui/Tab/TabItem';

import styles from './Tab.scss';

type Props = {
    children: ReactNode;
};

const Tab = ({ children }: Props): ReactElement => {
    return <div className={styles.Tab}>{children}</div>;
};

Tab.Item = TabItem;
export default Tab;
