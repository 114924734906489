import React from 'react';
import classNames from 'classnames';

import ReactCodeInput, { InputModeTypes } from 'react-code-input';

import styles from './MultiInputsField.scss';

type Props = {
    name: string;
    value: string;
    fields?: number;
    type?: 'number' | 'text' | 'password';
    inputMode?: InputModeTypes;
    hasError?: boolean;
    onChange: (value: string) => void;
};

const MultiInputsField = ({
    fields = 4,
    name,
    value,
    type = 'text',
    inputMode = 'tel',
    hasError = false,
    onChange,
}: Props) => {
    return (
        <ReactCodeInput
            type={type}
            fields={fields}
            value={value}
            name={name}
            inputMode={inputMode}
            className={classNames(styles.multiInputsField, { [styles.error]: hasError })}
            onChange={onChange}
        />
    );
};

export default MultiInputsField;
