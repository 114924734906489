import { useTranslation } from 'react-i18next';

import { COLOR } from '@/ui/ProgressBar';

type ProgressStateDTO = {
    progress?: number;
    expectedProgress?: number;
    totalHomeworks?: number;
    completedHomeworks?: number;
    courseSlug: string;
    currentUserId: string;
    canBuyHomework: boolean;
};

type ProgressStateType = {
    label?: string;
    color: keyof typeof COLOR;
    info?: {
        title?: string;
        description: string;
        link?: string;
    };
};

const useEnrollmentProgressState = ({
    expectedProgress,
    progress,
    totalHomeworks = 0,
    completedHomeworks = 0,
    courseSlug,
    currentUserId,
    canBuyHomework,
}: ProgressStateDTO): ProgressStateType => {
    const { t } = useTranslation(['enrollment']);

    if (typeof progress === 'number') {
        if (expectedProgress === 1) {
            if (progress === 1) {
                return {
                    label: t('ENROLLMENT_PROGRESS_COMPLETED'),
                    color: COLOR.accent,
                };
            }

            if (!canBuyHomework && totalHomeworks > 0 && totalHomeworks > completedHomeworks) {
                return {
                    color: COLOR.warning,
                    info: {
                        description: t('ENROLLMENT_IMCOMPLETED_HOMEWORKS_CANT_BUY'),
                    },
                };
            }

            if (canBuyHomework && totalHomeworks > 0 && totalHomeworks > completedHomeworks) {
                return {
                    color: COLOR.warning,
                    info: {
                        description: t('ENROLLMENT_IMCOMPLETED_HOMEWORKS'),
                        link: `https://www.school-xyz.com/homework_payment?user_id=${currentUserId}&course_slug=${courseSlug}`,
                    },
                };
            }

            if (progress < 1) {
                return {
                    color: COLOR.warning,
                    info: {
                        description: t('ENROLLMENT_IMCOMPLETED_LECTURES'),
                    },
                };
            }
        }

        if (expectedProgress) {
            const difference = expectedProgress - progress;
            if (difference >= 0.45) {
                return {
                    color: COLOR.fail,
                    info: {
                        title: t('ENROLLMENT_PROGRESS_FAIL_TITLE'),
                        description: t('ENROLLMENT_PROGRESS_DESCRIPTION'),
                    },
                };
            }
            if (difference >= 0.2 && difference < 0.45) {
                return {
                    color: COLOR.warning,
                    info: {
                        title: t('ENROLLMENT_PROGRESS_WARNING_TITLE'),
                        description: t('ENROLLMENT_PROGRESS_DESCRIPTION'),
                    },
                };
            }
            return {
                color: COLOR.success,
                info: {
                    title: t('ENROLLMENT_PROGRESS_SUCCESS_TITLE'),
                    description: t('ENROLLMENT_PROGRESS_DESCRIPTION'),
                },
            };
        }
    }
    return {
        color: COLOR.accent,
    };
};

export default useEnrollmentProgressState;
