import React from 'react';
import { gql } from '@apollo/client';
import {
    CourseCardPromoFragment,
    CourseCardControlsFragmentDoc,
    CourseCardPriceFragmentDoc,
} from '@/generated/graphql';

import { BlockLabel, Container, Link, Heading } from '@xyz-school/xyz-react-frontend';
import CardLayout from '@/ui/CardLayout';
import Image from '@/ui/Image';

import { courseHelpers } from '@/models/Course';
import CourseCardControls from '@/models/Course/CourseCardControls';
import CourseCardPrice from '@/models/Course/CourseCardPrice';

import styles from './CourseCardPromo.scss';

gql`
    fragment CourseCardPromo on CourseType {
        id
        slug
        isFree
        thumbnail
        title
        courseKind {
            label
        }
        infoUrl
        purchaseUrl
        access {
            view
        }
        ...CourseCardControls
        ...CourseCardPrice
    }
    ${CourseCardControlsFragmentDoc}
    ${CourseCardPriceFragmentDoc}
`;

type Props = {
    isRecomended?: boolean;
    course: CourseCardPromoFragment;
};

const CourseCardPromo = ({ isRecomended, course }: Props) => {
    const isRecomendedTags = `utm_source=platform&utm_medium=course_card&utm_campaign=recommendation&utm_content=dashboard_my&utm_term=${course.slug}`;
    const getInfoUrl = () => {
        if (isRecomended) {
            return `${course.infoUrl}?${isRecomendedTags}`;
        }
        return `${course.infoUrl}`;
    };
    const infoUrl = getInfoUrl();

    const link = course.access.view ? courseHelpers.getUrl(course) : course.purchaseUrl || infoUrl;

    return (
        <Link to={link}>
            <CardLayout
                className={styles.courseCardPromo}
                thumbnail={<Image image={courseHelpers.getCover(course)} coverAlt={course.title} />}
                labels={<BlockLabel>{course.courseKind.label}</BlockLabel>}
            >
                <Heading wrap level={3}>
                    {course.title}
                </Heading>
                <Container marginTop={1} marginBottom={3}>
                    <CourseCardPrice course={course} />
                </Container>
                <CourseCardControls course={course} isRecomended={isRecomended} infoUrl={infoUrl} />
            </CardLayout>
        </Link>
    );
};

export default CourseCardPromo;
