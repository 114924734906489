import React, { ReactNode } from 'react';

import { GridCol, GridRow, Text } from '@xyz-school/xyz-react-frontend';

import CardsLayout from '@/ui/CardsLayout';

type Props = {
    title: string;
    children: ReactNode;
};

const DashboardEmptyTab = ({ title, children }: Props) => {
    return (
        <CardsLayout title={title}>
            <GridRow>
                <GridCol xl={6} lg={8}>
                    <Text color="label">{children}</Text>
                </GridCol>
            </GridRow>
        </CardsLayout>
    );
};
export default DashboardEmptyTab;
