import React from 'react';

import { gql } from '@apollo/client';
import { useCheckerAssignmentsListQuery } from '@/generated/graphql';
import { useTranslation } from 'react-i18next';
import { Text } from '@xyz-school/xyz-react-frontend';
import CheckerAssignmentListItemUi from './ui/CheckerAssignmentListItemUi';

gql`
    query checkerAssignmentsList($after: String) {
        assignments(after: $after) {
            edges {
                node {
                    id
                    awaitingCheck
                    dateSubmitted
                    dateChecked
                    course {
                        title
                    }
                    user {
                        avatar
                        username
                    }
                    lecture {
                        title
                    }
                    content {
                        title
                    }
                }
            }
        }
    }
`;

const CheckerAssignmentsList = () => {
    const { data, loading } = useCheckerAssignmentsListQuery();
    const { t } = useTranslation(['staff']);

    if (!data && !loading) {
        return null;
    }

    if (!loading && !data?.assignments?.edges.length) {
        return (
            <>
                <Text color="label">{t('TITLE_NO_ASSIGNMENTS')}</Text>
                <Text color="label">{t('DESCRIPTION_NO_ASSIGNMENTS')}</Text>
            </>
        );
    }

    return (
        <div>
            {data?.assignments?.edges.map((edge) => {
                if (edge?.node) {
                    return (
                        <CheckerAssignmentListItemUi
                            key={edge.node.id}
                            pk={edge.node.id}
                            courseTitle={edge.node.course?.title}
                            lectureTitle={edge.node.lecture?.title}
                            contentTitle={edge.node.content?.title}
                            avatar={edge.node.user?.avatar}
                            userName={edge.node.user?.username}
                            dateSubmited={edge.node.dateSubmitted}
                            dateChecked={edge.node.dateChecked}
                            awaitingMinutes={edge.node.awaitingCheck}
                        />
                    );
                }

                return null;
            })}
        </div>
    );
};

export default CheckerAssignmentsList;
