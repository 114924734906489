// @flow strict
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { PageError500 } from '@xyz-school/xyz-react-frontend';

import { client } from '@/core/apollo';
import App from '@/core/App';
import ScrollToTop from '@/core/ScrollToTop';

import Routes from './Routes';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
    Sentry.init({
        dsn: 'https://98f3ce851a49430cba20bb22e111b991@o287829.ingest.sentry.io/5458161',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

const container = document.getElementById('root');
if (container) {
    render(
        <Suspense fallback={<div />}>
            <Sentry.ErrorBoundary fallback={<PageError500 />}>
                <ApolloProvider client={client}>
                    <App>
                        <ScrollToTop />
                        <Routes />
                    </App>
                </ApolloProvider>
            </Sentry.ErrorBoundary>
        </Suspense>,
        container,
    );
}
