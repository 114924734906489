import { useCallback, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { usePulling } from '@xyz-school/xyz-react-frontend';

import { notificationApi } from '@/models/Notification';

const INTERVAL = 60 * 1000;

const usePullingNotification = (): [number, Dispatch<SetStateAction<number>>] => {
    const [unreadCount, setUnreadCount] = useState<number>(0);

    const loadUnreadCount = useCallback(
        () =>
            notificationApi
                .getNotificationUnreadCount()
                .then((data) => setUnreadCount(data.unreadCount))
                .catch(() => {
                    // FIXME add error handler
                }),
        [setUnreadCount, notificationApi],
    );

    useEffect(() => {
        loadUnreadCount();
    }, []);

    usePulling(INTERVAL, loadUnreadCount);

    return [unreadCount, setUnreadCount];
};

export default usePullingNotification;
