import React from 'react';

import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { PopoverMenu } from '@xyz-school/xyz-react-frontend';
import { useUpdateLectureMutation } from '@/generated/graphql';

type Props = {
    lectureId: string;
    contentId: string;
    contentIds: string[];
};

gql`
    mutation UpdateLecture($input: CourseLectureDTO!) {
        updateLecture(input: $input) {
            lecture {
                id
                contents {
                    id
                }
            }
        }
    }
`;

const CourseContentFastActions = ({ lectureId, contentId, contentIds }: Props) => {
    const { t } = useTranslation(['lecture']);
    const [updateLecture] = useUpdateLectureMutation();
    const currentContentIndex = contentIds.indexOf(contentId);

    const updatedArray = (array: string[], from: number, to: number): string[] => {
        const tmpArray = array;
        [tmpArray[from], tmpArray[to]] = [tmpArray[to], tmpArray[from]];
        return tmpArray;
    };

    const updateFnc = (contentsArray: string[]): void => {
        updateLecture({
            variables: { input: { id: lectureId, contents: contentsArray } },
        });
    };

    return (
        <PopoverMenu>
            {currentContentIndex !== 0 && (
                <PopoverMenu.Item
                    label={t('FAST_ACTION_RAISE')}
                    onClick={() => {
                        updateFnc(updatedArray(contentIds, currentContentIndex, currentContentIndex - 1));
                    }}
                />
            )}
            {currentContentIndex !== contentIds.length - 1 && (
                <PopoverMenu.Item
                    label={t('FAST_ACTION_LOWER')}
                    onClick={() => {
                        updateFnc(updatedArray(contentIds, currentContentIndex, currentContentIndex + 1));
                    }}
                />
            )}
        </PopoverMenu>
    );
};
export default CourseContentFastActions;
