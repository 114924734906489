import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@xyz-school/xyz-react-frontend';
import useFormatedDate from '@/core/useFormatedDate';

import NotificationItem from '@/ui/NotificationItem';

type Props = {
    startDate: string;
    endDate: string;
};

const EnrollmentBundleVacationNotification = ({ startDate, endDate }: Props) => {
    const { formatDate } = useFormatedDate();
    const { t } = useTranslation(['enrollment']);

    return (
        <NotificationItem>
            <Text size="small">
                {t('BUNDLE_VACATION', {
                    startDate: formatDate(startDate, 'DD MMMM'),
                    endDate: formatDate(endDate, 'DD MMMM'),
                })}
            </Text>
        </NotificationItem>
    );
};

export default EnrollmentBundleVacationNotification;
