import React, { useEffect, useState, useRef } from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { Button, Text, useNotificationActions } from '@xyz-school/xyz-react-frontend';

import PhoneField from '@/ui/PhoneField';

import { UserPhoneFormFragment, useProfilePhoneVerificationMutation } from '@/generated/graphql';

import UserPhoneVerificationModal from '../UserPhoneVerificationModal';

import styles from './UserPhoneForm.scss';

gql`
    fragment UserPhoneForm on UserType {
        phone
        contactPhone
    }

    mutation ProfilePhoneVerification($input: ProfilePhoneVerificationDTO!) {
        profilePhoneVerification(input: $input) {
            verificationId
            expiredAt
        }
    }
`;

type Props = {
    data?: UserPhoneFormFragment;
};

const normalizePhone = (input: string): string => {
    const regex = /[^0-9+]/g;
    return input.replace(regex, '');
};

const UserPhoneForm = ({ data }: Props) => {
    const { t } = useTranslation(['user']);
    const { pushNotification } = useNotificationActions();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [phone, setPhone] = useState('');
    const [currentVerificationId, setCurrentVerificationId] = useState<string>();
    const [currentVerificationEndDate, setCurrentVerificationEndDate] = useState<string>();
    const [isPhoneVerified, setIsPhoneVerified] = useState<boolean>(false);
    const [isVerificationModalOpened, setIsVerificationModalOpened] = useState<boolean>(false);

    const [verifyPhone] = useProfilePhoneVerificationMutation({
        onCompleted: ({ profilePhoneVerification }) => {
            if (profilePhoneVerification?.verificationId) {
                setIsVerificationModalOpened(true);
                setCurrentVerificationId(profilePhoneVerification?.verificationId);
                setCurrentVerificationEndDate(profilePhoneVerification.expiredAt);
            }
        },
        onError: (error) => {
            pushNotification({
                type: 'error',
                message: error.message,
                name: 'CodeVerificationError',
            });
        },
    });

    const handlePhoneSubmit = () => {
        verifyPhone({
            variables: {
                input: {
                    phone,
                },
            },
        });
    };

    useEffect(() => {
        if (data?.phone) {
            setPhone(data.phone);
        }

        if (!data?.phone && data?.contactPhone) {
            setPhone(data.contactPhone);
        }
    }, [data?.phone, data?.contactPhone]);

    useEffect(() => {
        setIsPhoneVerified(!!data?.phone && data?.phone === normalizePhone(phone));
    }, [data?.phone, phone]);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.target.value);
    };

    const handleChangeClick = () => {
        setTimeout(() => {
            inputRef.current?.focus();
        }, 50);
    };

    const handleModalClose = () => {
        setIsVerificationModalOpened(false);
    };

    const handleVerifySuccess = () => {
        setIsPhoneVerified(true);
        setIsVerificationModalOpened(false);

        pushNotification({
            type: 'success',
            message: t('VERIFY_SUCCESS_NOTIFICATION'),
            name: 'verifyOk',
        });
    };

    return (
        <>
            <div className={styles.formWrap}>
                <PhoneField ref={inputRef} value={phone} onChange={handlePhoneChange} />

                {isPhoneVerified ? (
                    <Button
                        styleType="primary"
                        type="submit"
                        size="large"
                        className={styles.formBtn}
                        onClick={handleChangeClick}
                    >
                        {t('PHONE_EDIT_BTN_TEXT')}
                    </Button>
                ) : (
                    <Button
                        styleType="primary"
                        type="submit"
                        size="large"
                        className={styles.formBtn}
                        onClick={handlePhoneSubmit}
                    >
                        {t('PHONE_VERIFY_BTN_TEXT')}
                    </Button>
                )}
            </div>

            {data && isPhoneVerified && (
                <Text size="small" color="success">
                    {t('PHONE_VERIFIED_TEXT')}
                </Text>
            )}

            {data && !isPhoneVerified && (
                <Text size="small" color="fail">
                    {t('PHONE_UNVERIFIED_TEXT')}
                </Text>
            )}

            {isVerificationModalOpened && currentVerificationId && (
                <UserPhoneVerificationModal
                    phone={phone}
                    verificationId={currentVerificationId}
                    verificationEndDate={currentVerificationEndDate}
                    onClose={handleModalClose}
                    onSuccess={handleVerifySuccess}
                />
            )}
        </>
    );
};

export default UserPhoneForm;
