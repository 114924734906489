import React from 'react';

import Icon from '@/ui/Icon';

const TYPE = {
    lesson: 'course-start',
    task: 'course-task',
    questbox: '',
} as const;

const STATUS = {
    locked: 'lock',
    viewed: 'course-complete',
} as const;

type Props = {
    type: keyof typeof TYPE;
    status?: keyof typeof STATUS;
};

const LectureIcon = ({ type, status }: Props) => {
    return <Icon name={status ? STATUS[status] : TYPE[type]} />;
};

export default LectureIcon;
