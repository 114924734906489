import React from 'react';
import { gql } from '@apollo/client';
import { FaqItemFragmentDoc, FaqListFragment } from '@/generated/graphql';
import FaqItem from '@/features/Faq/FaqItem';

gql`
    fragment FaqList on CourseType {
        questions {
            ...FaqItem
        }
    }
    ${FaqItemFragmentDoc}
`;

type Props = {
    course: FaqListFragment;
};

const FaqList = ({ course }: Props) => {
    return (
        <div>
            {course.questions.map((question) => (
                <FaqItem question={question} />
            ))}
        </div>
    );
};

export default FaqList;
