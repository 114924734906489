import React from 'react';

import { gql } from '@apollo/client';

import { PageCourseLectureListFragment, PageCourseLectureItemFragmentDoc } from '@/generated/graphql';
import { Join, Separator } from '@xyz-school/xyz-react-frontend';

import PageCourseLectureItem from './PageCourseLectureItem';
import styles from './PageCourseLectureList.scss';

gql`
    fragment PageCourseLectureList on CourseSectionType {
        lectures {
            id
            ...PageCourseLectureItem
        }
    }
    ${PageCourseLectureItemFragmentDoc}
`;

type Props = {
    courseSlug: string;
    section: PageCourseLectureListFragment;
};

const PageCourseLectureList = ({ courseSlug, section }: Props) => (
    <div className={styles.PageCourseLectureList}>
        <Separator marginTop={0} marginBottom={3} marginBottomXs={1} />
        <Join separator={<Separator marginTop={3} marginTopXs={1} marginBottom={3} marginBottomXs={1} />}>
            {section.lectures.map((lecture) => (
                <PageCourseLectureItem courseSlug={courseSlug} lecture={lecture} key={lecture.id} />
            ))}
        </Join>
    </div>
);

export default PageCourseLectureList;
