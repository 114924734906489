import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { Link, Text } from '@xyz-school/xyz-react-frontend';

import BreadcrumbsWrapper from '@/features/BreadcrumbsWrapper';
import { PageLectureBreadCrumbsCourseFragment, PageLectureBreadCrumbsLectureFragment } from '@/generated/graphql';
import { routes } from '@/Routes';

gql`
    fragment PageLectureBreadCrumbsCourse on CourseType {
        slug
        title
    }
    fragment PageLectureBreadCrumbsLecture on CourseLectureType {
        title
    }
`;

type Props = {
    course: PageLectureBreadCrumbsCourseFragment;
    lecture: PageLectureBreadCrumbsLectureFragment;
};

const PageLectureBreadCrumbs = ({ course, lecture }: Props) => {
    const { t } = useTranslation(['dashboard']);

    return (
        <BreadcrumbsWrapper>
            <Link to="/dashboard/my">{t('MY_COURSES')}</Link>
            <Link to={routes.COURSE(course.slug)}>{course.title}</Link>
            <Text>{lecture.title}</Text>
        </BreadcrumbsWrapper>
    );
};

export default PageLectureBreadCrumbs;
