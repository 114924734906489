import dayjs from 'dayjs';
import 'dayjs/locale/ru';

const useFormatedDate = () => {
    const formatDate = (date: string, format: string): string => {
        return dayjs(date).locale('ru').format(format);
    };

    return { formatDate };
};

export default useFormatedDate;
