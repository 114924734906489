import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { FaqItemFragment } from '@/generated/graphql';
import CollapseItemLayout from '@/ui/CollapseItemLayout';
import EditorJsDataView from '@/models/EditorJsData/ui/EditorJsDataView';

gql`
    fragment FaqItem on QuestionType {
        text
        answer
    }
`;

type Props = {
    question: FaqItemFragment;
};

const FaqItem = ({ question }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <CollapseItemLayout title={question.text} isOpen={isOpen} onToogle={handleToggle}>
            {!!question.answer && <EditorJsDataView content={question.answer} />}
        </CollapseItemLayout>
    );
};

export default FaqItem;
