import { FaqListFragment } from '@/generated/graphql';

export const XYZ_PLUS_FAQ_DATA: FaqListFragment = {
    questions: [
        {
            text: 'Как будет происходить оплата?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Если ты, к примеру, выберешь тариф “AA”, то сперва спишется 9 120 ₽ сразу за 6 месяцев вперед. Когда 6 месяцев кончатся, оплата будет списываться каждый месяц по 1 520 ₽. Скидка -15% останется с тобой навсегда. Таким образом, чем более “продвинутый” тариф ты выбираешь, тем меньше у тебя будет ежемесячная оплата по истечении основного периода.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как отменить подписку?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Нажми на кнопку “Отменить подписку” в разделе управления подпиской. Тебя перекинет на страницу в Cloud Payments, где нужно будет ввести данные своей карты и сумму ежемесячного списания (1 900, 1 520 или 1 140 ₽), чтобый найти текущую подписку и отменить её. После отмены у тебя все еще будет доступ к контенту до тех пор, пока не закончится текущий период подписки. За новый период списаний не будет. Учти, что цены на тарифы могут меняться, и когда ты покупал подписку, ты зафиксировал для себя конкретную цену. Если решишь подписаться заново, цена уже может быть другой.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как возобновить подписку?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Если у тебя когда-то была подписка, и она была отменена, то в любой момент можно оформить ее еще раз. Для этого нажми “Возобновить подписку” и выбери любой тариф.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как работает акция “Первые две недели за рубль”?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Если ты ещё никогда не оформлял подписку, то можешь воспользоваться пробным двухнедельным периодом стоимостью всего 1₽. Для этого оформи подписку на тариф Indie. По истечении двух недель будет списываться по 1 900 ₽ в месяц согласно тарифу.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как перейти на другой тариф?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Сперва нужно отменить текущую подписку, как это сделать — читай чуть выше. Далее нужно оформить новую подписку с тем тарифом, на который хочешь перейти. Списание по новому тарифу произойдёт сразу же при его оформлении, сам тариф обновится как только твой предыдущий уровень доступа подойдёт к концу.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Что делать если платеж за новый месяц подписки не прошел?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'О том, что платеж не прошёл, можно узнать зайдя на платформу, также на почту должно прийти оповещение. Возможных причин может быть множество, например — на карте недостаточно средств. Мы попробуем списать средства три раза: первые два не отменяют подписку и есть возможность исправить ситуацию, а последняя, к сожалению, отменит подписку. Попытки списания происходят 1 раз в сутки. Если есть необходимость изменить данные карты, сделать это можно через письмо с предупреждением о просроченном платеже. Там нужно нажать “Оплатить сейчас”, после чего на странице Cloud Payments нажать на вторую кнопку снизу, где отображаются последние 4 цифры текущей карты.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Что будет, если у меня уже есть доступ к XYZ Плюс, но нет подписки?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Иногда мы выдаем доступ бонусом, например, вместе с покупкой какого-либо курса. Если хочешь продолжать смотреть контент в XYZ Плюс после того, как доступ закончится, оформи подписку. После окончания доступа она вступит в силу и контент будет по-прежнему в твоем распоряжении.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как сменить карту?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text:
                                'Если нужно указать новую карту, потому что на текущей кончились деньги или она заблокирована, то сделать это можно через письмо с предупреждением о просроченном платеже. Там нужно нажать “Оплатить сейчас”, после чего на странице Cloud Payments нажать на вторую кнопку снизу, где отображаются последние 4 цифры текущей карты. Если же с картой все в порядке, но сменить ее все равно нужно, то необходимо отменить текущую подписку и оформить новую, указав при оформлении другую карту.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
        {
            text: 'Как я узнаю о выходе новых мастер-классов?',
            answer: {
                time: 0,
                blocks: [
                    {
                        data: {
                            text: 'Мастер-классы, которые появились недавно, будут в самом верху с пометкой “Новое”.',
                        },
                        type: 'paragraph',
                    },
                ],
                version: '1',
            },
        },
    ],
};
