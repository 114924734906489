import React from 'react';
import { gql } from '@apollo/client';
import { XyzPlusContentCardFragment, XyzPlusContentStatus } from '@/generated/graphql';

import { routes } from '@/Routes';
import { Link } from '@xyz-school/xyz-react-frontend';
import XyzPlusContentCardUi from '../ui/XyzPlusContentCardUi';

gql`
    fragment XyzPlusContentCard on XyzPlusContentType {
        id
        title
        thumbnail
        relatedCourse {
            title
        }
        category {
            title
        }
        isNew
        status
    }
`;

type Props = {
    content?: XyzPlusContentCardFragment;
};

const XyzPlusContentCard = ({ content }: Props) => {
    if (!content) {
        return null;
    }

    return (
        <Link history to={routes.XYZ_PLUS_CONTENT(content.id)}>
            <XyzPlusContentCardUi
                title={content.title}
                thumbnail={content.thumbnail}
                courseTitle={content.relatedCourse?.title}
                categoryTitle={content.category?.title}
                isNew={content.isNew}
                isDraft={content.status === XyzPlusContentStatus.Draft}
            />
        </Link>
    );
};

export default XyzPlusContentCard;
